import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #e6f4ef;
  border-radius: 88px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2) inset;
  padding: 8px 9px;

  .ant-btn-default {
    background: transparent;
    border-color: transparent;
    color: #02494e;

    .ant-btn-icon {
      color: #03494e;
    }

    &.active,
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: #215054;
      border-color: #215054;
      color: #fff;

      .ant-btn-icon {
        color: #d7e05f;
      }
    }
  }
`;

export default StylesContainer;
