import { Form, InputNumber, Select, Space } from "antd";
import InputContainer from "../../../../../../atom/InputContainer";
import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helper/general";

const BitAboutYou = ({ proposalData, updateProposalData }) => (
  <div style={{ textAlign: "center", maxWidth: 720, margin: "0 auto" }}>
    <h2 className="title">A bit about you...</h2>
    <p className="description">
      Let’s set some basic parameters for this journey.
      <br />
      Please fill out the questions below.
    </p>
    <Space size={[38, 32]} style={{ justifyContent: "center" }} wrap>
      <InputContainer labelText="Initial Investment Amount">
        <InputNumber
          size="large"
          min={0}
          step={1000}
          value={proposalData.investmentAmount}
          formatter={getCurrencyValue}
          parser={cleanCurrencyValue}
          style={{ width: 231 }}
          onChange={value => updateProposalData("investmentAmount", value)}
          onClick={selectValue}
        />
      </InputContainer>
      <InputContainer labelText="Length Of Investment">
        <InputNumber
          addonAfter={
            <Form.Item style={{ marginBottom: 0 }}>
              <Select
                options={[
                  {
                    label: "Years",
                    value: "years",
                  },
                  {
                    disabled: true,
                    label: "Quarters",
                    value: "quarters",
                  },
                  {
                    disabled: true,
                    label: "Months",
                    value: "months",
                  },
                ]}
                style={{ width: 120 }}
                value="years"
              />
            </Form.Item>
          }
          size="large"
          min={1}
          max={80}
          step={1}
          value={proposalData.investmentDuration}
          style={{ width: 166 }}
          onChange={value => updateProposalData("investmentDuration", value)}
          onClick={selectValue}
        />
      </InputContainer>
      <InputContainer labelText="Monthly Investment Contributions">
        <InputNumber
          size="large"
          min={0}
          step={100}
          value={proposalData.contributions}
          formatter={getCurrencyValue}
          parser={cleanCurrencyValue}
          style={{ width: 240 }}
          onChange={value => updateProposalData("contributions", value)}
          onClick={selectValue}
        />
      </InputContainer>
      <InputContainer labelText="Current Age">
        <InputNumber
          size="large"
          min={10}
          max={100}
          step={1}
          value={proposalData.currentAge}
          style={{ width: 173 }}
          onChange={value => updateProposalData("currentAge", value)}
          onClick={selectValue}
        />
      </InputContainer>
      <InputContainer labelText="Spouse’s Current Age">
        <InputNumber
          size="large"
          min={0}
          max={100}
          step={1}
          value={proposalData.currentSpouseAge}
          style={{ width: 169 }}
          onChange={value => updateProposalData("currentSpouseAge", value)}
          onClick={selectValue}
        />
      </InputContainer>
      <InputContainer labelText="Combined Household Income">
        <InputNumber
          size="large"
          min={0}
          step={100}
          value={proposalData.householdIncome}
          formatter={getCurrencyValue}
          parser={cleanCurrencyValue}
          style={{ width: 240 }}
          onChange={value => updateProposalData("householdIncome", value)}
          onClick={selectValue}
        />
      </InputContainer>
    </Space>
  </div>
);

export default BitAboutYou;
