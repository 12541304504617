import styled from "styled-components";
import { Menu } from "antd";

export const ChildIcon = styled.div`
  height: 100%;
  border-left: 3px solid #f2f2f2;
`;

export const StyledMenu = styled(Menu)`
  background: transparent;
  color: #ffffff60;

  &.ant-menu-light .ant-menu-submenu-title,
  &.ant-menu-inline .ant-menu-item {
    gap: 8px;
    color: #ffffff60;
  }

  &.ant-menu-light .ant-menu-item-selected {
    font-weight: bold;
    background: transparent;
    color: #ffffff;

    .ant-menu-item-icon {
      color: #ffffff;
    }
  }

  & .ant-menu-submenu > .ant-menu .ant-menu-item .ant-menu-item-icon + span {
    margin-left: 0;
  }
`;
