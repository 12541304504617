import styled from "styled-components";

const StylesContainer = styled.header`
  align-items: end;
  border-bottom: 1px solid #bec8ce;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;

  .description {
    color: #234744;
    text-align: right;
  }
`;

export default StylesContainer;
