import AWS from "aws-sdk";
import Cookies from "js-cookie";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "./userPool";

export const MFA_REQUIRED = "MFA Required";
export const NEW_PASSWORD_REQUIRED = "New Password Required";

export default function logIn(
  username,
  password,
  { mfaRequired, newPasswordRequired } = {}
) {
  return new Promise((resolve, reject) => {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        const token = result.getIdToken().getJwtToken();
        Cookies.set("mmi-token", token, { expires: 7, secure: true });

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_COGNITO_CLIENT_ID,
          Logins: {
            [`cognito-idp.${process.env.AWS_REGION}.amazonaws.com/${process.env.REACT_APP_COGNITO_USER_POOL_ID}`]:
              token,
          },
        });

        resolve(result);
      },
      onFailure: err => {
        console.log(err);
        return reject(err);
      },
      mfaRequired: codeDeliveryDetails =>
        mfaRequired
          ? mfaRequired(codeDeliveryDetails, cognitoUser)
          : reject(MFA_REQUIRED),
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log("newPasswordRequired:", userAttributes);

        // the api doesn't accept this field back, thats why we delete it
        delete userAttributes.email_verified;
        delete userAttributes.email;

        return newPasswordRequired
          ? newPasswordRequired(userAttributes, requiredAttributes, cognitoUser)
          : reject(NEW_PASSWORD_REQUIRED);
      },
    });
  });
}

// export default logIn;
