import logo from "../images/logo_main.png";

export default {
  adminHeaderBorderBottomColor: "#C6C6C6",
  adminPieChartColorPrimary: "#F36835",
  adminPieChartColorSecondary: "#BEC8CE",
  adminPieChartColorTertiary: "#354A5F",
  adminPieChartColorRest: "#72C0C7",
  chartColors: ["#4190D0", "#18355E", "#64C981", "#90D04D", "#00BDC4"],
  colorAdminAnalyticValue: "#131523",
  colorPrimaryButton: "#04223A",
  colorPrimaryButtonText: "#FFFFFF",
  colorRadioButton: "#5DCA83",
  colorSecondaryButton: "#04223A",
  colorSecondaryButtonText: "#FFFFFF",
  colorTertiaryButton: "#E7EBEE",
  colorTertiaryButtonText: "#516474",
  logoSrc: logo,
  modalFinancialProductDetailsHeaderBackground: "#E7EBEE",
  productCardBackground: "linear-gradient(173deg, #FFFFFF 0%, #DEE8EF 100%)",
  productCardBorder: "1px solid #BFC8CE",
  proposalGuideFontPrimary: "Museo Sans, sans-serif",
  proposalGuideCheckbox: {
    colorBorder: "#5F6060",
    colorText: "#234744",
  },
  proposalGuideRadio: {
    colorBorder: "#8396A6",
    colorPrimary: "#5DCA83",
    colorText: "#15284B",
    fontSize: 16,
    lineWidth: 2,
  },
  quarterlyGoalAumColor: "#4190D0",
  quarterlyGoalClientColor: "#90D04D",
  quarterlyGoalProposalColor: "#18355E",
  sidebarMenuItemHoverBackground: "#152A4E",
  sidebarBackground: "#215783",
  sidebarIconColor: "#BEC8CE",
  sidebarIconColorActive: "#FFFFFF",
  tenantColorPrimary: "#4897D1",
};
