import styled from "styled-components";

const StylesContainer = styled.div`
  .header {
    border-bottom: 1px solid #c9c8c8;
    padding: 16px 32px;

    .title {
      color: #1e1919;
      font-size: 20px;
      font-weight: 700;
      margin: 0;
    }
  }

  .body {
    box-sizing: border-box;
    padding: 32px 48px;
    overflow: auto;
  }

  .ant-table-wrapper {
    max-width: 1010px;

    table {
      border-spacing: 0 4px;
    }

    .ant-table-thead > tr > th {
      background: #fff;
      border: unset;
      color: #000;
      padding: 0;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        background: #ecf4fe;
        color: #1e1919;

        &:first-of-type {
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }

        &:last-of-type {
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      .ant-table-expanded-row .ant-table-cell div {
        background: #fff;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 24px;
        white-space: break-spaces;

        img {
          border: 1px solid #c9c8c8;
          border-radius: 12px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
`;

export default StylesContainer;
