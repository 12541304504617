import { Flex } from "antd";

import StyledCard from "./styles";

import { PRIORITIES_DEFAULT_STATE } from "../ModalPriorities/constant";
import { getListUseOfMoney } from "../../../../constant";

import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";

export const priorityValuesList = [
  ...PRIORITIES_DEFAULT_STATE.step1.values,
  ...PRIORITIES_DEFAULT_STATE.step2.values,
  ...PRIORITIES_DEFAULT_STATE.step3.values,
  ...PRIORITIES_DEFAULT_STATE.step4.values,
];

export const getCleanSortableLabel = label =>
  label
    ?.replaceAll("[my]", "")
    ?.replaceAll("[spouse]", "")
    ?.replaceAll("[disabled]", "");

const SortableCard = ({
  label,
  index,
  handleArrowUp,
  handleArrowDown,
  handleArrowRight,
  showIndex,
}) => {
  const isDisabled = label?.includes("[disabled]");

  const cleanLabel = getCleanSortableLabel(label);

  const useOfMoneyData = getListUseOfMoney().find(
    tendencyData =>
      tendencyData.value ===
      priorityValuesList.find(it => it.label === cleanLabel)?.type
  );

  return (
    <StyledCard
      className={isDisabled ? "disabled" : ""}
      color={useOfMoneyData?.accentColor}
    >
      <Flex align={"center"} gap={10}>
        {showIndex && <span className={"index-value"}>{index}</span>}
        {cleanLabel}
      </Flex>

      <Flex align={"center"} gap={10}>
        {handleArrowRight && (
          <ArrowRightOutlined onClick={() => handleArrowRight(label)} />
        )}

        {handleArrowUp && index !== 1 && (
          <ArrowUpOutlined onClick={() => handleArrowUp(label)} />
        )}

        {handleArrowDown && index !== 5 && (
          <ArrowDownOutlined onClick={() => handleArrowDown(label)} />
        )}
      </Flex>
    </StyledCard>
  );
};

export default SortableCard;
