import {
  convertDecimalIntoDate,
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../helper/general";
import { DEFAULT_BIRTH_YEAR } from "../constant";

export const getGaugeConfig = percent => ({
  percent,
  width: 140,
  height: 140,
  range: {
    color: "l(0) 0:#4F8083 1:#4F8083",
  },
  startAngle: 2.45,
  endAngle: 7,
  indicator: null,
  statistic: {
    title: {
      offsetY: -45,
      style: {
        fontSize: 24,
        fontWeight: 700,
        color: "#516474",
      },
      formatter: ({ percent }) => `${Math.floor(percent * 100)}%`,
    },
    content: {
      offsetY: -20,
      style: {
        fontSize: 21,
        fontWeight: 300,
        color: "#516474",
      },
      formatter: () => "chance",
    },
  },
});

export const getGrowthChartConfig = (
  { birthYear = DEFAULT_BIRTH_YEAR, height = 332 } = {
    birthYear: DEFAULT_BIRTH_YEAR,
    height: 332,
  }
) => {
  return {
    chart: {
      type: "spline",
      height,
      borderWidth: 0,
      spacingTop: 25,
      spacingRight: 25,
      spacingBottom: 24,
      backgroundColor: "transparent",
    },
    legend: false,
    title: {
      text: "",
    },
    credits: false,
    xAxis: {
      lineColor: "transparent",
      tickWidth: 0,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: function () {
          return `'${(this.value + birthYear).toString().slice(2)}`;
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `
        <p><b>Date:</b> ${convertDecimalIntoDate(birthYear + this.x)}</p>
        <p><b>${this.series.name}:</b> ${getCurrencyValue(this.y)}</p>
      `;
      },
    },
    yAxis: {
      min: 0,
      opposite: true,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#354A5F",
        },
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };
};

export const getIncomeChartConfig = ({
  birthYear = DEFAULT_BIRTH_YEAR,
  height = 200,
  spacingTop = 20,
  background,
  borderColor,
}) => {
  return {
    chart: {
      type: "column",
      height,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: borderColor ?? "#E8E8E8",
      spacingTop,
      spacingRight: 25,
      spacingLeft: 20,
      styledMode: false,
      backgroundColor: background ?? {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 0,
        },
        stops: [
          [0, "#CCFFE8"],
          [1, "#F8FDFF"],
        ],
      },
    },
    title: {
      text: "",
    },
    plotOptions: {
      column: {
        borderRadiusTopLeft: 16,
        borderRadiusTopRight: 16,
        stacking: "normal",
      },
    },
    legend: false,
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return `${this.series.name}: ${getCurrencyValue(this.y)}`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    xAxis: {
      reserveSpace: true,
      showFirstLabel: true,
      allowDecimals: false,
      lineColor: "transparent",
      tickWidth: 0,
      tickPixelInterval: 30,
      minRange: 1,
      labels: {
        step: 1,
        showFirstLabel: true,
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: data => `'${(data.value + birthYear).toString().slice(2)}`,
      },
    },
    yAxis: {
      opposite: true,
      lineWidth: 0,
      labels: {
        step: 1,
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
      title: {
        text: null,
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };
};

export const getMcChartConfig = (birthYear = DEFAULT_BIRTH_YEAR) => {
  return {
    chart: {
      height: 250,
    },
    xAxis: {
      lineColor: "transparent",
      tickWidth: 0,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: function () {
          return `'${(this.value + birthYear).toString().slice(2)}`;
        },
      },
    },
    yAxis: {
      opposite: true,
      gridLineColor: "transparent",
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#354A5F",
        },
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      useHTML: true,
      formatter: function () {
        let tooltipHtml = `<span style="line-height: 21px"><b>Date:</b> ${convertDecimalIntoDate(
          birthYear + this.x
        )}</span>`;

        this.points.map(
          point =>
            (tooltipHtml += `
              <br>
              <span style="line-height: 21px"><b>${point.series.name}</b></span>
              <span style="line-height: 21px">${getCurrencyValue(
                point.point.z ?? point.point.high ?? point.y
              )}</span>
            `)
        );

        return tooltipHtml;
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    legend: false,
    title: false,
    credits: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };
};

export const getIncomeChartSeries = ({
  annuities_isOn,
  hideDetailedNames,
  incomeBarchart,
  name,
  otherIncome_isOn,
  socialSecurity_isOn,
  totalWithdrawalsColor,
}) => {
  const series = [];

  incomeBarchart?.totalWithdrawals &&
    series.push({
      name:
        name && !hideDetailedNames ? `Withdrawals (${name})` : "Withdrawals",
      color: totalWithdrawalsColor || "#1B5568",
      data: incomeBarchart.totalWithdrawals,
      stack: name,
      visible: true,
      borderRadius: "6px",
      groupPadding: 0.05,
      pointPadding: 0.05,
    });

  incomeBarchart?.annuities &&
    annuities_isOn &&
    series.push({
      name: name && !hideDetailedNames ? `Annuities (${name})` : "Annuities",
      color: "#5F82F8",
      data: incomeBarchart.annuities,
      stack: name,
      visible: true,
      borderRadius: "6px",
      groupPadding: 0.05,
      pointPadding: 0.05,
    });

  incomeBarchart?.otherIncome &&
    otherIncome_isOn &&
    series.push({
      name:
        name && !hideDetailedNames ? `Other Income (${name})` : "Other Income",
      color: "#9FBEFF",
      data: incomeBarchart.otherIncome,
      stack: name,
      visible: true,
      borderRadius: "6px",
      groupPadding: 0.05,
      pointPadding: 0.05,
    });

  incomeBarchart?.socialSecurity &&
    socialSecurity_isOn &&
    series.push({
      name:
        name && !hideDetailedNames
          ? `Social Security (${name})`
          : "Social Security",
      color: "#3C4C99",
      data: incomeBarchart.socialSecurity,
      stack: name,
      visible: true,
      borderRadius: "6px",
      groupPadding: 0.05,
      pointPadding: 0.05,
    });

  return series;
};

export const getActualAccountBalanceSeries = (calculationsData, series) => {
  if (calculationsData?.gOT?.progressDataForPlotting?.length) {
    if (
      calculationsData.gOT.progressDataForPlotting.length === 1 &&
      calculationsData.gOT.progressDataForPlotting[0][1] === 0
    ) {
      //do not render only zero progress value
    } else {
      series.push({
        name: "Actual account balance",
        lineWidth: 2,
        color: "#1B5568",
        zoneAxis: "x",
        dashStyle: "Line",
        zones: [
          {
            value: 10,
            color: "#1B5568",
          },
        ],
        marker: {
          enabled: calculationsData.gOT.progressDataForPlotting.length === 1,
          symbol: "circle",
          fillColor: "#000",
        },
        dataLabels: {
          enabled: false,
        },
        data: calculationsData.gOT.progressDataForPlotting.sort(
          (a, b) => a[0] - b[0]
        ),
      });
    }
  }
};
