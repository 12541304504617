import styled from "styled-components";

const StylesContainer = styled.div`
  height: 100%;
  padding: 80px 40px;
  border-bottom: unset;
  background: linear-gradient(360deg, #4897d100 0%, #4897d120 100%) 0 0;
  background-size: contain;
  box-sizing: border-box;
  overflow: auto;

  .content-body {
    max-width: 1100px;
    margin: 0 auto;
  }

  .title {
    line-height: 40px;
    font-size: 32px;
    font-weight: bold;
    color: #15284b;
  }

  .description {
    line-height: 28px;
    font-size: 22px;
    font-weight: 300;
    color: #15284b;
  }

  & * {
    font-family: ${props => props.fontFamily};
  }

  .ant-radio-wrapper .ant-radio-inner::after {
    width: 22px;
    height: 22px;
    background: #5dca83;
    border: 3px solid #fff;
    margin-top: -11px;
    margin-left: -11px;
  }

  & .ant-radio-wrapper .ant-radio-input:focus-visible + .ant-radio-inner {
    outline: none;
  }

  & .ant-radio-wrapper .ant-radio-inner {
    width: 22px;
    height: 22px;
  }

  & .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.775);
  }

  @media screen and (max-width: 720px) {
    padding: 40px;
  }
`;

export default StylesContainer;
