import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, ConfigProvider, Flex, Form, Tabs, theme } from "antd";
import TabGoalInfo from "./components/TabGoalInfo";
import TabUser from "./components/TabUser";

import StyledModal from "./styles";

import {
  DEFAULT_RETIREMENT_AGE,
  NEW_CLIENT_DEFAULT_DATA,
} from "../../../../utils/constant";
import { tableItemTypes } from "./constants";

import {
  isAdminView,
  isManagerAccessAffiliateOnly,
  isManagerAccessLevelOnly,
} from "../../../../utils/helper/specialized";
import {
  getCurrencyValue,
  stringToCamelCase,
} from "../../../../utils/helper/general";
import {
  openGoalsManagedUser,
  openManagedUser,
  openProposal,
  setLastAdvisorLogin,
} from "../../../../utils/request/manager";

import Close from "../../../../icon/Close";
import { ReactComponent as IconOpenClientView } from "../../images/icon_open_client_view.svg";

const ModalClientDetails = ({
  activeUserData,
  leadView,
  loading,
  managerAccess,
  onCancel,
  onFinish,
  open,
}) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const [showBasicSection, setShowBasicSection] = useState();

  const isGoalsPlanningType = Form.useWatch("planningType", form) === "Goals";
  const isMarriedFormValue = Form.useWatch("maritalStatus", form) === "married";
  const userLabel = leadView ? "Lead" : "Client";

  useEffect(() => {
    if (open) {
      if (activeUserData) {
        const guideInitialData = activeUserData.valueMap?.guideInitialData;
        const leadInitial = activeUserData?.valueMap?.leadInitial;

        form.setFieldsValue({
          assets: !isNaN(+(leadInitial?.assets ?? guideInitialData?.assets))
            ? (leadInitial?.assets ?? guideInitialData?.assets)
            : 0,
          email: activeUserData.email,
          enableOnboardingQuestions:
            activeUserData.enableOnboardingQuestions ??
            guideInitialData?.enableOnboardingQuestions ??
            true,
          firstName: activeUserData.firstName,
          lastName: activeUserData.lastName,
          maritalStatus:
            (leadInitial?.isMarried ?? guideInitialData?.isMarried)
              ? "married"
              : "single",
          oldEmail: activeUserData.email,
          phoneAlt: leadInitial?.phoneAlt ?? guideInitialData?.phoneAlt,
          phoneNumber:
            activeUserData.phoneNumber ??
            leadInitial?.phoneNumber ??
            guideInitialData?.phoneNumber,
          planningType: isManagerAccessAffiliateOnly(managerAccess)
            ? "Goals"
            : stringToCamelCase(
                activeUserData.valueMap?.leadInitial?.planningType ??
                  activeUserData.valueMap?.prospectObjective?.planningType ??
                  activeUserData.planningType
              ),
          retirementAge:
            activeUserData.retirementAge ??
            guideInitialData?.retirementAge ??
            DEFAULT_RETIREMENT_AGE,
          spouseEmail:
            leadInitial?.spouseEmail ?? guideInitialData?.spouseEmail,
          spouseFirstName:
            leadInitial?.spouseFirstName ?? guideInitialData?.spouseFirstName,
          spouseLastName:
            leadInitial?.spouseLastName ?? guideInitialData?.spouseLastName,
          yearBorn:
            activeUserData.birthYear ??
            activeUserData.yearBorn ??
            guideInitialData?.yearBorn,
        });
      } else {
        form.resetFields();
        form.setFieldsValue(NEW_CLIENT_DEFAULT_DATA);

        // set planningType for specific tenants
        if (managerAccess?.affiliate) {
          form.setFieldValue("planningType", "Goals");
        } else if (managerAccess?.proportal) {
          form.setFieldValue("planningType", "Cashflow");
        }
      }

      // required to rerender Advanced content with new props
      setFormValues(form.getFieldsValue());

      setShowBasicSection(
        formValues.planType === "basic" || !formValues.planType
      );
    }
  }, [open]);

  const handleFormSubmit = values => {
    if (values.planningType?.toLowerCase() === "cashflow") {
      if (
        values.yearBorn &&
        values.startDate &&
        values.yearBorn > dayjs(values.startDate?.$d).format("YYYY")
      ) {
        state.showWarning("Birth Year could not be bigger, than Start Date.");
        return;
      } else if (
        values.planType === "basic" &&
        values.savingsPerMonth > values.annualIncome / 12
      ) {
        state.showWarning(
          `You need to increase Annual Income value to save ${getCurrencyValue(values.savingsPerMonth)} monthly`
        );
        return;
      }

      if (values.planType && values.planType !== "basic") {
        values.accounts = JSON.stringify(
          values.accounts.map(accountData => ({
            account_name: accountData.accountType,
            account_type: tableItemTypes.find(
              it => it.title === accountData.accountType
            ).type,
            starting_balance: accountData.startingBalance,
            growth: accountData.growth,
            start_age: accountData.startAge,
            end_age: accountData.endAge,
          }))
        );
        values.incomes = JSON.stringify(
          values.incomes.map(incomeData => ({
            income_name: incomeData.incomeType,
            income_type: tableItemTypes.find(
              it => it.title === incomeData.incomeType
            ).type,
            annual_amount: incomeData.annualAmount,
            start_age: incomeData.startAge,
            end_age: incomeData.endAge,
          }))
        );
        values.expenses = JSON.stringify(
          values.expenses.map(expenseData => ({
            expense_name: expenseData.expenseType,
            expense_type: tableItemTypes.find(
              it => it.title === expenseData.expenseType
            ).type,
            amount: expenseData.amount,
            frequency: expenseData.frequency,
            start_age: expenseData.startAge,
            end_age: expenseData.endAge,
          }))
        );
      }
    }

    onFinish(values);
  };

  const handleOpenClientView = () => {
    const userEmail = activeUserData.email;
    let planningType = "";

    if (isManagerAccessAffiliateOnly(managerAccess)) {
      planningType = "Goals";
    } else if (isManagerAccessLevelOnly(managerAccess)) {
      planningType = "Proposal";
    } else {
      planningType =
        activeUserData.valueMap?.leadInitial?.planningType ??
        activeUserData.valueMap?.prospectObjective?.planningType ??
        activeUserData.planningType;
    }

    if (planningType?.toLowerCase() === "cashflow") {
      openManagedUser(userEmail);
    } else if (planningType?.toLowerCase() === "goals") {
      openGoalsManagedUser(userEmail);
    } else {
      openProposal({
        organization: state.orgName ?? state.organization?.name,
        userEmail,
      });
    }

    setLastAdvisorLogin(userEmail).then(() => state.setAdminData());
  };

  const handleViewTypeChange = e =>
    setShowBasicSection(e.target.value === "basic");

  const setFormValue = (name, value) => {
    form.setFieldValue(name, value);
    setFormValues(form.getFieldsValue());
  };

  const shouldShowCashflowTab = () => {
    if (activeUserData) {
      return false;
    } else if (isGoalsPlanningType) {
      return false;
    } else if (
      managerAccess &&
      !managerAccess?.affiliate &&
      !managerAccess?.proportal
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            colorPrimary: token.colorRadioButton,
          },
        },
      }}
    >
      <StyledModal
        className="add-client-modal"
        closeIcon={<Close />}
        footer={
          <Flex gap={20}>
            <Button
              disabled={loading || isAdminView()}
              form="clientInfoForm"
              htmlType="submit"
              shape="round"
              type="primary"
            >
              {activeUserData ? "Update User" : "Create User"}
            </Button>
            <Button
              htmlType="button"
              onClick={onCancel}
              shape="round"
              style={{ background: "#E6F4EF", color: token.colorPrimary }}
              type="text"
            >
              Close
            </Button>
          </Flex>
        }
        onCancel={onCancel}
        open={open}
        title={
          <Flex
            align="center"
            justify="space-between"
            style={{ paddingRight: 40 }}
          >
            <b style={{ fontSize: 18, color: "#1B5568" }}>
              {userLabel} Details
            </b>
            {activeUserData && (
              <Button
                onClick={handleOpenClientView}
                shape="round"
                size="small"
                style={{ width: 170, background: "#E6F4EF", color: "#1B5568" }}
                type="primary"
              >
                <Flex align="center" gap={4}>
                  <span>Open Advisor View</span>
                  <IconOpenClientView />
                </Flex>
              </Button>
            )}
          </Flex>
        }
        width={568}
      >
        <Form
          form={form}
          id="clientInfoForm"
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          {shouldShowCashflowTab() ? (
            <Tabs
              items={[
                {
                  key: "clientInfo",
                  label: "Client Info",
                  children: (
                    <TabUser
                      editView={activeUserData}
                      isGoalsPlanningType={isGoalsPlanningType}
                      isMarriedFormValue={isMarriedFormValue}
                      managerAccess={managerAccess}
                    />
                  ),
                },
                {
                  key: "goalInfo",
                  label: "Base Information",
                  children: (
                    <TabGoalInfo
                      handleViewTypeChange={handleViewTypeChange}
                      handleUserDataChange={setFormValue}
                      newUserData={formValues}
                      showBasicSection={showBasicSection}
                    />
                  ),
                },
              ]}
            />
          ) : (
            <TabUser
              editView={activeUserData}
              isGoalsPlanningType={isGoalsPlanningType}
              isMarriedFormValue={isMarriedFormValue}
              managerAccess={managerAccess}
            />
          )}
        </Form>
      </StyledModal>
    </ConfigProvider>
  );
};

export default ModalClientDetails;
