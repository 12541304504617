import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Col, Row, Tabs, theme } from "antd";
import SectionCapitalMarketAssumptions from "../SectionCapitalMarketAssumptions";
import SectionMc from "../SectionMc";
import SectionStressTest from "../SectionStressTest";

import { isOrganizationRivershares } from "../../../../utils/helper/specialized";

const StyledTabs = styled(Tabs)`
  &.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    width: 150px;
    white-space: break-spaces;
    text-align: left;
  }

  &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2a2828;
  }

  &.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
    width: 4px;
  }
`;

const SectionSimulations = ({
  hideMcSettingsToggler,
  investmentAssumptions,
  liveAssessment,
  organization,
  product,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  return (
    product && (
      <Row>
        <Col span={24}>
          <StyledTabs
            defaultActiveKey="1"
            tabPosition={isSmallScreen ? "top" : "left"}
            items={[
              {
                label: "Run Monte Carlo",
                key: "1",
                children: (
                  <SectionMc
                    hideMcSettingsToggler={hideMcSettingsToggler}
                    investmentAssumptions={investmentAssumptions}
                    liveAssessment={liveAssessment}
                    organization={organization}
                    product={product}
                  />
                ),
              },
              {
                label: "Stress Test",
                key: "2",
                children: (
                  <SectionStressTest
                    investmentAssumptions={investmentAssumptions}
                    liveAssessment={liveAssessment}
                    organization={organization}
                    product={product}
                  />
                ),
              },
              {
                label: "RiverShares Capital Market Assumptions",
                key: "3",
                children: (
                  <SectionCapitalMarketAssumptions
                    investmentAssumptions={investmentAssumptions}
                    liveAssessment={liveAssessment}
                    organization={organization}
                    product={product}
                  />
                ),
                hidden: !isOrganizationRivershares(organization),
              },
            ].filter(it => !it.hidden)}
          />
        </Col>
      </Row>
    )
  );
};

export default SectionSimulations;
