import styled from "styled-components";
import { Flex } from "antd";

import sliderImage from "../../images/investment_objective_slider.svg";

const StylesContainer = styled(Flex)`
  margin-top: 77px;

  .row-account-label {
    color: #a5a3a3;
  }

  .chart-label {
    color: #354a5f;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    visibility: hidden;
    width: 103px;

    &.active {
      visibility: visible;
    }
  }

  .chart-part-container {
    overflow: hidden;
    padding-right: 20px;
    position: relative;
    width: 100%;

    .chart-y-axis-title {
      bottom: 50px;
      color: #00bdc4;
      font-size: 16px;
      position: absolute;
      right: -6px;
      rotate: 90deg;

      &.top {
        bottom: 30px;
        right: -18px;
      }
    }
  }

  .ant-slider-horizontal {
    margin: 0 0 8px;
  }

  .ant-slider .ant-slider-handle {
    z-index: 1;

    &::before {
      background: url("${sliderImage}") center;
      height: 56px;
      left: -25px;
      top: -17px;
      width: 48px;
    }
  }

  .ant-slider .ant-slider-handle::after {
    display: none;
  }

  .ant-input {
    border-color: #00bdc4;
    border-radius: 12px;
    color: #354a5f;
    max-width: 361px;
    padding: 7px 15px;
    width: 100%;
  }

  .ant-input-number {
    border-color: #00bdc4;
    border-radius: 12px;
    color: #354a5f;
    padding: 4px 11px;
  }

  .ant-select {
    width: 186px;

    &.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
      padding: 7px 15px;
    }

    .ant-select-selector {
      border-color: #00bdc4;
      border-radius: 12px;
    }

    .ant-select-selection-item {
      font-size: 16px;
    }

    .ant-select-arrow {
      color: #354a5f;
    }
  }
`;

export default StylesContainer;
