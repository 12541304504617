import { useEffect, useState } from "react";
import { Button, Flex, Form, Input, InputNumber, Switch } from "antd";

import StyledModal from "./styles";

import {
  cleanPhoneMask,
  selectValue,
  setPhoneMask,
} from "../../../../utils/helper/general";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  firmName: "",
  next: false,
  proportal: false,
  affiliate: false,
  penny: false,
  level: false,
};

const ModalNextAdvisorSettings = ({
  activeAdvisorData,
  open,
  onCancel,
  handleSave,
}) => {
  const [form] = Form.useForm();
  const isEditeMode = !!activeAdvisorData;
  const [formState, setFormState] = useState(defaultValues);

  useEffect(() => {
    if (open) {
      const fieldsValue = activeAdvisorData
        ? {
            ...defaultValues,
            ...activeAdvisorData,
            ...activeAdvisorData.userManager?.access,
            firmName: activeAdvisorData.userManager?.firmName,
            phoneNumber:
              activeAdvisorData.userManager?.personalInfo?.phoneNumber,
          }
        : defaultValues;

      setFormState(fieldsValue);
      form.setFieldsValue(fieldsValue);
    }
  }, [open]);

  const onValuesChange = newValue => {
    setFormState(oldState => ({
      ...oldState,
      ...newValue,
    }));
  };

  return (
    <StyledModal
      footer={
        <Flex>
          <Button
            form={"nextAdvisorSettingsForm"}
            htmlType={"submit"}
            shape={"round"}
            style={{ width: 78, background: "#296469", color: "#ffffff" }}
          >
            Done
          </Button>

          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Cancel
          </Button>
        </Flex>
      }
      open={open}
      title={isEditeMode ? "Edit Advisor" : "New Advisor"}
      onCancel={onCancel}
      width={514}
    >
      <Form
        form={form}
        id={"nextAdvisorSettingsForm"}
        layout={"vertical"}
        onFinish={handleSave}
        onValuesChange={onValuesChange}
      >
        <Flex gap={16} style={{ marginBottom: 25 }} vertical>
          <Flex justify={"space-between"}>
            <Form.Item
              label={"First Name"}
              name={"firstName"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input onClick={selectValue} style={{ width: 206 }} />
            </Form.Item>
            <Form.Item
              label={"Last Name"}
              name={"lastName"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input onClick={selectValue} style={{ width: 206 }} />
            </Form.Item>
          </Flex>
          <Form.Item
            label={"Email"}
            name={"email"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={isEditeMode} onClick={selectValue} />
          </Form.Item>
          <Flex justify={"space-between"}>
            <Form.Item
              label={"Phone"}
              name={"phoneNumber"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                formatter={setPhoneMask}
                onClick={selectValue}
                parser={cleanPhoneMask}
                style={{ width: 206 }}
              />
            </Form.Item>
            <Form.Item label={"Company Name (Optional)"} name={"firmName"}>
              <Input onClick={selectValue} style={{ width: 206 }} />
            </Form.Item>
          </Flex>
        </Flex>
        <div className={"section-title"}>Access</div>
        <Flex className={"access-container"} gap={20} vertical>
          <Flex align={"center"} gap={12}>
            <Form.Item name={"next"} valuePropName="checked">
              <Switch id={"switchAccessNext"} />
            </Form.Item>
            <label className={"switch-label"} htmlFor={"switchAccessNext"}>
              Next
            </label>
          </Flex>
          <Flex align={"center"} gap={12}>
            <Form.Item name={"proportal"} valuePropName="checked">
              <Switch
                disabled={formState?.penny || formState.level}
                id={"switchAccessProportal"}
              />
            </Form.Item>
            <label className={"switch-label"} htmlFor={"switchAccessProportal"}>
              ProPortal
            </label>
          </Flex>
          <Flex align={"center"} gap={12}>
            <Form.Item name={"affiliate"} valuePropName="checked">
              <Switch
                disabled={formState.penny || formState.level}
                id={"switchAccessAffiliate"}
              />
            </Form.Item>
            <label className={"switch-label"} htmlFor={"switchAccessAffiliate"}>
              Affiliate
            </label>
          </Flex>
          <div>
            <Flex align={"center"} gap={12}>
              <Form.Item name={"penny"} valuePropName="checked">
                <Switch
                  disabled={!formState.proportal && !formState.level}
                  id={"switchAccessPenny"}
                />
              </Form.Item>
              <label className={"switch-label"} htmlFor={"switchAccessPenny"}>
                Penny
              </label>
            </Flex>
            <span style={{ color: "#2A2828", marginLeft: 50 }}>
              <i>* Requires either planning or Level</i>
            </span>
          </div>
          <Flex align={"center"} gap={12}>
            <Form.Item name={"level"} valuePropName="checked">
              <Switch
                disabled={formState?.proportal || formState.affiliate}
                id={"switchAccessLevel"}
              />
            </Form.Item>
            <label className={"switch-label"} htmlFor={"switchAccessLevel"}>
              Level (Demo only)
            </label>
          </Flex>
        </Flex>
      </Form>
    </StyledModal>
  );
};

export default ModalNextAdvisorSettings;
