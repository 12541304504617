import styled from "styled-components";

const StylesContainer = styled.header`
  align-items: end;
  border-bottom: 1px solid #c9c8c8;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;

  .description {
    color: #2a2a2a;
    text-align: right;
    font-size: 17px;
    font-style: normal;
    line-height: 130%;
    letter-spacing: -0.34px;
  }
`;

export default StylesContainer;
