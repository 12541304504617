import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  margin: 0 auto;
  width: 1124px;

  .header {
    color: #1e1e1e;
    margin-bottom: 54px;

    h3 {
      font-size: 26px;
      margin: 0;
    }

    p {
      font-size: 16px;
      line-height: 150%;
      margin: 0;
      max-width: 686px;
    }
  }

  .column-title {
    color: #000;
    font-size: 14px;
    margin: 0 0 11px;
    text-align: center;
  }

  .footer {
    margin-top: 15px;
  }

  .sortable-column {
    background: #e6e9f1;
    border-radius: 12px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
    display: flex;
    gap: 12px;
    height: fit-content;
    min-height: 308px;
    padding: 10px 8px 26px;
    width: 354px;
  }

  .ant-btn-primary:not(:disabled) {
    background: #428916;
  }
`;

export default StylesContainer;
