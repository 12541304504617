import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    position: relative;
    width: 1230px;
    padding: 53px 71px 50px 72px;
  }

  main {
    margin-top: 43px;

    h4 {
      color: #2a2a2a;
      font-size: 23px;
      font-style: normal;
      line-height: 160%;
      letter-spacing: -0.46px;
      margin: 0;
    }

    p {
      color: #2a2a2a;
      font-size: 16px;
      font-style: normal;
      line-height: 160%;
      letter-spacing: -0.32px;
    }
  }
`;

export default StylesContainer;
