import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 16px #0000000d;
  max-width: 822px;
  padding: 24px;

  .data-container {
    display: flex;
    flex-direction: column;

    .label {
      color: #a5a3a3;
      font-size: 12px;
      line-height: 16px;
    }

    .value {
      color: #1e1919;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
  }
`;

export default StylesContainer;
