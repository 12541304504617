import styled from "styled-components";
import { Layout } from "antd";

const StyledHeader = styled(Layout.Header)`
  align-items: end;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: 668px) {
    border-bottom: 1px solid #c9c8c8;
  }
`;

export default StyledHeader;
