import styled from "styled-components";
import { Carousel } from "antd";

const StyledCarousel = styled(Carousel)`
  &.slick-slider .slick-dots-bottom {
    bottom: 13px;
  }

  &.slick-slider .slick-dots li button {
    width: 12px;
    height: 8px;
    background: #17191c70;
    border-radius: 12px;
    opacity: 1;
  }

  &.slick-slider .slick-dots li.slick-active button {
    width: 26px;
    background: #12494e;
  }
`;

const CarouselPrimary = ({ children, ...props }) => (
  <StyledCarousel {...props}>{children}</StyledCarousel>
);

export default CarouselPrimary;
