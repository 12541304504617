import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-header {
      margin-bottom: 17px;
    }

    .ant-modal-title {
      color: #000;
      font-family: Roboto, sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .ant-modal-content {
      padding: 33px 30px 50px;
    }

    .ant-modal .ant-modal-body {
      overflow: auto;
    }

    .ant-modal-footer {
      margin-top: 17px;
    }
  }

  .ant-table-wrapper {
    min-width: 385px;
    width: 100%;

    .ant-table-thead > tr > th {
      background: #fff;
      color: #000000de;

      &::before {
        display: none;
      }
    }

    .ant-select {
      background: #ebf1f3;
      border-radius: 4px;
    }
  }
`;

export default StyledModal;
