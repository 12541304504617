import { Flex, Image, Layout, theme } from "antd";

const AdminHeader = () => {
  const { token } = theme.useToken();

  return (
    <Layout.Header
      style={{
        height: "fit-content",
        background: "#FFFFFF",
        padding: "32px 100px 19px 47px",
        borderBottom: `1px solid ${token.adminHeaderBorderBottomColor}`,
      }}
    >
      <Flex justify={"space-between"}>
        <Image
          id={"headerLogo"}
          preview={false}
          src={token.logoSrc}
          style={{ maxHeight: 40 }}
        />
        {token.slogan_src && <Image preview={false} src={token.slogan_src} />}
      </Flex>
    </Layout.Header>
  );
};

export default AdminHeader;
