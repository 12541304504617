const PennyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.262"
    height="19.288"
    viewBox="0 0 19.262 19.288"
  >
    <g id="Group_9186" transform="translate(0.524 0.5)">
      <path
        id="Path_7548"
        d="M15.563,2.674a9.158,9.158,0,0,0-12.935,0,9.3,9.3,0,0,0-2.5,8.082,2.925,2.925,0,0,0,2.9,2.463h.29a.423.423,0,0,0,.423-.423V11.12a.423.423,0,0,0-.423-.423h-.29a.416.416,0,0,1-.413-.348,6.772,6.772,0,0,1,1.8-5.892,6.625,6.625,0,0,1,9.369,9.37,6.815,6.815,0,0,1-5.908,1.85.415.415,0,0,1-.349-.414l0-2.034c.6,0,1.213.011,1.346.011a4.278,4.278,0,0,0,3.22-1.29,4.138,4.138,0,0,0,.1-5.5,4.07,4.07,0,0,0-2.959-1.41,4.122,4.122,0,0,0-3.043,1.2A4.3,4.3,0,0,0,5,9.362v.066c0,.178,0,.6,0,1.044,0,.074,0,.149,0,.224H5l0,4.566a2.925,2.925,0,0,0,2.466,2.9A9.64,9.64,0,0,0,9,18.288a9.28,9.28,0,0,0,6.562-2.679,9.157,9.157,0,0,0,0-12.935M7.52,9.362a1.822,1.822,0,0,1,.456-1.341,1.585,1.585,0,0,1,2.32.084,1.6,1.6,0,0,1-.042,2.12,1.791,1.791,0,0,1-1.382.493c-.121,0-.716,0-1.349-.011,0-.6,0-1.221,0-1.345"
        transform="translate(0 0)"
        fill="currentColor"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default PennyIcon;
