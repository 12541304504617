import { Button, Flex, Image } from "antd";
import CardChartPie from "../CardChartPie";

import { PORTFOLIO_LIST } from "../../constants";

import risk_line from "../../images/risk_line.svg";

const SectionChangeRisk = ({
  handleContinue,
  proposalData,
  updateProposalData,
}) => {
  const getPortfolioDataByType = type =>
    PORTFOLIO_LIST.find(it => it.value === type);

  return (
    <Flex
      align={"center"}
      gap={50}
      style={{ marginTop: 76, overflow: "auto" }}
      vertical
    >
      <Flex gap={31}>
        <CardChartPie
          active={proposalData.portfolio === "income"}
          onClick={() => {
            updateProposalData("portfolio", "income");
          }}
          series={getPortfolioDataByType("income").series}
          style={{ marginTop: 230 }}
          title={getPortfolioDataByType("income").label}
        />
        <CardChartPie
          active={proposalData.portfolio === "conservative"}
          onClick={() => {
            updateProposalData("portfolio", "conservative");
          }}
          series={getPortfolioDataByType("conservative").series}
          style={{ marginTop: 150 }}
          title={getPortfolioDataByType("conservative").label}
        />
        <Flex align={"center"} gap={45} vertical>
          <CardChartPie
            active={proposalData.portfolio === "moderate"}
            onClick={() => {
              updateProposalData("portfolio", "moderate");
            }}
            series={getPortfolioDataByType("moderate").series}
            style={{ marginTop: 48 }}
            title={getPortfolioDataByType("moderate").label}
          />
          <Button
            onClick={handleContinue}
            shape={"round"}
            style={{ background: "#1971B9", color: "#fff" }}
            type={"primary"}
          >
            Continue
          </Button>
        </Flex>
        <CardChartPie
          active={proposalData.portfolio === "aggressive"}
          onClick={() => {
            updateProposalData("portfolio", "aggressive");
          }}
          series={getPortfolioDataByType("aggressive").series}
          style={{ marginTop: 17 }}
          title={getPortfolioDataByType("aggressive").label}
        />
        <CardChartPie
          active={proposalData.portfolio === "equity"}
          onClick={() => {
            updateProposalData("portfolio", "equity");
          }}
          series={getPortfolioDataByType("equity").series}
          title={getPortfolioDataByType("equity").label}
        />
      </Flex>
      <Image src={risk_line} preview={false} width={923} />
    </Flex>
  );
};

export default SectionChangeRisk;
