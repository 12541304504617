export default {
  chart: {
    backgroundColor: "transparent",
    height: 300,
    spacingTop: 0,
    type: "pie",
  },
  tooltip: {
    outside: true,
    style: {
      zIndex: 9999,
    },
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      size: 158,
      innerSize: "60%",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      center: ["50%", "50%"],
      borderColor: "#ffffff",
      borderWidth: 2,
    },
  },
  legend: {
    enabled: true,
    layout: "vertical",
    align: "center",
    verticalAlign: "bottom",
    borderWidth: 0,
    labelFormatter: function () {
      return `<div style="width:245px; white-space: normal;">${
        this.y + "% " + this.name
      }</div>`;
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
};
