const MemberStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
  >
    <path
      d="M5,0,3.75,3.75H0l3.125,2.5L1.875,10,5,7.5,8.125,10,6.875,6.25,10,3.75H6.25Z"
      fill="#b9ebfd"
    />
  </svg>
);

export default MemberStar;
