const RolesPermissionsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.672"
    height="14.82"
    viewBox="0 0 16.672 14.82"
  >
    <path
      id="Icon_awesome-hammer"
      d="M16.537,5.614l-.655-.655a.463.463,0,0,0-.655,0l-.327.327-.837-.837a1.845,1.845,0,0,0-.473-1.783l-1.31-1.31a4.632,4.632,0,0,0-6.55,0l2.62,1.31v.543a1.851,1.851,0,0,0,.543,1.31l1.422,1.422a1.845,1.845,0,0,0,1.783.473l.837.837-.327.327a.463.463,0,0,0,0,.655l.655.655a.463.463,0,0,0,.655,0l2.62-2.62a.463.463,0,0,0,0-.655Zm-8.3-.44a2.809,2.809,0,0,1-.285-.346L.569,11.722a1.79,1.79,0,1,0,2.53,2.53L9.991,6.868A2.814,2.814,0,0,1,9.66,6.6L8.238,5.174Z"
      transform="translate(0)"
      fill="currentColor"
    />
  </svg>
);

export default RolesPermissionsIcon;
