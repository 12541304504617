import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 61px 10px;
  }
`;

export default StyledModal;
