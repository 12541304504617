import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .category-title {
    font-size: 18px;
    line-height: 24px;
    color: #354a5f;
    border-bottom: 1px solid #bec8ce;
  }

  .card {
    color: #354a5f;

    .title {
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

export default StylesContainer;
