import { Col, Flex, Image, Radio, Row, Space } from "antd";

import { RadioGroupLabel } from "./styles";

import demo from "./images/demo.png";

const SectionAboutMe = ({
  ButtonsContainer,
  proposalData,
  updateProposalData,
}) => (
  <Flex gap={50} justify={"space-between"} wrap={"wrap"}>
    <Flex style={{ marginTop: 50, maxWidth: 500 }} vertical>
      <h2 className="title" style={{ margin: "0 0 10px" }}>
        About Me
      </h2>
      <p className="description" style={{ margin: "0 0 44px" }}>
        Let us know a little about your current time horizon.
      </p>
      <Row gutter={[50, 50]} style={{ marginBottom: 69 }}>
        <Col xs={24} sm={8} md={9} lg={9}>
          <Radio.Group
            onChange={e =>
              updateProposalData("currentAgeRange", e.target.value)
            }
            value={proposalData.currentAgeRange}
          >
            <Space direction="vertical">
              <RadioGroupLabel style={{ marginBottom: 25 }}>
                Current Age
              </RadioGroupLabel>
              <Radio size={"large"} value={"-45"}>
                Under 45
              </Radio>
              <Radio value={"45-55"}>45 - 55</Radio>
              <Radio value={"56-65"}>56 - 65</Radio>
              <Radio value={"66-75"}>66 - 75</Radio>
              <Radio value={"75-"}>Over 75</Radio>
            </Space>
          </Radio.Group>
        </Col>
        <Col xs={24} sm={16} md={15} lg={15}>
          <Radio.Group
            onChange={e =>
              updateProposalData("incomeExpectations", e.target.value)
            }
            value={proposalData.incomeExpectations}
          >
            <Space direction="vertical">
              <RadioGroupLabel style={{ marginBottom: 17 }}>
                My expectations of income changes over the next several years
              </RadioGroupLabel>
              <Radio value={"I fear I might lose my job or I plan to retire"}>
                I fear I might lose my job or I plan to retire
              </Radio>
              <Radio
                value={"I anticipate my income will probably trend downward"}
              >
                I anticipate my income will probably trend downward
              </Radio>
              <Radio value={"I expect a fairly level income"}>
                I expect a fairly level income
              </Radio>
              <Radio value={"I anticipate a steadily growing income"}>
                I anticipate a steadily growing income
              </Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
      <ButtonsContainer />
    </Flex>
    <Image height={600} preview={false} src={demo} width={430} />
  </Flex>
);

export default SectionAboutMe;
