import { AuthenticationDetails } from "amazon-cognito-identity-js";
import userPool from "./userPool";

const changePassword = ({ username, oldPassword, newPassword }) =>
  new Promise((resolve, reject) => {
    const authenticationData = {
      Username: username,
      Password: oldPassword,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const cognitoUser = userPool.getCurrentUser();

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            return reject(err);
          }
          return resolve(result);
        });
      },
      onFailure: err => {
        return reject(err);
      },
    });
  });

export default changePassword;
