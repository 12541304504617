import PageConversations from "../pages/PageConversations";
import PageNextAdvisors from "../pages/PageNextAdvisors";
import PageNextLeads from "../pages/PageNextLeads";

export default [
  {
    path: "/super-admin/enterprises",
    Component: () => <div>Enterprises</div>,
  },
  {
    path: "/super-admin/all-users",
    Component: () => <div>All users</div>,
  },
  {
    path: "/super-admin/next-advisors",
    Component: PageNextAdvisors,
  },
  {
    path: "/super-admin/next-leads",
    Component: PageNextLeads,
  },
  {
    path: "/super-admin/user-activity",
    Component: () => <div>User Activity</div>,
  },
  {
    path: "/super-admin/roles-permissions",
    Component: () => <div>Roles & Permissions</div>,
  },
  {
    path: "/super-admin/conversations",
    Component: PageConversations,
  },
  {
    path: "/super-admin/training-lab",
    Component: () => <div>Training lab</div>,
  },
  {
    path: "/super-admin/environments",
    Component: () => <div>Environments</div>,
  },
];
