import styled from "styled-components";
import { Slider } from "antd";

import sliderImage from "../../images/investment_objective_slider.svg";

export const StyledSlider = styled(Slider)`
  &.ant-slider-horizontal {
    margin-bottom: 20px;
  }

  &.ant-slider .ant-slider-handle::before {
    background: url("${sliderImage}") center;
    width: 43px;
    height: 55px;
    left: -25px;
    top: -14px;
  }

  &.ant-slider .ant-slider-handle::after {
    display: none;
  }
`;

export const RiskToleranceLabel = styled.span`
  width: 120px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
