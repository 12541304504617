const ProductStatsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path
      d="M16.5,7.62V3a15.083,15.083,0,0,0,0,30V28.38c-4.5-.72-9-5.1-9-10.38S12,8.34,16.5,7.62ZM28.455,16.5H33A14.445,14.445,0,0,0,19.5,3V7.62A10.747,10.747,0,0,1,28.455,16.5ZM19.5,28.38V33A14.445,14.445,0,0,0,33,19.5H28.455A10.747,10.747,0,0,1,19.5,28.38Z"
      transform="translate(-3 -3)"
      fill="#749b9e"
    />
  </svg>
);

export default ProductStatsIcon;
