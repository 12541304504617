import { Flex, Image } from "antd";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { STATIC_CONTENT } from "./constant";

import demo from "./image/demo.png";

const ReportPageProposalHowToGet = ({ personaName, proposalData }) => (
  <StylesContainer id={"reportPageProposalHowToGet"}>
    <Flex className={"page-container"} gap={29} vertical>
      <ReportHeader personaName={personaName} />

      <main>
        <h2>How do we get there?</h2>
        <p style={{ marginBottom: 38 }}>
          {STATIC_CONTENT[proposalData?.riskTolerance]?.howWeGet}
        </p>
        <Image preview={false} src={demo} style={{ marginBottom: 58 }} />

        <Flex gap={66} justify={"space-between"}>
          <Flex flex={"734px"} vertical>
            <h3 style={{ marginBottom: 16 }}>What can we expect?</h3>
            <p style={{ marginBottom: 42 }}>
              {STATIC_CONTENT[proposalData?.riskTolerance]?.whatExpect}
            </p>
            <Image
              preview={false}
              src={`/assets/pdf/one_ascent_new/risk_tolerance/${proposalData?.riskTolerance?.toLowerCase()}.svg`}
            />
          </Flex>

          <Flex flex={"287px"}>
            <img
              alt={"Best, Worst and Average Years"}
              src={`/assets/pdf/one_ascent_new/benchmark/${proposalData?.riskTolerance?.toLowerCase()}.svg`}
              style={{ width: "100%" }}
            />
          </Flex>
        </Flex>
      </main>
    </Flex>
  </StylesContainer>
);

export default ReportPageProposalHowToGet;
