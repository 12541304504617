import { Button, Flex } from "antd";
import CardSponsor from "../CardSponsor";

import StyledModal from "./styles";

import { ReactComponent as CloseIcon } from "../../../../images/blue_light_close.svg";

const ModalSponsorsList = ({ handleClose, open, sponsorsList }) => (
  <StyledModal
    closeIcon={<CloseIcon />}
    footer={
      <Flex justify={"center"}>
        <Button
          onClick={handleClose}
          shape={"round"}
          size={"small"}
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: 16,
            color: "#4EABE9",
          }}
          type={"text"}
        >
          CLOSE
        </Button>
      </Flex>
    }
    onCancel={handleClose}
    open={open}
    title={"All Sponsors"}
    width={342}
  >
    <Flex gap={40} vertical>
      {sponsorsList?.map((it, index) => (
        <CardSponsor key={index} sponsorData={it} />
      ))}
    </Flex>
  </StyledModal>
);

export default ModalSponsorsList;
