import styled from "styled-components";
import { Select, Typography } from "antd";

export const Label = styled(Typography)`
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  color: #192849;
  margin-bottom: 12px;
`;

export const StyledSelect = styled(Select)`
  width: 384px;

  &.ant-select-single .ant-select-selector {
    border-color: #707070;
    border-radius: 20px;
    padding-left: 24px;
  }

  &.ant-select .ant-select-arrow {
    color: #707070;
  }
`;
