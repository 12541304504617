import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      padding: 29px 39px 36px 32px;
    }

    .ant-modal-header {
      margin-bottom: 65px;

      .ant-modal-title {
        font-weight: 800;
      }
    }

    .ant-modal-body {
      padding: 0 30px 0 50px;

      .step-marker {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 31px;
        width: 31px;
        height: 31px;
        font-size: 16px;
        font-weight: 800;
        background: #296469;
        color: #ffffff;
        border-radius: 50%;
      }

      .ant-upload-wrapper {
        width: 100%;

        .ant-upload-drag .ant-upload-btn {
          background: #e6f4ef;
          color: #0551a7;
          padding: 28px 12px;
          text-align: center;
          border-radius: 12px;

          .title {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
          }

          .description {
            font-size: 16px;
            margin: 0;
          }
        }
      }
    }

    .ant-modal-footer {
      margin-top: 48px;
    }
  }

  @media screen and (max-width: 550px) {
    &.ant-modal {
      .ant-modal-content {
        padding: 12px;
      }

      .ant-modal-body {
        padding: 0;
      }
    }
  }
`;

export default StyledModal;
