import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .row-selected-value {
    align-self: stretch;
    background: #071a4d;
    border-bottom: 4px solid #020d1f;
    border-left: 1px solid #020d1f;
    border-radius: 12px;
    border-right: 1px solid #020d1f;
    border-top: 1px solid #020d1f;
    display: flex;
    gap: 10px;
    line-height: 140%;
    margin: 0;
    padding: 10px 12px;

    .index {
      color: #e6ecf2;
      font-size: 18px;
      font-style: normal;
    }
  }
`;

export default StylesContainer;
