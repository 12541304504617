import styled from "styled-components";
import { Typography } from "antd";

const StylesContainer = styled(Typography.Text)`
  bottom: 20px;
  color: #224843;
  font-size: 16px;
  font-weight: 300;
  left: 40px;
  position: fixed;

  @media screen and (max-width: 720px) {
    position: initial;
  }
`;

export default StylesContainer;
