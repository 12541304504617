const EnterprisesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.273"
    height="15.546"
    viewBox="0 0 17.273 15.546"
  >
    <path
      id="Icon_material-business"
      d="M11.636,7.955V4.5H3V20.046H20.273V7.955ZM6.455,18.318H4.727V16.591H6.455Zm0-3.455H4.727V13.136H6.455Zm0-3.455H4.727V9.682H6.455Zm0-3.455H4.727V6.227H6.455ZM9.909,18.318H8.182V16.591H9.909Zm0-3.455H8.182V13.136H9.909Zm0-3.455H8.182V9.682H9.909Zm0-3.455H8.182V6.227H9.909Zm8.636,10.364H11.636V16.591h1.727V14.864H11.636V13.136h1.727V11.409H11.636V9.682h6.909Zm-1.727-6.909H15.091v1.727h1.727Zm0,3.455H15.091v1.727h1.727Z"
      transform="translate(-3 -4.5)"
      fill="currentColor"
    />
  </svg>
);

export default EnterprisesIcon;
