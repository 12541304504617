import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    max-height: 80vh;
    padding: 23px 31px 31px;
    overflow: auto;
  }
`;

export default StyledModal;
