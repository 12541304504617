import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      padding: 29px 24px 24px 32px;

      .ant-modal-close {
        top: 32px;
        right: 32px;
      }

      .ant-modal-header {
        margin-bottom: 23px;

        .ant-modal-title {
          font-weight: 800;
        }
      }
    }
  }
`;

export default StyledModal;
