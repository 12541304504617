import styled from "styled-components";
import { InputNumber } from "antd";

export const StyledInput = styled(InputNumber)`
  width: 127px;
  border-radius: unset;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
`;
