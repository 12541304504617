import { Button, Modal, Row, theme, Typography } from "antd";

import Close from "../../../../icon/Close";

const ModalConfirmSaveProposal = ({
  handleClose,
  handleSaveProposal,
  isFirstSave,
  open,
}) => {
  const { token } = theme.useToken();

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      closeIcon={<Close />}
      title={"Confirm Save"}
      footer={
        <Row justify={"space-between"}>
          <Button type={"text"} shape={"round"} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSaveProposal}
            shape={"round"}
            style={{
              background: token.tenantColorPrimary,
              color: "#FFFFFF",
              borderColor: "transparent",
            }}
          >
            Yes, Save New Proposal
          </Button>
        </Row>
      }
    >
      <div style={{ textAlign: "center", padding: "34px 0" }}>
        {!isFirstSave && (
          <Typography.Paragraph style={{ fontSize: 16 }}>
            Saving these changes will override your
            <br />
            previously saved proposal.
          </Typography.Paragraph>
        )}
        <Typography.Paragraph style={{ fontSize: 16 }}>
          Do you want to continue?
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default ModalConfirmSaveProposal;
