import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-header {
      margin-bottom: 29px;
    }

    .ant-modal-footer {
      margin-top: 21px;
    }
  }

  .access-container {
    background: #ebf1f3;
    padding: 22px 32px 19px;
    border-radius: 5px;
  }

  .section-title {
    font-size: 18px;
    font-weight: 500;
    color: #23292c;
  }

  .switch-label {
    font-weight: 500;
    color: #2a2828;
  }

  .ant-input,
  .ant-input-number {
    background: #ebf1f3;
    border-color: transparent;
  }

  .ant-switch.ant-switch-checked,
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #6073e2;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-form-item .ant-form-item-label {
    padding: 0;

    > label {
      color: #8396a6;
    }
  }
`;

export default StyledModal;
