export const defaultProposalData = {
  organization: "OneAscent",
  prospectType: "WealthManagement",
  investmentsToAvoid: [],
  investmentsToAlignWith: [],
  investmentObjective: "income",
  riskTolerance: "moderate",
  investmentAmount: 100000,
  investmentDuration: 10,
  contributions: 0,
  currentAge: 60,
  currentSpouseAge: 0,
  householdIncome: 100000,
};
