import styled from "styled-components";

export const StyledCard = styled.div`
  height: 146px;
  border-radius: 12px;
  padding: 10px 10px 27px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #00000029;
  margin: 2px 0;
`;
