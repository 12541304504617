const animationDuration = 600;

export const topChartConfig = {
  chart: {
    height: 180,
    width: 700,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    backgroundColor: "transparent",
    animation: {
      duration: animationDuration,
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  title: {
    text: "",
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      lineWidth: 3,
    },
  },
  xAxis: {
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    max: 40,
    opposite: true,
    lineWidth: 1,
    tickAmount: 8,
    gridLineColor: "#BEC8CE60",
    labels: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
};

export const bottomChartConfig = {
  chart: {
    height: 133,
    width: 700,
    marginTop: 24,
    marginBottom: 0,
    marginRight: 0,
    backgroundColor: "transparent",
    animation: {
      duration: animationDuration,
    },
  },

  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    max: 20,
    opposite: true,
    lineWidth: 1,
    tickAmount: 5,
    gridLineColor: "#BEC8CE60",
    labels: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
};
