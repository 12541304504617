import styled from "styled-components";
import { Flex } from "antd";

import background from "./images/background.svg";
import background_mobile from "./images/background_mobile.svg";

const StylesContainer = styled(Flex)`
  background: url(${background}) no-repeat bottom;
  background-size: contain;
  box-sizing: border-box;
  padding: 45px 55px;
  height: 100%;

  header {
    border-bottom: 1px solid #c9c8c8;
    padding-bottom: 26px;
    width: 100%;
  }

  .title {
    color: #02494e;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: -0.56px;
    line-height: 130%;
    margin: 0 0 18px;
  }

  .description {
    color: #02494e;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 150%;
    margin: 0;
  }

  .body {
    box-sizing: border-box;
    max-width: 1200px;
    padding: 77px 0 77px 72px;
    width: 100%;
  }

  .form-container {
    margin-top: 22px;
    max-width: 481px;
    min-width: 380px;
    width: min-content;
    z-index: 1;

    .ant-input-number {
      width: 100%;
    }

    .aim-container {
      padding: 10px 0;
      width: 100%;

      .ant-radio-button-wrapper {
        border-radius: 12px;
        border-top: 1px solid #215054;
        border-right: 1px solid #215054;
        border-bottom: 4px solid #215054;
        border-left: 1px solid #215054;
        font-size: 16.8px;
        height: 48px;
        padding: 6px 6px 6px 32px;
        width: 250px;

        &.ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          ) {
          font-weight: bold;
        }

        &::before {
          display: none;
        }
      }
    }

    .risk-line-image {
      margin: 16px 0 34px;
    }

    .risk-container {
      margin-top: 80px;

      .ant-radio-wrapper .ant-radio-inner {
        border-color: #4b4747;
      }

      .rotation-container .ant-radio-wrapper {
        transform: rotate(315deg) translate(10px, 0);
        width: 30px;
      }
    }
  }

  .hero-section {
    background: #fff;
    border-radius: 12px;
    border-top: 1px solid var(--Forest-Tertiary, #749b9e);
    border-right: 1px solid var(--Forest-Tertiary, #749b9e);
    border-bottom: 5px solid var(--Forest-Tertiary, #749b9e);
    border-left: 1px solid var(--Forest-Tertiary, #749b9e);
    box-sizing: border-box;
    padding: 40px 32px 32px;
    position: relative;
    width: 403px;
    z-index: 1;

    .icon-container {
      background: #fff;
      border-radius: 12px;
      box-sizing: border-box;
      height: 93px;
      padding: 24px;
      position: absolute;
      top: -60px;
      width: 93px;

      border-top: 1px solid var(--Forest-Tertiary, #749b9e);
      border-right: 1px solid var(--Forest-Tertiary, #749b9e);
      border-bottom: 5px solid var(--Forest-Tertiary, #749b9e);
      border-left: 1px solid var(--Forest-Tertiary, #749b9e);
    }
  }

  @media screen and (max-width: ${({ token }) => token.screenMDMax}px) {
    background: url(${background_mobile}) no-repeat bottom;
    padding: 42px 25px 28px;

    &:after {
      bottom: -60px;
      left: -14px;
      height: 100vw;
      rotate: 90deg;
      top: unset;
      width: calc(100% + 28px);
    }

    header {
      padding-bottom: 16px;
    }

    .logo {
      height: 38px;
      width: 123px;
    }

    .body {
      padding: 32px 0;
    }

    .form-container {
      width: 340px;
      min-width: unset;

      .risk-container {
        margin-top: 32px;
        padding-left: 32px;

        .rotation-container {
          gap: 18px;
          flex-direction: column;

          .ant-radio-wrapper {
            transform: unset;
          }
        }
      }
    }

    .risk-line-image {
      display: none;
    }

    .hero-section {
      display: none;
    }
  }
`;

export default StylesContainer;
