import { Button, Flex, Modal } from "antd";

import Close from "../../../../icon/Close";

const ModalConfirmRiskChange = ({ onCancel, onSuccess, open }) => (
  <Modal
    closeIcon={<Close />}
    footer={
      <Flex gap={16} justify={"center"}>
        <Button
          onClick={onCancel}
          shape={"round"}
          size={"large"}
          style={{
            backgroundColor: "#192849",
            color: "#FFFFFF",
            fontSize: 18,
          }}
        >
          Stick with Recommendation
        </Button>
        <Button
          shape={"round"}
          size={"large"}
          style={{
            fontSize: 18,
            backgroundColor: "#192849",
            color: "#FFFFFF",
          }}
          onClick={onSuccess}
        >
          Continue
        </Button>
      </Flex>
    }
    onCancel={onCancel}
    open={open}
  >
    <div style={{ textAlign: "center", marginBottom: 30 }}>
      <p style={{ fontSize: 22 }}>
        Changing your risk tolerance will change the investment recommendations.
      </p>
      <p style={{ fontSize: 18 }}>You can also change this later.</p>
    </div>
  </Modal>
);

export default ModalConfirmRiskChange;
