import StylesContainer from "./styles";

import { ReactComponent as PennyImage } from "../../images/penny_logo_1.svg";

const PennyChatToggler = ({ handleClick }) => (
  <StylesContainer align={"middle"} justify={"center"} onClick={handleClick}>
    <PennyImage />
  </StylesContainer>
);

export default PennyChatToggler;
