import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Flex } from "antd";
import CarouselHorizontal from "../CarouselHorizontal";

import { StyledButton, Title } from "./styles";

import {
  NEWS_CHANNEL_INSIGHT_ID,
  NEWS_CHANNEL_MEMBER_ID,
} from "../../../../constants";

import { getArticlesByChannelId, getFtsEntity } from "../../../../requests/fts";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";

const SectionNews = ({ handleShowNewsList, token, userId }) => {
  const [memberNews, setMemberNews] = useState([]);
  const [insightNew, setInsightNews] = useState([]);

  useEffect(() => {
    getArticlesByChannelId({
      channelId: NEWS_CHANNEL_MEMBER_ID,
      quantity: 100,
      token,
      userId,
    })
      .then(articles => {
        getFtsEntity({
          ids: Array.from(
            new Set(
              articles.map(it => it.contributor ?? it.source ?? it.author)
            )
          ).filter(it => it > 0),
          token,
        }).then(sourceList => {
          setMemberNews(
            articles
              .map(articleData => ({
                ...articleData,
                sourceName: sourceList.find(
                  sourceData =>
                    sourceData.id ===
                    (articleData.contributor ??
                      articleData.source ??
                      articleData.author)
                )?.name,
              }))
              .sort((a, b) => dayjs(b.updated) - dayjs(a.updated))
          );
        });
      })
      .catch(console.log);

    getArticlesByChannelId({
      channelId: NEWS_CHANNEL_INSIGHT_ID,
      quantity: 100,
      token,
      userId,
    })
      .then(articles => {
        getFtsEntity({
          ids: Array.from(
            new Set(
              articles.map(it => it.contributor ?? it.source ?? it.author)
            )
          ).filter(it => it > 0),
          token,
        }).then(sourceList => {
          setInsightNews(
            articles
              .map(articleData => ({
                ...articleData,
                sourceName: sourceList.find(
                  sourceData =>
                    sourceData.id ===
                    (articleData.contributor ??
                      articleData.source ??
                      articleData.author)
                )?.name,
              }))
              .sort((a, b) => dayjs(b.updated) - dayjs(a.updated))
          );
        });
      })
      .catch(console.log);
  }, []);

  return (
    <Flex gap={36} vertical>
      <Flex gap={15} vertical>
        <Title>Member News and Thought Leadership</Title>
        <CarouselHorizontal news={memberNews?.slice(0, 3)} />
        <StyledButton
          block
          onClick={() => handleShowNewsList(memberNews?.slice(0, 10))}
          shape={"round"}
        >
          More Member News
          <BlueArrow style={{ rotate: "270deg", marginLeft: 8 }} />
        </StyledButton>
      </Flex>
      <Flex gap={15} vertical>
        <Title>Industry Insights</Title>
        <CarouselHorizontal news={insightNew?.slice(0, 3)} />
        <StyledButton
          block
          onClick={() => handleShowNewsList(insightNew?.slice(0, 10))}
          shape={"round"}
        >
          More Insights
          <BlueArrow style={{ rotate: "270deg", marginLeft: 8 }} />
        </StyledButton>
      </Flex>
    </Flex>
  );
};

export default SectionNews;
