import styled from "styled-components";
import { Select } from "antd";

export const StyledSelect = styled(Select)`
  width: 100%;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: transparent;
    background: #ebf1f3;
  }
`;
