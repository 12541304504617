const Bullet = ({ size = 8, color = "#516474" }) => (
  <div
    style={{
      background: color,
      borderRadius: "50%",
      height: size,
      width: size,
    }}
  ></div>
);

export default Bullet;
