import { useContext } from "react";
import State from "../../../../context";
import { Button, Flex, Image } from "antd";

import StylesContainer from "./styles";

import { samplesData } from "./constants";

import { FilePdfOutlined } from "@ant-design/icons";
import {
  isOrganizationAstor,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../../utils/helper/specialized";

const PageAdminProposal = () => {
  const [state] = useContext(State);

  const getSamplesList = organization => {
    if (isOrganizationOneascent(organization)) {
      return samplesData.oneascent;
    } else if (isOrganizationAstor(organization)) {
      return samplesData.astor;
    } else if (isOrganizationRivershares(organization)) {
      return samplesData.rivershares;
    } else {
      return samplesData.default;
    }
  };

  const currentOrganizationSamplesData = getSamplesList(
    state.organization?.name
  );

  return (
    <StylesContainer gap={74} vertical>
      {currentOrganizationSamplesData.samplesList.map((it, index) => (
        <Flex className={"card"} gap={16} key={index} vertical>
          <div className={"category-title"}>{it.name}</div>
          <Flex gap={28}>
            <Image
              src={currentOrganizationSamplesData.previewImageSrc}
              preview={false}
            />
            <Flex gap={8} style={{ marginTop: 10 }} vertical>
              <div className={"title"}>{it.name}</div>
              <div>Last update: {it.lastUpdateDate}</div>
              <Flex gap={16} style={{ marginTop: 24 }}>
                {it.buttonsList.map((buttonData, index) => (
                  <Button
                    icon={<FilePdfOutlined />}
                    key={index}
                    onClick={() => window.open(buttonData.link)}
                    shape={"round"}
                    size={"small"}
                    style={{ textTransform: "capitalize" }}
                    type={"primary"}
                  >
                    View {buttonData.name}
                  </Button>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </StylesContainer>
  );
};

export default PageAdminProposal;
