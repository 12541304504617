import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .header {
    background: #001141;
    padding: 12px 18px;
    border-radius: 12px 18px 0 0;

    .title {
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
    }

    .clear-button {
      font-size: 16px;
      font-weight: 500;
      color: #4eabe9;
    }
  }

  .body {
    max-height: 373px;
    background: #e5f1f5;
    border-radius: 0 0 12px 12px;
    padding: 30px 23px 31px 18px;
    overflow: auto;
  }

  .body.active {
    padding: 20px 23px 0;
  }

  .date {
    min-width: 145px;
    font-size: 14px;
    color: #aaaaaa;
  }
`;

export default StylesContainer;
