import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    margin-bottom: 34px;
  }

  & .ant-modal-footer {
    margin-top: 33px;
  }

  .list-title {
    border-bottom: 1px solid #7ebaba;
    color: #3e5377;
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

export default StyledModal;
