export const defaultProposalData = {
  contributions: 0,
  currentAge: 60,
  investmentAmount: 100000,
  investmentDuration: 10,
  investmentObjective: "Accumulate",
  organization: "Rivershares",
  riskTolerance: "Income/Growth",
};

export const riskToleranceCardsData = [
  {
    description:
      "Conservative. Investors seeking consistent returns with a low degree of potential loss.",
    riskTolerance: "Income",
    title: "Income",
  },
  {
    description:
      "Moderate. Investors willing to balance capital appreciation and capital preservation and who are willing to accept some volatility and potential loss.",
    riskTolerance: "Income/Growth",
    title: "Income and Growth",
  },
  {
    description:
      "Growth. Investors with long term (20+year) time horizons willing to accept higher volatility and potential loss but less downside than an aggressive investor.",
    riskTolerance: "Growth",
    title: "Growth",
  },
];
