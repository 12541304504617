const PlusOutlined = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path
      d="M9.51562 7.0625H7.4375V4.98437C7.4375 4.92422 7.38828 4.875 7.32812 4.875H6.67188C6.61172 4.875 6.5625 4.92422 6.5625 4.98437V7.0625H4.48437C4.42422 7.0625 4.375 7.11172 4.375 7.17188V7.82812C4.375 7.88828 4.42422 7.9375 4.48437 7.9375H6.5625V10.0156C6.5625 10.0758 6.61172 10.125 6.67188 10.125H7.32812C7.38828 10.125 7.4375 10.0758 7.4375 10.0156V7.9375H9.51562C9.57578 7.9375 9.625 7.88828 9.625 7.82812V7.17188C9.625 7.11172 9.57578 7.0625 9.51562 7.0625Z"
      fill="white"
    />
    <path
      d="M7 1.375C3.61758 1.375 0.875 4.11758 0.875 7.5C0.875 10.8824 3.61758 13.625 7 13.625C10.3824 13.625 13.125 10.8824 13.125 7.5C13.125 4.11758 10.3824 1.375 7 1.375ZM7 12.5859C4.1918 12.5859 1.91406 10.3082 1.91406 7.5C1.91406 4.6918 4.1918 2.41406 7 2.41406C9.8082 2.41406 12.0859 4.6918 12.0859 7.5C12.0859 10.3082 9.8082 12.5859 7 12.5859Z"
      fill="white"
    />
  </svg>
);

export default PlusOutlined;
