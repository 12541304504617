import styled from "styled-components";
import { Form } from "antd";

const StyledForm = styled(Form)`
  & .ant-form-item {
    margin: 0;
  }

  & .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #009a00;
    background-color: #009a00;
  }

  & .ant-input-number {
    background: #ebf1f3;
    border-color: transparent;
  }
`;

export default StyledForm;
