import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Flex, Input, theme } from "antd";
import ModalAssignLead from "./components/ModalAssignLead";
import ModalArchiveManagedUser from "../../../../molecule/ModalArchiveManagedUser";
import MenuActions from "../../../../atom/MenuActions";

import { StyledTable } from "./styles";

import {
  addLead,
  archiveLead,
  editLead,
} from "../../../../utils/request/manager";

const getColumns = ({
  searchedText,
  handleArchiveLeadAction,
  handleAssignAction,
  handleEditAction,
}) => [
  {
    dataIndex: "firstName",
    filteredValue: [searchedText],
    key: "firstName",
    onFilter: (value, record) =>
      String(record.name).toLowerCase().includes(value.toLowerCase()) ||
      String(record.email).toLowerCase().includes(value.toLowerCase()),
    render: (it, restValues) => `${it} ${restValues.lastName}`,
    sorter: (a, b) => a.firstName?.localeCompare(b.firstName),
    title: "Lead Name",
  },
  {
    dataIndex: "email",
    key: "email",
    render: value => <a href={"mailto:" + value}>{value}</a>,
    sorter: (a, b) => a.email?.localeCompare(b.email),
    title: "Email",
  },
  {
    dataIndex: "valueMap",
    key: "question",
    render: valueMap => valueMap?.leadInitial?.question,
    title: "Question",
  },
  {
    dataIndex: "valueMap",
    key: "advisor",
    render: valueMap => valueMap?.leadInitial?.assignQuestionTo,
    sorter: (a, b) =>
      a.valueMap?.leadInitial?.assignQuestionTo?.localeCompare(
        b.valueMap?.leadInitial?.assignQuestionTo
      ),
    title: "Advisor",
  },
  {
    dataIndex: "valueMap",
    key: "lastAction",
    render: valueMap =>
      dayjs(valueMap?.leadInitial?.updatedAt).format("MM/DD/YY, h:mma"),
    sorter: (a, b) => {
      console.log(a);

      return (
        dayjs(a.valueMap?.leadInitial?.updatedAt) -
        dayjs(b.valueMap?.leadInitial?.updatedAt)
      );
    },
    title: "Last Action",
    width: 170,
  },
  {
    dataIndex: "userType",
    key: "status",
    title: "Status",
    width: 150,
  },
  {
    dataIndex: "actionsMenu",
    className: "actions-menu-table-cell",
    fixed: "right",
    key: "actionsMenu",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          props: restValues,
          handleAssignAction,
          handleArchiveLeadAction,
          handleEditAction,
        })}
      />
    ),
    title: "",
    width: 40,
  },
];

const getItems = ({ props, handleArchiveLeadAction, handleEditAction }) =>
  [
    {
      key: "edit",
      label: "Edit",
      onClick: () => handleEditAction(props.email),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: <span style={{ color: "red" }}>Archive lead</span>,
      onClick: () => handleArchiveLeadAction(props.email),
    },
  ].filter(it => !it.hidden);

const PageNextLeads = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const leadsList = state.managedUserManagers
    ?.map(it =>
      it.userManager?.managedUsers?.filter(
        it =>
          it.userType === "Unassigned" ||
          it.userType === "Assigned" ||
          it.userType === "Released"
      )
    )
    ?.filter(it => it !== undefined)
    ?.flat()
    ?.filter(it => it.entryPoint === "addedByOrgManager")
    ?.map((it, index) => ({
      ...it,
      key: index,
    }));

  const handleAssignAction = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("assignLeadModal");
  };

  const handleArchiveLead = () => {
    const leadEmail = state.activeEmail;
    state.setKeyValue("loading", true);

    const userManagerEmail = state.managedUserManagers.find(it =>
      it.userManager?.managedUsers?.find(it => it.email === leadEmail)
    )?.email;

    if (userManagerEmail) {
      archiveLead({
        userManagerEmail,
        email: leadEmail,
      })
        .then(() =>
          state.setAdminData().then(() => {
            state.closeModal("archiveManagedUserModal");
            state.showSuccess(`Lead ${leadEmail} archived`);
            state.setKeyValue("activeEmail", null);
            state.setKeyValue("loading", false);
          })
        )
        .catch(console.log);
    } else {
      state.setKeyValue("loading", false);
      state.showWarning("Manager do not identified. Please, email support.");
    }
  };

  const handleArchiveLeadAction = leadEmail => {
    state.setKeyValue("activeEmail", leadEmail);
    state.openModal("archiveManagedUserModal");
  };

  const handleAssignLead = values => {
    state.setKeyValue("loading", true);

    addLead({
      ...values,
      birthYear: 1987,
      leadInitial: {
        ...values,
        rate: 0,
        isNotifyAdvisor: values.isNotifyAdvisor,
        phoneNumber: `${values.phoneNumber}`,
        isNewLead: true,
      },
      phoneNumber: `${values.phoneNumber}`,
      userManagerEmail: values.assignQuestionTo,
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.showSuccess("Lead assigned");
          state.closeModal("assignLeadModal");
          state.setKeyValue("activeEmail", null);
        })
      )
      .catch(error => {
        state.setKeyValue("loading", false);
        state.showError(error.response.data);
      });
  };

  const handleUpdateLead = values => {
    state.setKeyValue("loading", true);

    const leadData = state.managedUserManagers
      .map(it => it.userManager?.managedUsers)
      ?.flat()
      ?.find(it => it?.email === values?.email);

    editLead({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      birthYear: leadData.birthYear,
      phoneNumber: values.phoneNumber,
      leadInitial: {
        ...leadData?.valueMap?.leadInitial,
        ...values,
        isNewLead: true,
      },
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.showSuccess(`Lead ${values.email} updated.`);
          state.closeModal("assignLeadModal");
          state.setKeyValue("activeEmail", null);
        })
      )
      .catch(error => {
        state.showError(error?.response?.data);
        state.setKeyValue("loading", false);
      });
  };

  const handleEditAction = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("assignLeadModal");
  };

  return (
    <>
      <div
        style={{
          fontSize: 20,
          fontWeight: 800,
          color: token.colorPrimary,
          padding: "31px 46px",
          borderBottom: "1px solid #C9C8C8",
        }}
      >
        Next Leads
      </div>
      <Flex gap={12} style={{ padding: "30px 109px 30px 46px" }} vertical>
        <Flex justify={"space-between"}>
          <Input
            style={{
              maxWidth: 228,
              height: 37,
              borderRadius: 25,
            }}
            placeholder="Search..."
            onChange={event => setSearchedText(event.target.value)}
          />
          <Button
            onClick={() => state.openModal("assignLeadModal")}
            shape={"round"}
            size={"small"}
            style={{ background: "#03494E", color: "#FFFFFF" }}
            type={"text"}
          >
            + Add Lead
          </Button>
        </Flex>
        <StyledTable
          columns={getColumns({
            searchedText,
            handleAssignAction,
            handleArchiveLeadAction,
            handleEditAction,
          })}
          dataSource={leadsList}
          loading={state.loading}
          pagination={false}
          scroll={{
            x: 1300,
            y: "calc(100vh - 280px)",
          }}
          token={token}
        />
      </Flex>

      <ModalArchiveManagedUser
        instanceName={"lead"}
        loading={state.loading}
        onCancel={() => state.closeModal("archiveManagedUserModal")}
        onConfirm={handleArchiveLead}
        open={state.archiveManagedUserModal}
      />
      <ModalAssignLead
        activeLeadData={leadsList?.find(it => it.email === state.activeEmail)}
        advisorsList={state.managedUserManagers}
        loading={state.loading}
        handleAssignLead={handleAssignLead}
        handleUpdateLead={handleUpdateLead}
        onCancel={() => {
          state.closeModal("assignLeadModal");
          state.setKeyValue("activeEmail", null);
        }}
        open={state.assignLeadModal}
      />
    </>
  );
};

export default PageNextLeads;
