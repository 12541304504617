export const checkboxList = [
  "Abortion",
  "Human rights violations",
  "Pornography",
  "Predatory lending",
  "Adult entertainment",
  "Alcohol",
  "Tobacco",
  "Cannabis",
  "Gambling",
  "Severe ethics controversies",
];
