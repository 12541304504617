import logo from "../images/logo.png";
import logo_short from "../images/logo_short.png";
import want_to_talk from "../images/want_to_talk.png";

export default {
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimaryButtonText: "#FFFFFF",
  comparisonProductsColors: ["#7908A3", "#0076FF", "#001A45"],
  goalsOnboardingBackground: "#EBEBEB",
  goalsOnboardingStepDividerLineColor: "#0E51A7",
  goalsOnboardingButtonContinueBackground: "#0551A7",
  goalsOnboardingButtonContinueColor: "#ffffff",
  goalsOnboardingButtonContinueBorderRadius: 30,
  goalsOnboardingSectionBasicInfoListMarkerBackground: "#516474",
  goalsOnboardingSectionRiskToleranceButtonIconColor: "#FFFFFF",
  goalsOnboardingSectionRiskToleranceButtonIconBackground: "#437CBD",
  goalsOnboardingSectionRiskToleranceButtonColor: "#FFFFFF",
  goalsOnboardingSectionRiskToleranceButtonConservativeBackground: "#0551A7",
  goalsOnboardingSectionRiskToleranceButtonModerateBackground: "#0551A7",
  goalsOnboardingSectionRiskToleranceButtonAggressiveBackground: "#0551A7",
  goalsOnboardingFormRegistrationButtonSubmitBackground: "#0551A7",
  goalsOnboardingFormRegistrationButtonSubmitBorderRadius: 30,
  iconGoalTypeBackground: "#0E51A7",
  iconGoalTypeColor: "#FFFFFF",
  incomeChartWithTypesContributionsColor: "#4997D1",
  incomeChartWithTypesNavbarButtonBackground: "#FFFFFF",
  incomeChartWithTypesNavbarButtonColor: "#2A2828",
  incomeChartWithTypesNavbarContainerBackground: "#D9F3F7",
  logoSrc: logo,
  pageDashAllEpisodesButtonBackground: "#296469",
  pageDashCreateNewGoalsButtonBackground: "#0551A7",
  pageDashCreateNewGoalsButtonColor: "#FFFFFF",
  page_dash_section_want_talk_button_background: "#0551A7",
  page_dash_section_want_talk_button_color: "#FFFFFF",
  page_dash_section_want_talk_button_copy: "Get Advice",
  page_dash_section_want_talk_copy: `You can click the “Get Advice” button any time to get in touch with a financial professional.`,
  pageDashWantToTalkImageLink: want_to_talk,
  pageDashWantToTalkLimitedContent: "",
  pageDashWantToTalkTitleContent: "Want to talk to a<br/>Bluepoint Advisor?",
  pageGoalUpdateProgressButtonBackground: "#0551A7",
  pageGoalUpdateProgressButtonColor: "#FFFFFF",
  pageLoginButtonSubmitColor: "#0551A7",
  pageLoginLogo: logo,
  pennySendMessageButtonBackground: "#0551A7",
  sidebarAvatarBackground: "#FFFFFF",
  sidebarAvatarColor: "#1056AA",
  sidebarBackground: "#0D1238",
  sidebarIconColor: "#9EA0AF",
  sidebarIconColorActive: "#FFFFFF",
  sidebarLogo: logo_short,
  tenantColorPrimary: "#0551A7",
};
