import { CognitoUser } from "amazon-cognito-identity-js";
import userPool from "./userPool";

const confirmPasswordChange = ({ username, confirmationCode, newPassword }) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(confirmationCode, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: err => {
        reject(err);
      },
    });
  });

export default confirmPasswordChange;
