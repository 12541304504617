const DocumentPage = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="15.377"
    height="20.124"
    viewBox="0 0 15.377 20.124"
  >
    <path
      d="M8743.756,7127.125h-12.138a1.615,1.615,0,0,1-1.619-1.609V7108.61a1.616,1.616,0,0,1,1.619-1.609h6.626v4.025a2.272,2.272,0,0,0,2.275,2.263h4.856v12.227A1.616,1.616,0,0,1,8743.756,7127.125ZM8733.6,7123.1a.716.716,0,1,0,0,1.432h8.579a.716.716,0,1,0,0-1.432Zm0-4.248a.715.715,0,1,0,0,1.43h8.579a.715.715,0,1,0,0-1.43Zm0-4.248a.715.715,0,1,0,0,1.43h1.8a.715.715,0,1,0,0-1.43Zm11.526-2.623h-4.6a.958.958,0,0,1-.96-.956v-3.648a.373.373,0,0,1,.374-.372,1.619,1.619,0,0,1,1.042.372l3.858,3.2a1.479,1.479,0,0,1,.542,1.152A.252.252,0,0,1,8745.123,7111.981Z"
      transform="translate(-8729.999 -7107)"
    />
  </svg>
);

export default DocumentPage;
