import { Flex } from "antd";
import RadioButton from "../../component/RadioButton";

import StylesContainer from "./styles";

import { getCurrencyValue } from "../../../../../utils/helper/general";

const ReportPageIpq1 = ({ children, prospectObjective }) => (
  <StylesContainer className={"page-container"} id={"reportPageIpq1"}>
    {children}

    <main>
      <Flex gap={16} vertical>
        <h4>Planning Center</h4>
        <Flex gap={40}>
          <Flex gap={7} flex={"440px"} vertical>
            <h5>Current Age</h5>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "-45"}
              />
              <span>Under 45</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "45-55"}
              />
              <span>45 - 55</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "56-65"}
              />
              <span>56 - 65</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "66-75"}
              />
              <span>66 - 75</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.currentAgeRange === "75-"}
              />
              <span>Over 75</span>
            </div>
          </Flex>
          <Flex gap={7} flex={"590px"} vertical>
            <h5>
              Your expectations of income changes over the next several years.
            </h5>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.incomeExpectations ===
                  "I fear I might lose my job or I plan to retire"
                }
              />
              <span>I fear I might lose my job or I plan to retire.</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.incomeExpectations ===
                  "I anticipate my income will probably trend downward"
                }
              />
              <span>I anticipate my income will probably trend downward.</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.incomeExpectations ===
                  "I expect a fairly level income"
                }
              />
              <span>I expect a fairly level income.</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.incomeExpectations ===
                  "I anticipate a steadily growing income"
                }
              />
              <span>I anticipate a steadily growing income.</span>
            </div>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap={16} vertical>
        <h4>Time Horizon and Investment Experience</h4>
        <Flex gap={40}>
          <Flex gap={7} flex={"440px"} vertical>
            <h5>
              When do you plan to begin withdrawal of at least 5% of your
              investments annually?
            </h5>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.beginWithdrawal === "-3"}
              />
              <span>0 - 3 years</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.beginWithdrawal === "3-5"}
              />
              <span>3 - 5 years</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.beginWithdrawal === "5-10"}
              />
              <span>5 - 10 years</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={prospectObjective?.beginWithdrawal === "10-"}
              />
              <span>More than 10 years</span>
            </div>
          </Flex>
          <Flex gap={7} flex={"590px"} vertical>
            <h5>
              Which Statement best describes your knowledge of investments?
            </h5>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.investmentKnowledge ===
                  "I know virtually nothing about investing"
                }
              />
              <span>I know virtually nothing about investing.</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.investmentKnowledge ===
                  "My understanding of the markets and investing is minimal"
                }
              />
              <span>
                My understanding of the markets and investing is minimal.
              </span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.investmentKnowledge ===
                  "Somewhat knowledgeable. I have a basic comprehension of investing"
                }
              />
              <span>
                Somewhat knowledgable. I have a basic comprehension of
                investing.
              </span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.investmentKnowledge ===
                  "Highly knowledgeable. I have a good understanding of how the stock and bond markets work, and I spend considerable time keeping up with financial matters"
                }
              />
              <span>
                Highly knowledgable. I have a good understanding of how the
                stock and bond markets work, and I spend considerable time
                keeping up with financial matters.
              </span>
            </div>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap={16} vertical>
        <h4>Returns, Risk, Volatility...</h4>
        <Flex gap={40}>
          <Flex gap={7} flex={"440px"} vertical>
            <h5>
              An investment decision involves both returns and risk - the higher
              the potential for returns, the greater the risk of highly volatile
              results, including loss. What influences you the most when making
              an important investment decision?
            </h5>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.decisionInfluence ===
                  "I am mostly influenced by the potential loss"
                }
              />
              <span>I am mostly influenced by the potential loss.</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.decisionInfluence ===
                  "I am equally influenced by potential loss or gain"
                }
              />
              <span>I am equally influenced by potential loss or gain.</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.decisionInfluence ===
                  "I am mostly influenced by the potential gain"
                }
              />
              <span>I am mostly influenced by the potential gain.</span>
            </div>
          </Flex>
          <Flex gap={7} flex={"590px"} vertical>
            <h5>
              Understanding that volatility has historically been present when
              investing, what is the maximum amount of volatility you can accept
              over a 1 year period and remain invested?
            </h5>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "Any decrease in value would concern me"
                }
              />
              <span>Any decrease in value would concern me.</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "Up to 10% downside ($100,000 falling to $90,000)"
                }
              />
              <span>Up to 10% downside ($100,000 falling to $90,000).</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "Up to 20% downside ($100,000 falling to $80,000)"
                }
              />
              <span>Up to 20% downside ($100,000 falling to $80,000).</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "Up to 30% downside ($100,000 falling to $70,000)"
                }
              />
              <span>Up to 30% downside ($100,000 falling to $70,000).</span>
            </div>
            <div className={"value-container"}>
              <RadioButton
                checked={
                  prospectObjective?.volatilityTolerance ===
                  "I would not worry about performance for that short of a period"
                }
              />
              <span>
                I would not worry about performance for that short of a period.
              </span>
            </div>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap={7} style={{ width: 440 }} vertical>
        <h5>
          When the next downturn in the market comes, how long are you prepared
          to ride out the declining market?
        </h5>
        <div className={"value-container"}>
          <RadioButton
            checked={
              prospectObjective?.decliningMarketWithholdingPeriod ===
              "Less than 1 year"
            }
          />
          <span>Less than 1 year</span>
        </div>
        <div className={"value-container"}>
          <RadioButton
            checked={
              prospectObjective?.decliningMarketWithholdingPeriod ===
              "Between 1-5 years"
            }
          />
          <span>Between 1-5 years</span>
        </div>
        <div className={"value-container"}>
          <RadioButton
            checked={
              prospectObjective?.decliningMarketWithholdingPeriod ===
              "More than 5 years"
            }
          />
          <span>More than 5 years</span>
        </div>
      </Flex>

      <Flex gap={16} vertical>
        <h4>A bit about you…</h4>
        <Flex gap={40}>
          <Flex gap={140} style={{ width: 440 }}>
            <Flex gap={7} vertical>
              <span>Amount</span>
              <b>
                {getCurrencyValue(prospectObjective?.investmentAmount) || "N/A"}
              </b>
            </Flex>
            <Flex gap={7} vertical>
              <span>Time</span>
              <b>
                {prospectObjective?.investmentDuration
                  ? `${prospectObjective?.investmentDuration} Years`
                  : "N/A"}
              </b>
            </Flex>
          </Flex>
          <Flex gap={60} style={{ width: 590 }}>
            <Flex gap={7} vertical>
              <span>Annual Investment Contributions</span>
              <b>
                {getCurrencyValue(prospectObjective?.contributions) || "N/A"}
              </b>
            </Flex>
            <Flex gap={7} vertical>
              <span>Combined Household Income</span>
              <b>
                {getCurrencyValue(prospectObjective?.householdIncome) || "N/A"}
              </b>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </main>
  </StylesContainer>
);

export default ReportPageIpq1;
