import { useContext } from "react";
import State from "../../../../context";
import { Button, Flex, Tabs, theme } from "antd";
import ModalArchiveManagedUser from "../../../../molecule/ModalArchiveManagedUser";
import ModalClientDetails from "../../components/ModalClientDetails";
import ModalImportManagedUser from "../../components/ModalImportManagedUser";
import ModalLeadQuestion from "../../components/ModalLeadQuestion";
import ModalReleaseLead from "../../components/ModalReleaseLead";
import SectionMyLeads from "./components/SectionMyLeads";
import SectionNextLeads from "./components/SectionNextLeads";
import SecurityGate from "../../../../provider/SecurityGate";

import StylesContainer from "./styles";

import { DEFAULT_RETIREMENT_AGE } from "../../../../utils/constant";

import {
  addLead,
  archiveLead,
  convertLeadToClient,
  editLead,
  importCSVManagedUserLead,
  releaseLead,
} from "../../../../utils/request/manager";
import { isOrganizationAstor } from "../../../../utils/helper/specialized";

import IconPenny from "../../images/IconPenny";
import { ReactComponent as DownloadIcon } from "./images/download.svg";

const isMyLeadUser = userData => {
  if (userData?.valueMap?.leadInitial?.isConverted) {
    return false;
  } else if (
    userData?.entryPoint === "addedByUserManager" &&
    (userData?.userType === "Assigned" || userData?.userType === "Prospect")
  ) {
    return true;
  } else if (
    userData?.entryPoint === "addedByItself" &&
    userData?.userType === "Affiliated"
  ) {
    return true;
  } else {
    return false;
  }
};

const PageLeads = ({ hideHeader }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const organization = state?.orgName ?? state?.organization?.name;

  const myLeadsList =
    state.managedUsers
      ?.filter(it => isMyLeadUser(it))
      ?.map((it, index) => ({ ...it, key: index })) ?? [];

  const handleArchiveLead = () => {
    state.closeModal("archiveLeadModal");
    state.setKeyValue("loading", true);

    archiveLead({
      email: state.activeEmail,
      userManagerEmail: state._id,
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.setKeyValue("activeEmail", null);
          state.showSuccess(`Lead ${state.activeEmail} archived`);
        })
      )
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleArchiveLeadModalClose = () => {
    state.closeModal("archiveLeadModal");
    state.setKeyValue("activeEmail", null);
  };

  const handleLeadQuestionModalClose = () => {
    state.closeModal("leadQuestionModal");
  };

  const handleMoveLeadToClient = email => {
    convertLeadToClient(email)
      .then(() =>
        state
          .setAdminData()
          .then(() => state.showSuccess(`Lead ${email} converted into client.`))
      )
      .catch(console.log);
  };

  const handleReleaseLead = () => {
    state.setKeyValue("loading", true);

    releaseLead({ email: state.activeEmail })
      .then(response => {
        state.showSuccess(response?.data);
        state.closeModal("releaseLeadModal");

        state.setAdminData(() => {
          state.setKeyValue(state.activeEmail, null);
          state.setKeyValue("loading", false);
        });
      })
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleReleaseLeadModalClose = () => {
    state.closeModal("releaseLeadModal");
    state.setKeyValue("activeEmail", null);
  };

  const handleExportLeads = () => {
    window.open(
      `${process.env.REACT_APP_API_GATEWAY}/userManager/export?email=${encodeURIComponent(state._id)}&userType=Lead`
    );
  };

  const handleImportLeads = file => {
    importCSVManagedUserLead(file)
      .then(response => {
        if (typeof response?.data === "object") {
          Object.keys(response.data).map(key =>
            state.showWarning(`${key} ${response.data[key]}`)
          );
        }

        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.closeModal("importManagedUserModal");
          state.showSuccess("Leads list imported.");
        });
      })
      .catch(error => {
        state.setKeyValue("loading", false);
        state.showError(error.response?.data);
      });
  };

  const handleLeadQuestionAnswerSubmit = values => {
    const leadData = state.managedUsers.find(
      it => it.email === state.activeEmail
    );

    if (leadData) {
      state.setKeyValue("loading", true);

      editLead({
        ...leadData,
        leadInitial: {
          ...leadData.valueMap?.leadInitial,
          ...values,
        },
      })
        .then(() =>
          state.setAdminData().then(() => {
            state.showSuccess("Answer sent.");
            state.closeModal("leadQuestionModal");
            state.setKeyValue("loading", false);
          })
        )
        .catch(error => {
          state.setKeyValue("loading", false);
          state.showError(error?.response?.data);
        });
    }
  };

  const handleUpdateLeadDetails = values => {
    state.setKeyValue("loading", true);

    if (state.activeEmail) {
      const leadData = state.managedUsers.find(
        it => it.email === state.activeEmail
      );

      editLead({
        birthYear: leadData.birthYear ?? values.yearBorn,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        leadInitial: {
          ...leadData?.valueMap?.leadInitial,
          assets: values.assets,
          isMarried: values.maritalStatus === "married",
          phoneAlt: values.phoneAlt,
          planningType: values.planningType,
          spouseEmail: values.spouseEmail,
          spouseFirstName: values.spouseFirstName,
          spouseLastName: values.spouseLastName,
        },
        phoneNumber: values.phoneNumber,
      })
        .then(() =>
          state.setAdminData().then(() => {
            state.showSuccess(`Lead data updated`);
            state.setKeyValue("loading", false);
            state.setKeyValue("openModalClientDetails", false);
            state.setKeyValue("activeEmail", null);
          })
        )
        .catch(error => {
          state.setKeyValue("loading", false);
          state.showError(error?.response?.data);
        });
    } else {
      const extraPayload = {};

      if (values.planningType?.toLowerCase() === "cashflow") {
        if (values.planType === "basic") {
          extraPayload.annualIncome = values.annualIncome;
          extraPayload.savingsPerMonth = values.savingsPerMonth;
          extraPayload.totalSavings = values.totalSavings;
        } else {
          extraPayload.accounts = values.accounts;
          extraPayload.expenses = values.expenses;
          extraPayload.incomes = values.incomes;
        }
      }

      const payload = {
        assets: values.assets,
        birthYear: values.yearBorn,
        doNotNotifyManagedUser: true,
        email: values.email,
        enableOnboardingQuestions: values.enableOnboardingQuestions,
        firstName: values.firstName,
        lastName: values.lastName,
        leadInitial: {
          rate: 0,
          isNotifyAdvisor: values.isNotifyAdvisor,
        },
        maritalStatus: values.maritalStatus,
        oldEmail: values.oldEmail,
        phoneAlt: values.phoneAlt,
        phoneNumber: `${values.phoneNumber ?? ""}`,
        planType: values.planType,
        planningType: values.planningType,
        retirementAge: DEFAULT_RETIREMENT_AGE,
        scenarioName: "Scenario 1",
        spouseEmail: values.spouseEmail,
        spouseFirstName: values.spouseFirstName,
        spouseLastName: values.spouseLastName,
        startDate: values.startDate?.$d ?? new Date(),
        userManagerEmail: state._id,
        ...extraPayload,
      };

      addLead(payload)
        .then(() =>
          state.setAdminData().then(() => {
            state.showSuccess(`New Lead "${values.email}" Successfully Added`);
            state.setKeyValue("openModalClientDetails", false);
          })
        )
        .catch(error => {
          state.setKeyValue("loading", false);
          state.showError(error.response?.data);
        });
    }
  };

  const tabsItems = [
    {
      children: (
        <SectionMyLeads
          handleMoveLeadToClient={handleMoveLeadToClient}
          myLeadsList={myLeadsList}
        />
      ),
      hidden:
        state.access?.next &&
        !state.access?.affiliate &&
        !state.access?.level &&
        !state.access?.proportal,
      key: 1,
      label: `My Leads (${myLeadsList?.length ?? 0})`,
    },
    {
      children: (
        <SectionNextLeads handleMoveLeadToClient={handleMoveLeadToClient} />
      ),
      disabled: !state.access?.next,
      hidden: isOrganizationAstor(organization),
      key: 2,
      label: `Next Leads (${
        state.managedUsers?.filter(
          it =>
            it.entryPoint === "addedByOrgManager" &&
            (it.userType === "Assigned" || it.userType === "Prospect") &&
            !it.valueMap?.leadInitial?.isConverted
        )?.length ?? 0
      })`,
    },
  ].filter(it => !it.hidden);

  return (
    <SecurityGate state={state}>
      <StylesContainer>
        {!hideHeader && (
          <header>
            <h3>Leads</h3>
            <Button
              disabled={!state.access?.penny}
              icon={<IconPenny />}
              onClick={() => state.openModal("openModalAskAdvice")}
              shape={"round"}
              style={{ background: token.buttonAskPennyBackground }}
              type={"primary"}
            >
              <span>
                Ask<b>Penny</b>
              </span>
            </Button>
          </header>
        )}
        <main>
          <Tabs
            tabBarExtraContent={
              <Button
                disabled={
                  !state.managedUsers?.filter(
                    it =>
                      (it.userType === "Assigned" ||
                        it.userType === "Unassigned") &&
                      !it.valueMap?.leadInitial?.isConverted
                  )?.length
                }
                onClick={handleExportLeads}
                shape={"round"}
                style={{ color: "#1B5568" }}
                type={"text"}
              >
                <Flex align={"center"} gap={6}>
                  <DownloadIcon />
                  Export All Leads
                </Flex>
              </Button>
            }
            items={tabsItems}
          />
        </main>

        <ModalArchiveManagedUser
          instanceName={"lead"}
          loading={state.loading}
          onCancel={handleArchiveLeadModalClose}
          onConfirm={handleArchiveLead}
          open={state.archiveLeadModal}
        />
        <ModalClientDetails
          activeUserData={state.managedUsers?.find(
            it => it.email === state.activeEmail
          )}
          leadView
          loading={state.loading}
          managerAccess={state.access}
          open={state.openModalClientDetails}
          onCancel={() => state.closeModal("openModalClientDetails")}
          onFinish={handleUpdateLeadDetails}
        />
        <ModalImportManagedUser
          instanceLabel={"lead"}
          loading={state.loading}
          onCancel={() => state.closeModal("importManagedUserModal")}
          onFinish={handleImportLeads}
          open={state.importManagedUserModal}
        />
        <ModalLeadQuestion
          activeUserData={state.managedUsers?.find(
            it => it.email === state.activeEmail
          )}
          loading={state.loading}
          open={state.leadQuestionModal}
          onCancel={handleLeadQuestionModalClose}
          onSubmit={handleLeadQuestionAnswerSubmit}
        />
        <ModalReleaseLead
          loading={state.loading}
          onCancel={handleReleaseLeadModalClose}
          onConfirm={handleReleaseLead}
          open={state.releaseLeadModal}
        />
      </StylesContainer>
    </SecurityGate>
  );
};

export default PageLeads;
