import styled from "styled-components";
import { Skeleton, Spin } from "antd";

const Container = styled(Skeleton.Node)`
  &.ant-skeleton.no-custom-width.ant-skeleton-element {
    width: 100%;
  }

  &.ant-skeleton.no-custom-width.ant-skeleton-element .ant-skeleton-image {
    width: 100%;
  }
`;

const CardSkeleton = ({ children, loading, width, height = 400 }) =>
  loading ? (
    <Container
      className={!width && "no-custom-width"}
      active
      round
      style={{ width, height }}
    >
      <Spin />
    </Container>
  ) : (
    children
  );

export default CardSkeleton;
