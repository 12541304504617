import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    align-items: center;
    border-bottom: 1px solid #c9c8c8;
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;

    h3 {
      color: #1e1919;
      font-size: 20px;
      font-weight: 800;
      margin: 0;
    }
  }

  main {
    padding: 42px 78px 27px 35px;
  }

  .ant-tabs {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #1e1919;
      font-size: 16px;
    }

    .ant-tabs-nav {
      margin-bottom: 30px;

      &::before {
        bottom: 3px;
        border-bottom-color: #c9c8c8;
      }

      .ant-tabs-ink-bar {
        height: 8px;
        background: #02494e;
        border-radius: 11px;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table-thead > tr > * {
      font-weight: 500;
      background: transparent;

      &::before {
        display: none;
      }
    }

    tr > td.assets-column,
    tr > th.assets-column {
      background: #6dc1c730;
    }
  }
`;

export default StylesContainer;
