export const STATIC_CONTENT = {
  Conservative: {
    howWeGet:
      "The Peak Conservative Allocation Model seeks to drive income and some growth through a blended portfolio comprising a combination of assets.",
    whatExpect:
      "You may desire some growth or income, but your primary focus is to limit exposure to risk. You understand that your portfolio has limited upside potential, but are willing to accept smaller gains for lower volatility.",
  },
  Equity: {
    howWeGet:
      "The Peak Equity Allocation Model seeks to drive capital appreciation through a blended portfolio comprising a combination of assets.",
    whatExpect:
      "You are comfortable taking risks and experiencing potential short-term changes in your account. The main objective is to drive long-term growth. You have a longer time horizon, typically 5-7+ years. ",
  },
  Growth: {
    howWeGet:
      "The Peak Growth Allocation Model seeks to drive account growth through a blended portfolio comprising a combination of assets.",
    whatExpect:
      "Your main focus is to grow assets over time. You can tolerate market volatility and are not too worried about short-term fluctuations in your account.",
  },
  Moderate: {
    howWeGet:
      "The Peak Moderate Allocation Model seeks to drive growth and some income through a blended portfolio comprising a combination of assets.",
    whatExpect:
      "You want to grow your account in a balanced manner by having exposure to growth-focused investments as well as risk-reducing investments. You understand that you will likely not achieve market matching returns in the long run, but you also expect to experience less volatility than the general market over that same period.",
  },
  Preservation: {
    howWeGet:
      "The Peak Preservation Allocation Model seeks to preserve capital in the account and minimize exposure to risk with a blended portfolio comprising a combination of assets.",
    whatExpect:
      "You are generally concerned with limiting fluctuations of your account value. The main objective for this portfolio is to preserve the principal value. \n",
  },
};
