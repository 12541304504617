const Proposal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.923"
    height="12.763"
    viewBox="0 0 18.923 12.763"
  >
    <path
      d="M21.923,9.317,20.589,7.983l-2.7,3.037A10.184,10.184,0,0,0,10.2,7.5,10.842,10.842,0,0,0,3,10.338l1.344,1.344A8.883,8.883,0,0,1,10.2,9.392a8.316,8.316,0,0,1,6.405,3.066l-2.725,3.066L10.1,11.739,3,18.844l1.419,1.419L10.1,14.577l3.785,3.785,3.832-4.3a11.611,11.611,0,0,1,1.362,4.3h1.9a13.382,13.382,0,0,0-1.93-5.809Z"
      transform="translate(-3 -7.5)"
      fill="currentColor"
    />
  </svg>
);

export default Proposal;
