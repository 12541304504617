import styled from "styled-components";

const StylesContainer = styled.div`
  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-select .ant-select-arrow {
    color: #354a5f;
  }
`;

export default StylesContainer;
