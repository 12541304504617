const ThumbUp = ({ active }) => (
  <svg
    id="Group_9220"
    data-name="Group 9220"
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="25"
    viewBox="0 0 50 25"
  >
    <rect
      id="Rectangle_3688"
      width="50"
      height="25"
      rx="12.5"
      fill={active ? "#34579A" : "#FFFFFF"}
    />
    <g id="Icon_ionic-md-thumbs-up" transform="translate(16.707 4.056)">
      <path
        id="Path_7829"
        d="M12.921,21.389h7.565a1.614,1.614,0,0,0,1.5-1.029L24.5,14.4a1.683,1.683,0,0,0,.114-.616v-1.7a1.94,1.94,0,0,0-1.663-1.962H17.711L18.5,6.545l.026-.264a1.275,1.275,0,0,0-.365-.893L17.276,4.5l-5.542,5.6a1.693,1.693,0,0,0-.484,1.192v8.445A1.658,1.658,0,0,0,12.921,21.389Z"
        transform="translate(-6.324 -4.5)"
        fill={active ? "#FFFFFF" : "#34579A"}
      />
      <path
        id="Path_7830"
        d="M3.375,15.75H6.19V25.6H3.375Z"
        transform="translate(-3.375 -8.713)"
        fill={active ? "#FFFFFF" : "#34579A"}
      />
    </g>
  </svg>
);

export default ThumbUp;
