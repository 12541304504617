const LevelLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="88"
    height="28"
    viewBox="0 0 88 28"
  >
    <g id="Group_9105" data-name="Group 9105" transform="translate(-31 -37)">
      <text
        transform="translate(65 58)"
        fill="#fff"
        fontSize="21"
        fontFamily="Quicksand-Bold, Quicksand"
        fontWeight="700"
      >
        <tspan x="0" y="0">
          Level
        </tspan>
      </text>
      <g transform="translate(44.041 51.158)">
        <g transform="translate(-13.041 -13.842)">
          <path
            d="M13.842,27.684A13.842,13.842,0,0,1,13.842,0a14.137,14.137,0,0,1,11.4,5.927,4.425,4.425,0,0,1-.468,5.746L22.58,13.86l2.115,2.111a4.426,4.426,0,0,1,.471,5.741A14.074,14.074,0,0,1,13.842,27.684Zm0-23.87a10.028,10.028,0,0,0,0,20.056,10.251,10.251,0,0,0,8.227-4.386A.626.626,0,0,0,22,18.671l-2.115-2.111-1.422,1.43a6.505,6.505,0,0,1-4.622,2.066A6.236,6.236,0,0,1,9.3,18.083a6.161,6.161,0,0,1-1.657-4.674,6.263,6.263,0,0,1,6-5.777A6.5,6.5,0,0,1,18.466,9.7c.138.137.791.8,1.429,1.453l2.179-2.177a.635.635,0,0,0,.07-.814A10.317,10.317,0,0,0,13.842,3.814Zm-.012,7.629h-.068a2.415,2.415,0,0,0-2.313,2.225,2.383,2.383,0,0,0,.64,1.81,2.433,2.433,0,0,0,3.676-.184L17.2,13.851c-.638-.65-1.293-1.316-1.43-1.455A2.751,2.751,0,0,0,13.83,11.443Z"
            transform="translate(0 0)"
            fill="#dbe247"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LevelLogo;
