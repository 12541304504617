import AdminAdvisorsAnalyticsSection from "./components/AdminAdvisorsAnalyticsSection";
import AdvisorsPage from "./components/AdvisorsPage";

const PageAdminAdvisors = () => (
  <>
    <AdminAdvisorsAnalyticsSection />
    <AdvisorsPage />
  </>
);

export default PageAdminAdvisors;
