const DashboardIconLarge = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g transform="translate(-32 -141)">
      <rect
        id="Rectangle_2066"
        data-name="Rectangle 2066"
        width="11"
        height="15"
        rx="1"
        transform="translate(32 141)"
        fill="currentColor"
      />
      <rect
        width="12"
        height="7"
        rx="1"
        transform="translate(45 141)"
        fill="currentColor"
      />
      <rect
        width="11"
        height="8"
        rx="1"
        transform="translate(32 158)"
        fill="currentColor"
      />
      <rect
        width="12"
        height="16"
        rx="1"
        transform="translate(45 150)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default DashboardIconLarge;
