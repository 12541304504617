import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    position: relative;
    width: 1230px;
    padding: 71px 80px 20px 78px;
  }

  main {
    color: #000;
    display: flex;
    gap: 73px;
    margin-top: 66px;

    .chart-container {
      background: #f0f9f9;
      border-radius: 12px;
    }

    h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin: 0;
    }

    h4 {
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0 0 24px;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      margin: 0;
    }
  }
`;

export default StylesContainer;
