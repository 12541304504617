import styled from "styled-components";

const StylesContainer = styled.div`
  .ant-spin-fullscreen {
    backdrop-filter: blur(17px);
    background: #00000065;

    .ant-spin {
      background: #fff;
      border-radius: 12px;
      padding: 48px 32px 32px;
      width: 382px;

      .ant-spin-dot-holder {
        color: #1677ff;
      }

      .ant-spin-text {
        color: #222;
        max-width: 522px;
        margin-top: 27px;
        text-align: start;

        h3 {
          font-size: 22px;
          line-height: 140%;
          margin: unset;
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          margin: 16px 0 0;
        }
      }
    }
  }
`;

export default StylesContainer;
