import styled from "styled-components";
import { Button } from "antd";

export const Title = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  color: #17191c;
`;

export const StyledButton = styled(Button)`
  align-self: center;
  max-width: 342px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  background: #001141;
  color: #4eabe9;
`;
