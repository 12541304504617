import PageNewsList from "../templates/PortalAssetManager/pages/PageNewsList";
import PageSettingsSecurity from "../templates/PortalAssetManager/pages/PageSettingsSecurity";
import PortalAssetManager from "../templates/PortalAssetManager";

export default [
  {
    path: "/mmi/dashboard",
    Component: PortalAssetManager,
  },
  {
    path: "/mmi/news-list",
    Component: PageNewsList,
  },
  {
    path: "/mmi/settings-security",
    Component: PageSettingsSecurity,
  },
];
