import logo from "../images/logo_main.png";

export default {
  chartColors: ["#6DC1C7", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimary: "#04223A",
  colorPrimaryButtonText: "#FFFFFF",
  comparisonProductsColors: ["#6DC1C7", "#004E88", "#D8D800"],
  incomeChartWithTypesContributionsColor: "#1B5568",
  incomeChartWithTypesNavbarButtonBackground: "#FFFFFF",
  incomeChartWithTypesNavbarButtonColor: "#354A5F",
  incomeChartWithTypesNavbarContainerBackground: "#04223A",
  logoSrc: logo,
  modalFinancialProductDetailsHeaderBackground: "#E7EBEE",
  pageLoginButtonSubmitColor: "#192849",
  pageLoginButtonSubmitFontSize: 16,
  pageLoginButtonSubmitFontWeight: 700,
  productCardBackground: "linear-gradient(173deg, #FFFFFF 0%, #DEE8EF 100%)",
  productCardBorder: "1px solid #BFC8CE",
  sidebarBackground: "#04223A",
  sidebarIconColorActive: "#BFC8CE",
  sidebarMenuItemHoverBackground: "#152A4E",
  tenantColorPrimary: "#04223A",
};
