import styled from "styled-components";
import { Menu } from "antd";

const StyledMenu = styled(Menu)`
  &.ant-menu {
    position: relative;
    width: 100%;
    background: transparent;
    margin-top: 28px;

    &.ant-menu-light.ant-menu-root.ant-menu-vertical {
      border-right: unset;
    }

    .ant-menu-item {
      width: 100%;
      height: fit-content;
      line-height: 19px;
      background: transparent;
      color: ${({ token }) => token.sidebarIconColor};
      padding: 0;
      margin: 0;
      border-radius: unset;
      text-align: center;

      &:not(:first-of-type) {
        margin-top: 28px;
      }
    }
  }
`;

export default StyledMenu;
