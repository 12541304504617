import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    position: relative;
    width: 1230px;
    padding: 71px 80px 20px 78px;
  }

  main {
    color: #1e1e1e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 55px;

    header {
      display: flex;
      gap: 65px;
      justify-content: space-between;
      margin-bottom: 73px;

      h2 {
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 0;
      }
    }

    h5 {
      font-size: 18px;
      font-style: normal;
      line-height: normal;
      font-weight: 300;
      margin: 0;
    }

    p {
      margin: 0;
      max-width: 688px;
    }

    .ant-divider-horizontal {
      background: #6e7d83;
      margin: 0 0 10px;
    }

    .description-container {
      width: 315px;
    }

    .personal-reflection-container {
      background: #ecf5f5;
      border-radius: 8px;
      height: 931px;
      width: 663px;
    }

    .sortable-column div {
      box-shadow: unset;
    }
  }
`;

export default StylesContainer;
