import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-close {
      top: 26px;
      right: 32px;
    }

    .ant-modal-content {
      padding: 30px 30px 36px;
    }

    .ant-modal-title {
      font-weight: 800;
    }

    .ant-modal-header {
      margin-bottom: 25px;
    }

    .ant-modal-body {
      color: #0d1238;
      background: #edf0f2;
      padding: 49px 110px 40px;
      text-align: center;
      border-radius: 12px;

      .title {
        margin: 0 0 8px;
      }

      .description {
        margin: 0;
      }
    }

    .ant-modal-footer {
      margin-top: 26px;
    }
  }
`;

export default StyledModal;
