import StyledModal from "./styles";

import { ReactComponent as CloseIcon } from "../../../../images/blue_light_close.svg";

const ModalPrimary = ({ children, ...props }) => (
  <StyledModal closeIcon={<CloseIcon />} {...props}>
    {children}
  </StyledModal>
);

export default ModalPrimary;
