import axios from "axios";

export const getQuizLink = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_API_GATEWAY}/questionary/generate-url`,
      requestBody
    )
    .then(({ data }) => data?.url);

export const sendQuizLinkOnEmail = requestBody =>
  axios.post(
    `${process.env.REACT_APP_API_GATEWAY}/questionary/send-email`,
    requestBody
  );

export const saveQuiz = ({ jwt, requestPayload }) =>
  axios.patch(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/userPreferencesValueMap`,
    requestPayload,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
