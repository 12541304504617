import styled from "styled-components";
import { Col, Form, Modal } from "antd";

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    margin-bottom: 32px;
  }

  &.ant-modal .ant-modal-content {
    padding: 20px 55px 42px 48px;
  }

  & .ant-col-24.ant-form-item-label {
    padding: 0;
  }

  @media screen and (max-width: 568px) {
    &.ant-modal .ant-modal-content {
      padding: 32px;
    }
  }
`;

export const ValidationLabel = styled(Col)`
  color: black;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 22px 36px;
  border-radius: 12px;
  border: 1px solid #e8e9e9;
`;

export default StyledModal;
