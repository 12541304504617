import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  &.ant-modal {
    .ant-modal-body {
      min-height: 352px;
      padding: 2px;
      box-sizing: border-box;
      overflow: auto;
    }

    .ant-modal-content {
      padding: 23px 32px 40px;
    }

    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 28px;
      }

      .ant-tabs-tab-btn {
        min-width: 64px;
        text-align: center;
      }

      .ant-tabs-ink-bar {
        height: 8px;
        background: #03494e;
        border-radius: 11px;
      }
    }
  }

  .ant-input,
  .ant-picker,
  .ant-input-number {
    background: #ebf1f3;
    border-color: transparent;
  }
`;

export default StyledModal;
