const Upload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.172"
    height="18.172"
    viewBox="0 0 18.172 18.172"
  >
    <g id="Icon_feather-upload" transform="translate(-3.5 -3.5)">
      <path
        id="Path_7817"
        d="M20.672,22.5v3.594a1.8,1.8,0,0,1-1.8,1.8H6.3a1.8,1.8,0,0,1-1.8-1.8V22.5"
        transform="translate(0 -7.219)"
        fill="none"
        stroke="#354a5f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_7818"
        d="M19.484,8.992,14.992,4.5,10.5,8.992"
        transform="translate(-2.406)"
        fill="none"
        stroke="#354a5f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_7819"
        d="M18,4.5V15.281"
        transform="translate(-5.414)"
        fill="none"
        stroke="#354a5f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default Upload;
