import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  padding: 27px 24px;

  & * {
    font-family: Poppins, sans-serif;
  }
`;

export default StylesContainer;
