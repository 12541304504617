const CleverLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="109"
    height="23.508"
    viewBox="0 0 109 23.508"
  >
    <g id="Clever_logo_fullcolor_light" transform="translate(54.5 11.754)">
      <g id="Group_9179" transform="translate(-54.5 -11.754)">
        <path
          id="Path_7736"
          d="M11.752,23.508A11.752,11.752,0,0,1,3.438,3.443,12.017,12.017,0,0,1,13.842.157a3.763,3.763,0,0,1,3.17,3.733l0,2.628,2.538,0h.007A3.757,3.757,0,0,1,23.28,9.682,11.957,11.957,0,0,1,20.066,20.07,11.721,11.721,0,0,1,11.752,23.508Zm.119-20.269A8.678,8.678,0,0,0,5.729,5.734,8.518,8.518,0,0,0,17.775,17.78,8.713,8.713,0,0,0,20.082,10.2a.532.532,0,0,0-.529-.447h0l-2.539,0c0,.769,0,1.555,0,1.715a5.522,5.522,0,0,1-1.534,4.013A5.278,5.278,0,0,1,7.77,15.22,5.32,5.32,0,0,1,7.9,8.148a5.5,5.5,0,0,1,4.14-1.658c.166,0,.957.007,1.73.014l0-2.617a.535.535,0,0,0-.449-.532A9.084,9.084,0,0,0,11.872,3.239Zm.169,6.49a2.3,2.3,0,0,0-1.777.634,2.052,2.052,0,0,0-.053,2.726,2.027,2.027,0,0,0,1.471.7,1.973,1.973,0,0,0,1.512-.6,2.347,2.347,0,0,0,.585-1.723c0-.161,0-.957,0-1.73C13,9.737,12.206,9.729,12.041,9.729Z"
          transform="translate(0 0)"
          fill="#dbe247"
        />
        <g id="Group_9178" transform="translate(29.466 4.188)">
          <path
            id="Path_7737"
            d="M116.709,19.171a1.274,1.274,0,0,1-.282.772.931.931,0,0,1-.772.4,1.358,1.358,0,0,1-.64-.169,4.64,4.64,0,0,0-2-.433,4.577,4.577,0,0,0-2.3.556,3.767,3.767,0,0,0-1.506,1.563,4.9,4.9,0,0,0-.527,2.307,4.46,4.46,0,0,0,1.2,3.37,4.839,4.839,0,0,0,5.131.715,1.516,1.516,0,0,1,.6-.151,1.035,1.035,0,0,1,.828.414,1.222,1.222,0,0,1,.282.772,1.02,1.02,0,0,1-.151.546.979.979,0,0,1-.433.377,6.9,6.9,0,0,1-3.126.734,7.392,7.392,0,0,1-3.389-.781,6.011,6.011,0,0,1-2.467-2.306,7,7,0,0,1-.923-3.691,6.8,6.8,0,0,1,.876-3.446,6.214,6.214,0,0,1,2.42-2.382,7.057,7.057,0,0,1,3.483-.857,6.706,6.706,0,0,1,3.107.734A1.009,1.009,0,0,1,116.709,19.171Z"
            transform="translate(-106.24 -16.817)"
            fill="#fff"
          />
          <path
            id="Path_7738"
            d="M153.113,28.7a1.112,1.112,0,0,1-.819.329,1.058,1.058,0,0,1-.791-.329,1.128,1.128,0,0,1-.32-.819V16.248a1.126,1.126,0,0,1,1.149-1.149,1.06,1.06,0,0,1,.791.329,1.13,1.13,0,0,1,.32.819V27.884A1.113,1.113,0,0,1,153.113,28.7Z"
            transform="translate(-138.718 -15.099)"
            fill="#fff"
          />
          <path
            id="Path_7739"
            d="M175.362,34.062a1.2,1.2,0,0,1-.791.282h-6.214a2.84,2.84,0,0,0,1.036,1.742,2.9,2.9,0,0,0,1.826.631,4.054,4.054,0,0,0,1.205-.141,3.128,3.128,0,0,0,.687-.3c.169-.106.285-.179.348-.216a1.432,1.432,0,0,1,.64-.169.924.924,0,0,1,.678.282.9.9,0,0,1,.282.659,1.175,1.175,0,0,1-.527.922,4.49,4.49,0,0,1-1.412.734,5.838,5.838,0,0,1-4.547-.358,4.6,4.6,0,0,1-1.817-1.817,5.3,5.3,0,0,1-.64-2.608,5.774,5.774,0,0,1,.678-2.843,4.812,4.812,0,0,1,1.789-1.874,4.621,4.621,0,0,1,2.372-.65,4.4,4.4,0,0,1,2.344.678,5.074,5.074,0,0,1,1.76,1.827,4.91,4.91,0,0,1,.659,2.485A.968.968,0,0,1,175.362,34.062Zm-6.985-1.6h4.933V32.33a1.947,1.947,0,0,0-.772-1.356,2.443,2.443,0,0,0-1.581-.565A2.337,2.337,0,0,0,168.377,32.461Z"
            transform="translate(-149.51 -24.666)"
            fill="#fff"
          />
          <path
            id="Path_7740"
            d="M213.965,29.327a.971.971,0,0,1,.3.725,1.464,1.464,0,0,1-.151.565l-3.521,7.758a1.169,1.169,0,0,1-.386.508,1.048,1.048,0,0,1-.574.207l-.169.019a.991.991,0,0,1-.593-.2,1.3,1.3,0,0,1-.424-.537l-3.5-7.758a1.49,1.49,0,0,1-.132-.6.94.94,0,0,1,.32-.687,1.091,1.091,0,0,1,.791-.311,1.221,1.221,0,0,1,.659.188,1.162,1.162,0,0,1,.452.527l2.6,5.95,2.58-5.931a1.243,1.243,0,0,1,.414-.546.89.89,0,0,1,.584-.169A1.041,1.041,0,0,1,213.965,29.327Z"
            transform="translate(-177.474 -25.156)"
            fill="#fff"
          />
          <path
            id="Path_7741"
            d="M252.213,34.062a1.2,1.2,0,0,1-.791.282h-6.213a2.839,2.839,0,0,0,1.035,1.742,2.9,2.9,0,0,0,1.826.631,4.052,4.052,0,0,0,1.205-.141,3.136,3.136,0,0,0,.688-.3c.169-.106.285-.179.348-.216a1.434,1.434,0,0,1,.64-.169.924.924,0,0,1,.678.282.9.9,0,0,1,.283.659,1.175,1.175,0,0,1-.527.922,4.491,4.491,0,0,1-1.412.734,5.839,5.839,0,0,1-4.548-.358,4.6,4.6,0,0,1-1.817-1.817,5.3,5.3,0,0,1-.64-2.608,5.771,5.771,0,0,1,.678-2.843,4.812,4.812,0,0,1,1.789-1.874,4.622,4.622,0,0,1,2.373-.65,4.4,4.4,0,0,1,2.344.678,5.073,5.073,0,0,1,1.761,1.827,4.91,4.91,0,0,1,.659,2.485A.969.969,0,0,1,252.213,34.062Zm-6.986-1.6h4.933V32.33a1.947,1.947,0,0,0-.772-1.356,2.444,2.444,0,0,0-1.582-.565A2.337,2.337,0,0,0,245.227,32.461Z"
            transform="translate(-205.046 -24.666)"
            fill="#fff"
          />
          <path
            id="Path_7742"
            d="M290.563,28.658a.955.955,0,0,1,.386.753,1.167,1.167,0,0,1-.3.876.994.994,0,0,1-.716.292,1.874,1.874,0,0,1-.64-.132c-.038-.012-.123-.038-.254-.075a1.534,1.534,0,0,0-.424-.057,1.675,1.675,0,0,0-.941.3,2.2,2.2,0,0,0-.744.913,3.362,3.362,0,0,0-.292,1.459v4.462a1.13,1.13,0,0,1-.32.819,1.16,1.16,0,0,1-1.619,0,1.129,1.129,0,0,1-.32-.819V29.675a1.129,1.129,0,0,1,.32-.819,1.159,1.159,0,0,1,1.619,0,1.13,1.13,0,0,1,.32.819v.245a2.854,2.854,0,0,1,1.243-1.177,3.819,3.819,0,0,1,1.732-.4A1.44,1.44,0,0,1,290.563,28.658Z"
            transform="translate(-234.97 -24.666)"
            fill="#fff"
          />
          <path
            id="Path_7743"
            d="M324.365,18.718a.381.381,0,0,1-.075.245.34.34,0,0,1-.282.151.576.576,0,0,1-.226-.075,6.5,6.5,0,0,0-3.71-1.186,5.665,5.665,0,0,0-5.008,2.947,6.676,6.676,0,0,0,0,6.232,5.666,5.666,0,0,0,5.008,2.947,6.431,6.431,0,0,0,3.691-1.167.352.352,0,0,1,.226-.075.358.358,0,0,1,.3.151.353.353,0,0,1,.075.226.358.358,0,0,1-.151.3,6.9,6.9,0,0,1-1.892.9,7.26,7.26,0,0,1-2.25.376,6.384,6.384,0,0,1-3.295-.885,6.508,6.508,0,0,1-2.391-2.439,7.187,7.187,0,0,1,0-6.91,6.509,6.509,0,0,1,2.391-2.438,6.387,6.387,0,0,1,3.295-.885,7.292,7.292,0,0,1,4.124,1.281A.378.378,0,0,1,324.365,18.718Z"
            transform="translate(-256.016 -16.571)"
            fill="#fff"
          />
          <path
            id="Path_7744"
            d="M366.951,30.254a.415.415,0,0,1,.094.226.353.353,0,0,1-.113.273.4.4,0,0,1-.283.1.328.328,0,0,1-.3-.151l-4.312-5.875-4.293,5.875a.311.311,0,0,1-.282.151.339.339,0,0,1-.264-.1.318.318,0,0,1-.094-.217.5.5,0,0,1,.094-.245l4.425-6.044-4.462-6.082a.349.349,0,0,1-.075-.226.353.353,0,0,1,.113-.273.378.378,0,0,1,.264-.1.349.349,0,0,1,.32.151l4.312,5.894,4.293-5.912a.341.341,0,0,1,.283-.151.417.417,0,0,1,.273.085.268.268,0,0,1,.1.217.451.451,0,0,1-.094.263l-4.425,6.1Z"
            transform="translate(-287.512 -16.866)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CleverLogo;
