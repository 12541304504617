import styled from "styled-components";
import { Space } from "antd";

import checked from "./images/checked.svg";

export const Checkbox = styled.div`
  background: #ffffff;
  border: 3px solid #4b9cd3;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  height: 58px;
  left: -29px;
  position: absolute;
  top: calc(50% - 29px);
  width: 58px;

  &.active {
    background: url(${checked}) 50% 40%;
  }
`;

export const GoalContainer = styled(Space)`
  border: 1px solid #c9d7e5;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px 55px;
  position: relative;
`;

export const GoalDescription = styled.p`
  color: #111111;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
`;

export const GoalName = styled.span`
  color: #111111;
  font-size: 26px;
  font-weight: bold;
`;
