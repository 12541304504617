import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  max-width: 1160px;

  .header {
    .title {
      color: #216a9c;
      font-family: Roboto, sans-serif;
      font-size: 21px;
      line-height: normal;
      margin: 0;
    }

    .description {
      color: #000;
      font-size: 16px;
      line-height: 140%;
      margin: 0;
      max-width: 800px;
    }
  }

  .card-next-up {
    align-items: stretch;
    background: #275a90;
    border-radius: 12px;
    color: #fff;
    gap: 40px;
    justify-content: space-between;
    line-height: normal;
    max-width: 563px;
    padding: 32px;

    .title {
      color: #fff;
      font-size: 22px;
      margin: 0;
    }

    .description {
      margin: 0;
      max-width: 250px;
    }

    .perspective-step-marker {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 12px;
      color: #12457a;
      font-size: 12px;
      font-weight: 500;
      padding: 1px 4px;
    }

    .ant-btn {
      min-width: 175px;
    }

    .extra-copy-container {
      background: #fff;
      border-radius: 12px;
      color: #222222;
      opacity: 0.9;
      padding: 20px 24px;
    }
  }

  @media screen and (max-width: 1314px) {
    .body {
      height: 100%;
    }
  }
`;

export default StylesContainer;
