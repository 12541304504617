import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../context";
import { Flex, Image, Layout, theme } from "antd";
import MenuGoalsUser from "../components/MenuGoalsUser";
import MenuSidebar from "../../../molecule/MenuSidebar";
import MenuSidebarSub from "../components/MenuSidebarSub";
import ModalContactUs from "../../../organism/ModalContactUs";
import ModalGoalsUserSettings from "../../../organism/ModalGoalsUserSettings";
import ModalPennyTerms from "../../../organism/ModalPennyTerms";
import ModalSupport from "../components/ModalSupport";
import SectionAskAdvice from "../../../organism/SectionAskAdvice";

import StyledLayout from "./styles";

import { ReactComponent as IconSupport } from "./images/icon_support.svg";

const GoalsLayout = ({ routes, children }) => {
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);
  const isTablet = useMediaQuery({ maxWidth: token.screenMD });

  const handleSupportButtonClick = () => {
    state.setKeyValue("openModalSupport", true);
  };

  return (
    <StyledLayout token={token}>
      <Layout.Sider
        breakpoint={"md"}
        collapsedWidth={"0"}
        width={isTablet ? 112 : 80}
      >
        <Flex
          align={"center"}
          gap={isTablet ? 28 : 40}
          style={{ width: "100%" }}
          vertical
        >
          {!isTablet && (
            <Image preview={false} src={token.sidebarLogo} width={30} />
          )}
          <Flex style={{ width: "100%" }} vertical>
            <MenuSidebar
              itemsLayoutVertical
              routes={routes?.filter(it => it.Icon)}
            />
            {isTablet && <MenuSidebarSub state={state} />}
          </Flex>
        </Flex>
        <Flex align={"center"} gap={46} justify={"center"} vertical>
          <Flex
            align={"center"}
            className={"pointer"}
            gap={4}
            justify={"center"}
            onClick={handleSupportButtonClick}
            vertical
          >
            <IconSupport />
            <span style={{ color: "#9CABAB", fontWeight: 500 }}>Support</span>
          </Flex>
          <MenuGoalsUser />
        </Flex>
      </Layout.Sider>
      <Layout.Content>{children}</Layout.Content>

      <ModalContactUs />
      <ModalGoalsUserSettings />
      <ModalPennyTerms
        onCancel={() => state.setKeyValue("openModalPennyTerms", false)}
        open={state.openModalPennyTerms}
      />
      <ModalSupport
        onCancel={() => state.setKeyValue("openModalSupport", false)}
        open={state.openModalSupport}
      />
      <SectionAskAdvice setState={setState} state={state} />
    </StyledLayout>
  );
};

export default GoalsLayout;
