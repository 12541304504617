import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 35px;
  }

  .ant-modal-title {
    font-size: 20px;
    color: #546170;
  }

  .ant-modal .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-footer {
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
  }

  .action-item {
    font-size: 16px;
    color: #21409a;
    padding: 12px;
    cursor: pointer;
  }
`;

export default StyledModal;
