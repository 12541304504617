const Users = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="15.4"
    viewBox="0 0 22 15.4"
  >
    <path
      d="M3.3,8.85a2.2,2.2,0,1,0-2.2-2.2A2.2,2.2,0,0,0,3.3,8.85Zm15.4,0a2.2,2.2,0,1,0-2.2-2.2A2.2,2.2,0,0,0,18.7,8.85Zm1.1,1.1H17.6a2.194,2.194,0,0,0-1.55.639,5.028,5.028,0,0,1,2.582,3.761H20.9a1.1,1.1,0,0,0,1.1-1.1v-1.1A2.2,2.2,0,0,0,19.8,9.95Zm-8.8,0A3.85,3.85,0,1,0,7.15,6.1,3.848,3.848,0,0,0,11,9.95Zm2.64,1.1h-.285a5.315,5.315,0,0,1-4.709,0H8.36A3.961,3.961,0,0,0,4.4,15.01V16a1.65,1.65,0,0,0,1.65,1.65h9.9A1.65,1.65,0,0,0,17.6,16v-.99A3.961,3.961,0,0,0,13.64,11.05Zm-7.69-.461A2.194,2.194,0,0,0,4.4,9.95H2.2A2.2,2.2,0,0,0,0,12.15v1.1a1.1,1.1,0,0,0,1.1,1.1H3.365A5.041,5.041,0,0,1,5.95,10.589Z"
      transform="translate(0 -2.25)"
    />
  </svg>
);

export default Users;
