const OtherInvestmentIcon = ({ background = "#1b5568", color = "#FFFFFF" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g id="Group_8596" transform="translate(0 0)">
      <g id="Education_badge">
        <g id="Group_1725">
          <circle
            cx="21"
            cy="21"
            r="21"
            transform="translate(0 0)"
            fill={background}
          />
        </g>
      </g>
      <text transform="translate(21 34)" fill={color} fontSize="33">
        <tspan x="-8" y="0">
          ?
        </tspan>
      </text>
    </g>
  </svg>
);

export default OtherInvestmentIcon;
