import { useEffect, useState } from "react";
import { Checkbox, Col, Row, theme } from "antd";

const FilterCategory = ({
  defaultValues,
  onFilterChange,
  categoryKey,
  disableBasedOnParent,
  hideUnavailableOptions,
  productFiltersApplied,
  filteredProducts,
  productsList,
  categoriesList,
}) => {
  const { token } = theme.useToken();
  const [categoryOptions, setCategoryOptions] = useState([]);

  const handleChange = checkedValues => {
    if (onFilterChange) {
      onFilterChange(checkedValues);
    }
  };

  const createCategoryOptions = () =>
    (
      categoriesList ?? [
        ...new Set(productsList.map(product => product[categoryKey])),
      ]
    )
      .filter(it => !!it)
      .map(category => ({
        value: category,
        disabled:
          disableBasedOnParent &&
          filteredProducts?.length > 0 &&
          filteredProducts.filter(product => product[categoryKey] === category)
            .length === 0,
        visible: hideUnavailableOptions
          ? filteredProducts?.filter(
              product => product[categoryKey] === category
            ).length > 0
          : true,
      }));

  useEffect(() => {
    setCategoryOptions(createCategoryOptions());
  }, [filteredProducts, productFiltersApplied]);

  return (
    <Checkbox.Group
      value={defaultValues}
      onChange={handleChange}
      style={{ display: "block" }}
    >
      {categoryOptions
        ?.sort((a, b) => a.value.localeCompare(b.value))
        ?.map(
          filter =>
            filter.visible && (
              <Row key={filter.value}>
                <Col span={24}>
                  <Checkbox
                    disabled={filter.disabled}
                    style={{ margin: "8px 0" }}
                    value={filter.value}
                  >
                    <span
                      style={{
                        color: filter.disabled ? token.colorGrey1 : "#354A5F",
                        textTransform: "capitalize",
                      }}
                      dangerouslySetInnerHTML={{ __html: filter.value }}
                    />
                  </Checkbox>
                </Col>
              </Row>
            )
        )}
    </Checkbox.Group>
  );
};

export default FilterCategory;
