import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    margin-bottom: 45px;
  }

  &.ant-modal .ant-modal-content {
    padding: 32px 32px 46px;
  }

  &.ant-modal .ant-modal-footer {
    margin-top: 30px;
  }

  .title {
    line-height: 40px;
    font-size: 28px;
    font-weight: 700;
    color: #23292c;
    margin: 0 0 35px;
  }

  .description {
    line-height: 24px;
    font-size: 16px;
    color: #23292c;
  }
`;

export default StyledModal;
