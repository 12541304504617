import styled from "styled-components";
import { Row } from "antd";

const StylesContainer = styled(Row)`
  gap: 40px;

  & .blurred-content {
    filter: blur(7px);
  }

  .aside-card {
    background: #ffffff;
    border-radius: 8px;
    padding: 16px 14px;
  }
`;

export default StylesContainer;
