import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1e1919;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export default StylesContainer;
