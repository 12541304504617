export const addThousandCommaSeparator = value =>
  (value + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const cleanCurrencyValue = value => value.replace(/[^\d.]/g, "");

export const getCurrencyValue = value => {
  if (value === "" || value === undefined || value === null) {
    return "";
  } else {
    return "$" + addThousandCommaSeparator(value);
  }
};

export const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const selectValue = event => event?.target?.select();

export const setHeadAttributes = ({
  faviconUrl,
  metaDescription,
  documentTitle,
}) => {
  if (documentTitle) document.title = documentTitle;

  if (faviconUrl) document.querySelector("link[rel='icon']").href = faviconUrl;

  if (metaDescription)
    document.querySelector("meta[name='description']").content =
      metaDescription;
};
