const AskPenny = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3106 9.04492C11.8143 9.04492 11.4297 9.42949 11.4297 9.90429C11.4297 10.3791 11.8143 10.7637 12.3106 10.7637C12.7639 10.7637 13.1485 10.3791 13.1485 9.90429C13.1485 9.42949 12.7639 9.04492 12.3106 9.04492ZM6.29496 9.04492C5.79867 9.04492 5.4141 9.42949 5.4141 9.90429C5.4141 10.3791 5.79867 10.7637 6.29496 10.7637C6.74828 10.7637 7.13285 10.3791 7.13285 9.90429C7.13285 9.42949 6.74828 9.04492 6.29496 9.04492Z"
      fill="currentColor"
    />
    <path
      d="M19.2076 7.41136C18.1742 5.99339 16.7304 5.04593 15.1471 4.61839V4.62054C14.7797 4.21234 14.365 3.83636 13.901 3.50121C10.384 0.944564 5.44686 1.72445 2.87947 5.24144C0.810529 8.09886 0.898615 11.9424 3.00838 14.6731L3.02557 17.5219C3.02557 17.5907 3.03631 17.6594 3.05779 17.7239C3.17166 18.0869 3.55838 18.2868 3.91932 18.1729L6.63924 17.3157C7.35897 17.5713 8.10233 17.7174 8.84139 17.7582L8.83065 17.7668C10.7449 19.1612 13.2543 19.5801 15.5553 18.8196L18.2859 19.709C18.3547 19.7305 18.4256 19.7434 18.4986 19.7434C18.8789 19.7434 19.1861 19.4362 19.1861 19.0559V16.177C21.0789 13.6075 21.1283 10.0561 19.2076 7.41136ZM6.94002 15.7903L6.68221 15.6828L4.55526 16.3489L4.53377 14.1145L4.3619 13.9211C2.54432 11.7039 2.42401 8.50921 4.12557 6.16527C6.19666 3.32503 10.167 2.69769 12.9986 4.7473C15.8388 6.81195 16.4683 10.7758 14.4166 13.5989C12.6957 15.96 9.61483 16.8323 6.94002 15.7903ZM17.7896 15.425L17.6178 15.6399L17.6392 17.8743L15.5338 17.1653L15.276 17.2727C14.0728 17.7196 12.7902 17.7561 11.6021 17.4231L11.5978 17.4209C13.1855 16.9332 14.625 15.945 15.6627 14.5227C17.3041 12.2604 17.5705 9.418 16.6166 6.99457L16.6295 7.00316C17.1236 7.35765 17.5769 7.80023 17.9615 8.33519C19.5213 10.475 19.4332 13.3883 17.7896 15.425Z"
      fill="currentColor"
    />
    <path
      d="M9.30277 9.04492C8.80649 9.04492 8.42192 9.42949 8.42192 9.90429C8.42192 10.3791 8.80649 10.7637 9.30277 10.7637C9.75609 10.7637 10.1407 10.3791 10.1407 9.90429C10.1407 9.42949 9.75609 9.04492 9.30277 9.04492Z"
      fill="currentColor"
    />
  </svg>
);

export default AskPenny;
