import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  margin-top: 60px;

  .ant-form {
    width: 222px;
    text-align: center;
  }

  .ant-form-item .ant-form-item-label > label {
    color: #0b2a46;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;

    &::after {
      display: none;
    }
  }

  .ant-input-number {
    border: 1px solid #4b4747;
    border-radius: 32px;
    width: 173px;

    input.ant-input-number-input {
      text-align: center;
    }
  }

  .card-related-financial-product {
    background: #f3f8f9;
    border-bottom: 1px solid #0b2a46;
    border-left: 12px solid #0b2a46;
    border-radius: 12px;
    border-right: 1px solid #0b2a46;
    border-top: 1px solid #0b2a46;
    box-sizing: border-box;
    color: #1e1919;
    font-family: Inter, sans-serif;
    left: 170px;
    padding: 23px;
    position: absolute;
    top: -75px;
    width: 283px;

    .label {
      font-size: 14px;
      line-height: 20px;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      margin: 0 0 11px;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
      text-transform: uppercase;
    }
  }
`;

export default StylesContainer;
