import styled from "styled-components";
import { Button, Flex, Select } from "antd";
import background from "./images/background.png";

export const Container = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${background}) #253048 no-repeat;
  background-size: cover;
  z-index: 999;
`;

export const StyledModal = styled.div`
  max-width: 600px;
  width: 100%;
  background: #fff;
  padding: 50px 60px 54px 71px;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 1;
  margin-top: 17vh;
`;

export const StyledButton = styled(Button)`
  background: #e5f2ff;
  border-color: #4897d1;
  color: #4897d1;
`;

export const StyledSelect = styled(Select)`
  height: 40px;
  width: 241px;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 21px;
    border-radius: 20px;
    border-color: #4897d1;
  }

  &.ant-select .ant-select-selection-placeholder {
    font-size: 16px;
    color: #bec8ce;
  }

  &.ant-select .ant-select-arrow .anticon {
    color: #4897d1;
  }

  & .ant-select-clear {
    top: 18px;
    right: 35px;
    font-size: 16px;
    color: #4897d1;
  }
`;
