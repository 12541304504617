import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AdvisorsTableContainer from "./components/AdvisorsTableContainer";
import AdvisorTableContainer from "./components/AdvisorTableContainer";

const AdvisorsPage = () => {
  const location = useLocation();
  const [activeAdvisorEmail, setActiveAdvisorEmail] = useState();

  useEffect(() => {
    setActiveAdvisorEmail(null);
  }, [location]);

  return activeAdvisorEmail ? (
    <AdvisorTableContainer
      advisorEmail={activeAdvisorEmail}
      setActiveAdvisorEmail={setActiveAdvisorEmail}
    />
  ) : (
    <AdvisorsTableContainer setActiveAdvisorEmail={setActiveAdvisorEmail} />
  );
};

export default AdvisorsPage;
