import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .header {
    h2 {
      color: #000;
      font-size: 26px;
      font-style: normal;
      line-height: normal;
      margin: 0;
    }

    p {
      color: #1e1e1e;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 0;
      max-width: 632px;
    }
  }

  .body h3 {
    border-bottom: 1px solid #a4a4a4;
    color: #071a4d;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin: 0;
    padding: 10px 10px 4px;
    text-align: center;
  }

  @media screen and (max-width: 991px) {
    .demo-image {
      display: none;
    }

    .header h2 {
      font-size: 22px;
      font-weight: 300;
    }

    .body h3 {
      font-size: 18px;
    }

    .checkbox-container {
      scale: 1.5;
    }
  }
`;

export default StylesContainer;
