import { useContext, useMemo, useState } from "react";
import State from "../../../../../../context";
import { Button, Flex } from "antd";
import TabForUsesMoney from "./components/TabFourUsesMoney";
import TabPersonalityDetails from "./components/TabPersonalityDetails";

import StylesContainer from "./styles";

import { LIST_PERSPECTIVES_PROFILE_POINTS } from "../ModalPerspectives/constant";

import { isAdminView } from "../../../../../../utils/helper/specialized";

import { ReactComponent as ArrowDown } from "./images/arrow_down.svg";

const SectionPerspectiveIndividualResults = () => {
  const [state] = useContext(State);
  const [activePersonality, setActivePersonality] = useState(
    state.getPreferenceValue(state.viewPerspectiveIndividualResults)
      ?.selectedPersonality ?? "Overview"
  );
  const [showOtherMatches, setShowOtherMatches] = useState(false);

  const handleTabChange = activeTab => {
    setActivePersonality(activeTab);
  };

  const handlePersonalitySelect = () => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue(state.viewPerspectiveIndividualResults, {
        ...state.getPreferenceValue(state.viewPerspectiveIndividualResults),
        calculation,
        selectedPersonality: activePersonality,
      })
      .then(() =>
        state.showSuccess(`Perspectives Profile '${activePersonality}' saved`)
      )
      .catch(error => console.log(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const getCalculation = () => {
    const perspectiveState = state.getPreferenceValue(
      state.viewPerspectiveIndividualResults
    );

    if (!perspectiveState) {
      return {};
    }

    const allAnswers = [
      ...perspectiveState.describeMe.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_PROFILE_POINTS[index],
      })),
      ...perspectiveState.lessMoney.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_PROFILE_POINTS[index],
      })),
      ...perspectiveState.moneyPrimarily.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_PROFILE_POINTS[index],
      })),
      ...perspectiveState.moreMoney.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_PROFILE_POINTS[index],
      })),
      ...perspectiveState.unexpectedlyReceived.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_PROFILE_POINTS[index],
      })),
    ];

    const getTotalByType = type =>
      allAnswers.reduce(
        (acc, it) => (it.type === type ? acc + it.score : acc),
        0
      );

    const givingPoints = getTotalByType("Giving");
    const investingPoints = getTotalByType("Investing");
    const savingPoints = getTotalByType("Saving");
    const spendingPoints = getTotalByType("Spending");

    const totalPoints = 45;

    const givingWeight = givingPoints / totalPoints;
    const investingWeight = investingPoints / totalPoints;
    const savingWeight = savingPoints / totalPoints;
    const spendingWeight = spendingPoints / totalPoints;

    const getPersonalityWeight = accumulatedWeight =>
      Math.round((accumulatedWeight / (40 / totalPoints)) * 100);

    const enterprisingPersonalityWeight = getPersonalityWeight(
      spendingWeight + investingWeight
    );
    const presentPersonalityWeight = getPersonalityWeight(
      spendingWeight + savingWeight
    );
    const abundantPersonalityWeight = getPersonalityWeight(
      spendingWeight + givingWeight
    );

    const contentPersonalityWeight = getPersonalityWeight(
      savingWeight + givingWeight
    );
    const preparedPersonalityWeight = getPersonalityWeight(
      savingWeight + investingWeight
    );
    const futuristicPersonalityWeight = getPersonalityWeight(
      givingWeight + investingWeight
    );

    return {
      givingPoints: givingPoints,
      investingPoints: investingPoints,
      savingPoints: savingPoints,
      spendingPoints: spendingPoints,
      personalityList: [
        {
          type: "Present",
          value: presentPersonalityWeight,
        },
        {
          type: "Abundant",
          value: abundantPersonalityWeight,
        },
        {
          type: "Enterprising",
          value: enterprisingPersonalityWeight,
        },
        {
          type: "Content",
          value: contentPersonalityWeight,
        },
        {
          type: "Prepared",
          value: preparedPersonalityWeight,
        },
        {
          type: "Futuristic",
          value: futuristicPersonalityWeight,
        },
        {
          type: "Responsive",
          value: 70,
        },
      ].sort((a, b) => b.value - a.value),
    };
  };

  const calculation = useMemo(() => {
    if (state.viewPerspectiveIndividualResults) {
      return getCalculation();
    }
  }, [state.viewPerspectiveIndividualResults]);

  return (
    <StylesContainer gap={16} vertical>
      <Flex align={"end"} className={"header"} justify={"space-between"}>
        <Flex vertical>
          <span className={"label"}>Perspectives Profile</span>
          <h3 className={"title"} style={{ textTransform: "capitalize" }}>
            {state.viewPerspectiveIndividualResults === "perspectiveSpouse"
              ? `${
                  state.getPreferenceValue("guideInitialData")
                    ?.spouseFirstName ?? ""
                } ${
                  state.getPreferenceValue("guideInitialData")
                    ?.spouseLastName ?? ""
                }`
              : `${state.personalInfo?.firstName} ${state.personalInfo?.lastName}`}
          </h3>
        </Flex>
        <Button
          disabled={
            activePersonality === "Overview" || state.loading || isAdminView()
          }
          onClick={handlePersonalitySelect}
          shape={"round"}
          type={"primary"}
        >
          {state.getPreferenceValue(state.viewPerspectiveIndividualResults)
            ?.selectedPersonality
            ? "Update your selection"
            : "Make this your selection"}
        </Button>
      </Flex>
      <Flex gap={16}>
        <Flex className={"tab-container"} gap={8} vertical>
          <div
            className={`tab ${
              activePersonality === "Overview" ? "active" : ""
            }`}
            onClick={() => handleTabChange("Overview")}
            style={{ fontSize: 19 }}
          >
            Overview
          </div>
          {calculation?.personalityList?.slice(0, 3)?.map((it, index) => (
            <div
              className={`
                tab
                ${it.type === activePersonality ? "active" : ""}
                ${
                  it.type ===
                  state.getPreferenceValue(
                    state.viewPerspectiveIndividualResults
                  )?.selectedPersonality
                    ? "selected"
                    : ""
                }
              `}
              onClick={() => handleTabChange(it.type)}
              key={it.type}
            >
              <h6 className={"title"}>{it.type}</h6>
              <p className={"description"}>
                {it.value}% Match {index === 0 && " - Best"}
              </p>
            </div>
          ))}
          <div
            className={`tab`}
            onClick={() => setShowOtherMatches(lastState => !lastState)}
          >
            <Flex align={"center"} justify={"space-between"}>
              <div>Other Matches</div>
              <ArrowDown
                style={{
                  transform: showOtherMatches ? "rotate(180deg)" : "unset",
                }}
              />
            </Flex>
          </div>
          {showOtherMatches &&
            calculation?.personalityList?.slice(3, 7)?.map(it => (
              <div
                className={`
                  tab
                  ${it.type === activePersonality ? "active" : ""}
                  ${
                    it.type ===
                    state.getPreferenceValue(
                      state.viewPerspectiveIndividualResults
                    )?.selectedPersonality
                      ? "selected"
                      : ""
                  }
                `}
                onClick={() => handleTabChange(it.type)}
                key={it.type}
              >
                <h6 className={"title"}>{it.type}</h6>
                <p className={"description"}>{it.value}% Match</p>
              </div>
            ))}
        </Flex>

        {activePersonality === "Overview" ? (
          <TabForUsesMoney />
        ) : (
          <TabPersonalityDetails
            activePersonality={activePersonality}
            calculation={calculation}
          />
        )}
      </Flex>
    </StylesContainer>
  );
};

export default SectionPerspectiveIndividualResults;
