import PageProposalGoals from "../../proposal/page/PageProposalGoals";

import Goals from "../../../icon/Goals";

export default [
  {
    path: "/rivershares-proposal",
    Component: PageProposalGoals,
    Icon: Goals,
    text: "Goals",
  },
];
