import styled from "styled-components";
import { Button, Modal } from "antd";

export const StyledButton = styled(Button)`
  height: 29px;
  border-color: transparent;
  padding: 0;
`;

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    background: linear-gradient(180deg, #001141 0%, #081c4f 100%);
    border-radius: 12px;
  }

  &.ant-modal .ant-modal-header {
    background: transparent;
  }

  .ant-modal-title {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 48px;
  }

  &.ant-modal .ant-modal-footer {
    margin-top: 60px;
  }

  .label {
    line-height: 16px;
    font-size: 12px;
    color: #a1aed0;
  }

  .value {
    line-height: 16px;
    font-size: 16px;
    color: #ffffff;
  }
`;

export default StyledModal;
