import styled from "styled-components";
import { Row } from "antd";

const StylesContainer = styled(Row)`
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 3px 12px #0000002f;
  cursor: pointer;
  height: 86px;
  position: fixed;
  right: 18px;
  top: 0;
  width: 147px;
  z-index: 999;
`;

export default StylesContainer;
