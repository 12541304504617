const RetirementIncomeIcon = ({
  background = "#1b5568",
  color = "#FFFFFF",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g id="Group_8595" transform="translate(0 0.198)">
      <circle
        cx="21"
        cy="21"
        r="21"
        transform="translate(0 -0.198)"
        fill={background}
      />
      <path
        d="M-2.092,2.3h.486a.577.577,0,0,0,.621-.54l.351-2C2.66-.54,5.359-2.4,5.927-5.454c.324-2.106-.756-4-3.78-5.049l-.918-.351.837-4.7A2.035,2.035,0,0,1,3.793-13.9a.5.5,0,0,0,.7.4L6.3-14.013c.4-.135.648-.4.54-.783a4.423,4.423,0,0,0-4.266-3.7l.4-2.187c.054-.351-.108-.54-.459-.54H2.012a.577.577,0,0,0-.621.54l-.378,2.16c-3.051.243-5.805,2.16-6.318,5.13-.378,2.241,1.08,4.1,3.645,4.968l.837.324-.864,5a2.814,2.814,0,0,1-2.7-2.052.525.525,0,0,0-.7-.432l-1.863.459a.643.643,0,0,0-.594.729c.324,2.349,2.214,4,5.346,4.185l-.351,1.971C-2.606,2.106-2.444,2.3-2.092,2.3ZM-.229-11.421-.364-11.5c-1.539-.621-1.566-1.431-1.458-2.079A2.685,2.685,0,0,1,.5-15.606Zm.108,8.262L.661-7.533l.108.054c1.188.486,1.836,1.107,1.674,2.16A2.85,2.85,0,0,1-.122-3.159Z"
        transform="translate(21.555 30.222)"
        fill={color}
      />
    </g>
  </svg>
);

export default RetirementIncomeIcon;
