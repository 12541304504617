export const checkboxList = [
  "Flourishing families",
  "Economic advancement",
  "Sanctity of life",
  "Environmental stewardship",
  "Quality healthcare",
  "Equal opportunity",
  "Education access",
  "Affordable housing",
  "Dignifying vocations",
  "Thriving communities",
  "Fair labor",
  "Viewpoint diversity",
];
