const GetAdvice = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.511"
    height="20"
    viewBox="0 0 22.511 20"
  >
    <path
      d="M23.832,22.752a2.282,2.282,0,0,0,1.251,2.036V25.1a4.652,4.652,0,0,1-4.01-1.408,6.283,6.283,0,0,1-1.618.21c-3.108,0-5.628-2.24-5.628-5s2.52-5,5.628-5,5.628,2.24,5.628,5a4.614,4.614,0,0,1-1.043,2.9,2.27,2.27,0,0,0-.208.952ZM12.576,5.143c5.441,0,9.868,3.529,10,7.924a7.668,7.668,0,0,0-3.123-.655,7.447,7.447,0,0,0-4.966,1.84A6.173,6.173,0,0,0,12.342,18.9a6,6,0,0,0,.542,2.5c-.1,0-.2,0-.307,0a12.356,12.356,0,0,1-1.56-.1C8.867,23.451,6.3,23.836,3.822,23.893v-.526a3.98,3.98,0,0,0,2.5-3.217,3.774,3.774,0,0,0-.042-.561,7.653,7.653,0,0,1-3.71-6.318C2.571,8.782,7.051,5.143,12.576,5.143Z"
      transform="translate(-2.571 -5.143)"
      fill="#fff"
      opacity="0.75"
    />
  </svg>
);

export default GetAdvice;
