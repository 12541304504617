import styled from "styled-components";
import { Input } from "antd";

export const FilterHeading = styled.span`
  color: #a0a0a0;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
  display: block;
`;

export const Search = styled(Input)`
  border-radius: 20px;
  border-color: transparent;
  background: #f7f7f7;

  input {
    background: #f7f7f7;
  }
`;
