import styled from "styled-components";
import { Collapse, Typography } from "antd";

export const ActionTitle = styled(Typography.Text)`
  font-weight: 500;
  font-size: 18px;
  display: block;
  line-height: 1;
  color: #354a5f;
`;

export const ActionSubtitle = styled(Typography.Text)`
  font-size: 14px;
  color: #8396a6;
  line-height: 1;
`;

export const StyledCollapse = styled(Collapse)`
  margin-bottom: 24px;

  &.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 24px 26px 20px;
  }

  &.ant-collapse-borderless
    > .ant-collapse-item:last-child
    .ant-collapse-header {
    background: #4897d126;
    padding: 16px 26px 8px;
    border-radius: 12px;
  }

  &.ant-collapse-borderless
    > .ant-collapse-item.ant-collapse-item-active:last-child
    .ant-collapse-header {
    border-radius: 12px 12px 0 0;
  }

  &.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-header-text {
    font-size: 18px;
    font-weight: 500;
    color: #1c4e7a;
  }

  &.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
    margin-top: 2px;
    background: #4897d126;
    border-radius: 0 0 12px 12px;
  }

  & .ant-collapse-expand-icon {
    position: absolute;
    left: 150px;
  }
`;
