import styled from "styled-components";
import { Checkbox } from "antd";

import checkbox_icon from "./images/red_small_x.svg";

export const StyledCheckBox = styled(Checkbox)`
  font-size: 16px;

  .ant-checkbox-inner {
    transform: scale(1.5);
  }

  &.ant-checkbox-checked .ant-checkbox-inner,
  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
    border-color: #5f6060;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    left: 2px;
    top: 2px;
    background: url(${checkbox_icon});
    background-size: contain;
    height: 10px;
    width: 10px;
    border: transparent;
    transform: unset;
  }
`;
