import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, InputNumber, Select, Table } from "antd";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helper/general";

import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const typeOptions = [
  { value: "Basic Expenses", label: "Basic Expenses" },
  { value: "Vehicle Payment", label: "Vehicle Payment" },
  { value: "Rent", label: "Rent" },
  { value: "Mortgage", label: "Mortgage" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "Daycare", label: "Daycare" },
  { value: "Vacation", label: "Vacation" },
  { value: "College Payment", label: "College Payment" },
  { value: "Loan Payment", label: "Loan Payment" },
  { value: "Insurance Premium", label: "Insurance Premium" },
  { value: "Property Taxes", label: "Property Taxes" },
  { value: "Other Expense", label: "Other Expense" },
  { value: "Expense Group", label: "Expense Group" },
];

const frequencyOptions = [
  { value: "Monthly", label: "Monthly" },
  { value: "Yearly", label: "Yearly" },
];

const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form id={"editableExpenseRow"} form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  typeOptions,
  frequencyOptions,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      dataIndex === "expenseType" || dataIndex === "frequency" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Select ref={inputRef} onBlur={save}>
            {(dataIndex === "expenseType" ? typeOptions : frequencyOptions).map(
              option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <InputNumber
            ref={inputRef}
            formatter={dataIndex === "startingBalance" && getCurrencyValue}
            parser={dataIndex === "startingBalance" && cleanCurrencyValue}
            onClick={selectValue}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      )
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const TableExpense = ({ newUserData, handleUserDataChange }) => {
  const [count, setCount] = useState(2);

  const handleDelete = key => {
    const newData = newUserData.expenses.filter(item => item.key !== key);
    handleUserDataChange("expenses", newData);
  };

  const defaultColumns = [
    {
      title: "Expense Type",
      dataIndex: "expenseType",
      width: "30%",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      editable: true,
    },
    {
      title: "Start Age",
      dataIndex: "startAge",
      editable: true,
    },
    {
      title: "End Age",
      dataIndex: "endAge",
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) =>
        newUserData.expenses.length >= 1 ? (
          <CloseOutlined onClick={() => handleDelete(record.key)} />
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      expenseType: "Other Expense",
      amount: 0,
      frequency: "Monthly",
      startAge: 0,
      endAge: 0,
    };
    handleUserDataChange("expenses", [...newUserData.expenses, newData]);
    setCount(count + 1);
  };

  const handleSave = row => {
    const newData = [...newUserData.expenses];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    handleUserDataChange("expenses", newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        typeOptions,
        frequencyOptions,
      }),
    };
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        dataSource={newUserData.expenses}
        columns={columns}
        pagination={false}
        size="small"
      />
      <Button type="text" size="small" shape="round" onClick={handleAdd}>
        + Add Expense
      </Button>
    </div>
  );
};

export default TableExpense;
