import axios from "axios";

export const createChannel = ({ entityId, userId, token }) =>
  axios.post(
    `${process.env.REACT_APP_FTS_API_DOMAIN}/channel`,
    {
      channel: {
        name: "Api Channel",
        description: "An Api Created by a Channel",
        owner: userId,
        stages: [
          {
            not: false,
            entities: [
              {
                type: "entity",
                value: entityId,
                options: { relevance: "high" },
              },
            ],
          },
        ],
      },
    },
    {
      headers: {
        "x-api-key": process.env.REACT_APP_FTS_API_KEY,
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteChannel = ({ channelId, token }) =>
  axios.delete(
    `${process.env.REACT_APP_FTS_API_DOMAIN}/channel/id/${channelId}`,
    {
      headers: {
        "x-api-key": process.env.REACT_APP_FTS_API_KEY,
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getArticlesByChannelId = ({
  channelId,
  quantity,
  token,
  userId,
}) =>
  getChanel({ channelId: channelId, token }).then(chanelData =>
    getRecentArticlesForStages({
      requestBody: {
        stages: chanelData.stages,
        count: quantity,
        offset: 0,
        sorting: "time",
        shouldCluster: true,
      },
      token,
      userId,
    })
  );

export const getArticlesBySearchParams = ({
  quantity = 3,
  searchParams,
  token,
  userId,
}) =>
  searchEntity({ searchParams, token }).then(entities =>
    createChannel({
      entityId: entities[0].id,
      userId,
      token,
    }).then(({ data }) =>
      getRecentArticlesForStages({
        requestBody: {
          stages: data.channels[0].stages,
          count: quantity,
          offset: 0,
          sorting: "time",
          shouldCluster: true,
        },
        token,
        userId,
      }).then(articles => {
        deleteChannel({
          channelId: data.channels[0].id,
          token,
        });
        return articles;
      })
    )
  );

export const getChanel = ({ channelId, token }) =>
  axios
    .get(`${process.env.REACT_APP_FTS_API_DOMAIN}/channel/id/${channelId}`, {
      headers: {
        "x-api-key": process.env.REACT_APP_FTS_API_KEY,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => data.channels[0]);

export const getFtsEntity = ({ ids, token }) =>
  axios
    .get(
      `${process.env.REACT_APP_FTS_API_DOMAIN}/entity/ids/${encodeURIComponent(
        ids
      )}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_FTS_API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data }) => data.entities);

export const getRecentArticlesForStages = ({ requestBody, token, userId }) =>
  axios
    .post(
      `${process.env.REACT_APP_FTS_API_DOMAIN}/news/user/${encodeURIComponent(
        userId
      )}/query`,
      requestBody,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_FTS_API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data }) => data.articles);

export const loginFts = () =>
  axios
    .post(
      `${process.env.REACT_APP_FTS_API_DOMAIN}/auth/login`,
      {
        email: process.env.REACT_APP_FTS_EMAIL,
        password: process.env.REACT_APP_FTS_PASS,
      },
      {
        withCredentials: true,
        headers: {
          "x-api-key": process.env.REACT_APP_FTS_API_KEY,
        },
      }
    )
    .then(({ data }) => data);

export const searchEntity = ({ searchParams, token }) =>
  axios
    .get(
      `${
        process.env.REACT_APP_FTS_API_DOMAIN
      }/entity/search/${encodeURIComponent(searchParams)}?include=company`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_FTS_API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data }) => data.entities);
