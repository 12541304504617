import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Flex, Input, Row, theme } from "antd";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalArchiveManagedUser from "../../../../../../molecule/ModalArchiveManagedUser";
import ModalConvertClient from "../ModalConvertClient";

import StyledTable from "./styles";

import { archiveLead } from "../../../../../../utils/request/manager";
import {
  getManagedUsers,
  handleCreateProposal,
  isAdminView,
  isOrganizationAstor,
} from "../../../../../../utils/helper/specialized";
import { getCurrencyValue } from "../../../../../../utils/helper/general";

const getColumns = ({
  handleArchiveClientActionClick,
  handleConvert,
  handleClientNameClick,
  state,
  organization,
  searchedText,
  token,
}) =>
  [
    {
      dataIndex: "clientName",
      filteredValue: [searchedText],
      key: "clientName",
      onFilter: (value, record) =>
        String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (name, restValues) => (
        <a onClick={() => handleClientNameClick(restValues.email)}>
          <b>{name}</b>
        </a>
      ),
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      title: "Client Name",
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={"mailto:" + value}>{value}</a>,
      sorter: (a, b) => a.email.localeCompare(b.email),
      title: "Email",
    },
    {
      hidden: isOrganizationAstor(organization),
      key: "clientType",
      title: "Client Type",
    },
    {
      key: "planningType",
      render: (_, allValues) => (
        <span style={{ textTransform: "capitalize" }}>
          {allValues.valueMap?.leadInitial?.planningType ||
            allValues.planningType ||
            allValues.valueMap?.prospectObjective?.planningType ||
            "Proposal"}
        </span>
      ),
      title: "Planning Type",
    },
    {
      align: "right",
      className: "assets-column",
      dataIndex: "assets",
      key: "assets",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.assets - b.assets,
      title: "Assets",
    },
    {
      align: "right",
      dataIndex: "updateDateTime",
      key: "updateDateTime",
      render: (value, restValues) =>
        restValues.proposalSaved ? (
          dayjs(value).format("MM/DD/YYYY")
        ) : (
          <Flex justify={"end"}>
            <Button
              shape="round"
              size="small"
              style={{
                borderColor: token.colorSecondaryButton,
                color: token.colorSecondaryButton,
              }}
              onClick={() => handleCreateProposal(restValues.email, state)}
              disabled={isAdminView()}
            >
              Create Proposal
            </Button>
          </Flex>
        ),
      sorter: (a, b) => dayjs(a.updateDateTime) - dayjs(b.updateDateTime),
      title: "Proposal Generated",
    },
    {
      align: "right",
      dataIndex: "lastAdvisorLoginTime",
      key: "lastAdvisorLoginTime",
      render: it => it?.$date && dayjs(it.$date).format("MM/DD/YYYY"),
      title: "Last Log-in",
    },
    {
      align: "right",
      dataIndex: "lastAdvisorUpdateTime",
      key: "lastAdvisorUpdateTime",
      render: it => it?.$date && dayjs(it.$date).format("MM/DD/YYYY"),
      title: "Last Update",
    },
    {
      className: "actions-menu-table-cell",
      key: "actionsMenu",
      render: (_, restValues) => (
        <MenuActions
          items={getItems({
            email: restValues.email,
            handleArchiveClientActionClick,
            handleConvert,
          })}
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

const getItems = ({ email, handleConvert, handleArchiveClientActionClick }) => [
  {
    key: "move",
    label: "Move to Lead",
    onClick: () => handleConvert(email),
  },
  {
    type: "divider",
  },
  {
    key: "archive",
    label: <span style={{ color: "red" }}>Archive</span>,
    onClick: () => handleArchiveClientActionClick(email),
  },
];

const TableLevelClients = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  useState(false);
  const [isConvertModalOpened, setIsConvertModalOpened] = useState(false);

  const organization = state.organization?.name ?? state.orgName;

  const handleConvert = email => {
    state.setKeyValue("activeEmail", email);
    setIsConvertModalOpened(true);
  };

  const handleArchiveClient = () => {
    state.closeModal("archiveManagedUserModal");
    state.setKeyValue("loading", true);

    archiveLead({
      email: state.activeEmail,
      userManagerEmail: state._id,
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.setKeyValue("activeEmail", null);
          state.showSuccess(`Client ${state.activeEmail} archived`);
        })
      )
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleArchiveClientActionClick = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("archiveManagedUserModal");
  };

  const handleClientNameClick = clientEmail => {
    state.setKeyValue("activeEmail", clientEmail);
    state.setKeyValue("openModalClientDetails", true);
  };

  const getTableData = () =>
    getManagedUsers(state)?.map(it => ({
      ...it,
      assets: it.valueMap?.productMap
        ? Object.keys(it.valueMap.productMap)
            .map(
              key =>
                it.valueMap.productMap[key][
                  it.valueMap.productMap[key].length - 1
                ].investmentAmount
            )
            .reduce((acc, value) => acc + value, 0)
        : (it.proposedAmount ?? 0),
      clientName: it.lastName + ", " + it.firstName,
      key: it.email,
      updateDateTime: it.updateDateTime?.$date,
      proposalSaved: it.proposalSavedDate ?? it.valueMap?.productMap,
    }));

  return (
    <>
      <Row>
        <Input
          style={{
            maxWidth: 290,
            height: 37,
            marginBottom: 16,
            borderRadius: 25,
          }}
          placeholder="Search Clients..."
          onChange={event => setSearchedText(event.target.value)}
        />
      </Row>
      <StyledTable
        columns={getColumns({
          state,
          handleConvert,
          handleArchiveClientActionClick,
          handleClientNameClick,
          organization,
          searchedText,
          token,
        })}
        dataSource={getTableData()}
        loading={state.loading}
        pagination={false}
        rowHoverable={false}
      />

      <ModalArchiveManagedUser
        loading={state.loading}
        onCancel={() => state.closeModal("archiveManagedUserModal")}
        onConfirm={handleArchiveClient}
        open={state.archiveManagedUserModal}
      />
      <ModalConvertClient
        email={state.activeEmail}
        handleClose={() => setIsConvertModalOpened(false)}
        open={isConvertModalOpened}
      />
    </>
  );
};

export default TableLevelClients;
