const Folder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="73"
    height="59.999"
    viewBox="0 0 73 59.999"
  >
    <path
      d="M5,0H64a5,5,0,0,1,5,5V53a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
      transform="translate(4 1.999)"
      fill="#56a1a7"
    />
    <path
      d="M65,57H5a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,5,0H23.73V2.6a5.006,5.006,0,0,0,5,5H69.746c.084,0,.169,0,.254-.006V52A5.006,5.006,0,0,1,65,57Z"
      transform="translate(0 -0.001)"
      fill="#6dc1c7"
    />
  </svg>
);

export default Folder;
