import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  padding: 0 29px;

  & .ant-input,
  & .ant-input-affix-wrapper {
    background: #ebf1f3;
    border-color: transparent;
  }

  .ant-input-suffix {
    cursor: pointer;
  }
`;

export default StylesContainer;
