import styled from "styled-components";

export const DownloadsContainer = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const DownloadLink = styled.a`
  margin-left: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:first-child {
    margin-left: 0;
  }
`;

export const SectionTitle = styled.p`
  font-size: 18px;
  margin: 0 0 9px;
`;
