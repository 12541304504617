import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      padding: 0;
    }

    .ant-modal-header {
      border-bottom: 1px solid #dbdbdb;
      padding: 21px 33px 10px;
    }

    .ant-modal-body {
      max-height: 70vh;
      overflow: auto;
      padding: 0 20px;
    }

    .ant-modal-footer {
      border-top: 1px solid #dbdbdb;
      padding: 20px 28px;
    }
  }

  .ant-btn.ant-btn-custom {
    background: #d9f3f7;
    color: #354a5f;
  }

  .page-container {
    border-radius: 12px;
    box-shadow: 0 3px 6px #00000029;
  }
`;

export default StyledModal;
