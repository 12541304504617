import { Row, Skeleton, Space, Spin } from "antd";

const SkeletonGoal = ({ loading, children }) =>
  loading ? (
    <Row gutter={[20, 20]}>
      <Skeleton
        active
        title={false}
        paragraph={{ rows: 2, width: [170, 340] }}
      />
      <Space size={32}>
        <Space direction="vertical">
          <Skeleton.Button block />
          <Skeleton.Node active round style={{ width: "60vw", height: 300 }}>
            <Spin />
          </Skeleton.Node>
        </Space>
        <Space direction="vertical">
          <Skeleton.Button block />
          <Skeleton.Node active round style={{ width: "20vw", height: 300 }}>
            <Spin />
          </Skeleton.Node>
        </Space>
      </Space>
      <Space size={32}>
        <Space direction="vertical">
          <Skeleton.Button block />
          <Skeleton.Node active round style={{ width: "25vw", height: 300 }}>
            <Spin />
          </Skeleton.Node>
        </Space>
        <Space direction="vertical">
          <Skeleton.Button block />
          <Skeleton.Node active round style={{ width: "25vw", height: 300 }}>
            <Spin />
          </Skeleton.Node>
        </Space>
        <Space direction="vertical">
          <Skeleton.Button block />
          <Skeleton.Node active round style={{ width: "27vw", height: 300 }}>
            <Spin />
          </Skeleton.Node>
        </Space>
      </Space>
    </Row>
  ) : (
    children
  );

export default SkeletonGoal;
