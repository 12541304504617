import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import State from "../../context";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  theme,
  Typography,
} from "antd";

import StyledModal, { StyledForm, ValidationLabel } from "./styles";

import {
  changeEmail,
  changeUserPassword,
  getLiveAssessment,
  logout,
  setMFAEnabled,
  updatePersonalInfo,
  verifyPassword,
} from "../../utils/request/regularApp";
import { selectValue } from "../../utils/helper/general";

import Close from "../../icon/Close";

const ModalGoalsUserSettings = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ maxWidth: token.screenLGMin });
  const [emailPasswordForm] = Form.useForm();
  const [personalDataForm] = Form.useForm();
  const [state] = useContext(State);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [personalDataDirty, setPersonalDataDirty] = useState(false);
  const [entranceDataDirty, setEntranceDataDirty] = useState(false);
  const [mfaValue, setMfaValue] = useState();

  useEffect(() => {
    emailPasswordForm.setFieldsValue({
      newEmail: state._id,
    });

    personalDataForm.setFieldsValue({
      birthYear:
        state.personalInfo?.birthYear ??
        state.preferences?.valueMap?.guideInitialData?.yearBorn ??
        "",
      firstName: state.personalInfo?.firstName,
      lastName: state.personalInfo?.lastName,
    });

    if (mfaValue === undefined) {
      setMfaValue(state.isMfaEnabled);
    }
  }, [state]);

  const onPersonalDataFormFinish = ({ firstName, lastName, birthYear }) => {
    state.setKeyValue("loading", true);

    updatePersonalInfo({ birthYear, firstName, lastName })
      .then(async () => {
        const productMap = state.getPreferenceValue("productMap");

        const updateGoalsRequests = Object.keys(productMap).map(goalId =>
          getLiveAssessment({
            activeGoal: goalId,
            objective: {
              ...productMap[goalId][productMap[goalId].length - 1],
              birthYear: +birthYear,
            },
            organization: "goals",
            productId:
              productMap[goalId][productMap[goalId].length - 1].productId,
            saveAction: "PUT",
            saveId: goalId,
          }).catch(console.log)
        );

        await Promise.all(updateGoalsRequests);

        state
          .setUserData()
          .then(() => {
            state.setKeyValue("loading", false);
            setPersonalDataDirty(false);
            state.showSuccess("Personal data saved!");
            state.closeModal("profileSettingsModal");
          })
          .catch(error => {
            state.setKeyValue("loading", false);
            console.log(error);
          });
      })
      .catch(error => {
        state.setKeyValue("loading", false);
        console.log(error);
      });
  };

  const onEmailPasswordFormFinish = values => {
    state.setKeyValue("loading", true);

    if (oldPassword) {
      verifyPassword(oldPassword)
        .then(response => {
          if (response.data) {
            changeUserPassword(values)
              .then(() =>
                state.setUserData().then(() => {
                  state.showSuccess("Password changed!");
                  emailPasswordForm.resetFields();
                  setOldPassword("");
                  state.setKeyValue("loading", false);
                  state.closeModal("profileSettingsModal");
                })
              )
              .catch(error => {
                console.log(error);
                state.setKeyValue("loading", false);
              });
          } else {
            state.setKeyValue("loading", false);
            state.showError("Old password is incorrect");
          }
        })
        .catch(error => {
          console.log(error);
          state.setKeyValue("loading", false);
        });
    }

    if (values.newEmail && values.newEmail !== state._id) {
      changeEmail(values.newEmail)
        .then(() => {
          state.setUserData().then(() => {
            state.setKeyValue("loading", false);
            state.showSuccess("Email changed!");
            setEntranceDataDirty(false);
            state.closeModal("profileSettingsModal");
          });
        })
        .catch(error => {
          state.setKeyValue("loading", false);
          state.showError(error.response.data);
        });
    }

    if (values.enableMFA !== undefined) {
      setMFAEnabled(values.enableMFA)
        .then(() => {
          state.setUserData().then(() => {
            setEntranceDataDirty(false);
            state.closeModal("profileSettingsModal");
            state.setKeyValue("loading", false);
            state.showSuccess("Your information has been saved. Thank you!");
          });
        })
        .catch(error => {
          console.log(error);
          state.setKeyValue("loading", false);
        });
    }
  };

  const handleContact = () => {
    state.closeModal("profileSettingsModal");
    state.openModal("contactUsModal");
  };

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      footer={false}
      forceRender
      onCancel={() => state.closeModal("profileSettingsModal")}
      open={state.profileSettingsModal}
      title={!isTablet && "Profile"}
      width={831}
    >
      {isTablet && (
        <Row justify="space-between" style={{ marginBottom: 27 }}>
          <Typography.Text
            style={{ fontSize: 28, fontWeight: 500, color: "#23292C" }}
          >
            Profile
          </Typography.Text>
          <Button
            onClick={() =>
              logout()
                .then(() => navigate("/login"))
                .catch(console.log)
            }
            shape={"round"}
            style={{ width: 163 }}
            type={"primary"}
          >
            Log Out
          </Button>
        </Row>
      )}
      <StyledForm
        autoComplete={"off"}
        form={personalDataForm}
        onFinish={onPersonalDataFormFinish}
        style={{ marginBottom: isTablet ? 50 : 30 }}
      >
        <Row align={"bottom"} style={{ gap: 16 }} wrap={isTablet}>
          <Form.Item
            label={"Name"}
            labelCol={{ span: 24 }}
            name="firstName"
            onChange={() => setPersonalDataDirty(true)}
            onClick={selectValue}
            style={{ width: "100%", margin: 0 }}
          >
            <Input
              disabled={state.loading}
              placeholder={"First Name"}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            name={"lastName"}
            onChange={() => setPersonalDataDirty(true)}
            onClick={selectValue}
            style={{ width: "100%", margin: 0 }}
          >
            <Input
              disabled={state.loading}
              placeholder={"Last Name"}
              size={"large"}
            />
          </Form.Item>
        </Row>
        <Form.Item
          label={"Birth Year"}
          labelCol={{ span: 24 }}
          name={"birthYear"}
          onChange={() => setPersonalDataDirty(true)}
          style={{ margin: 0 }}
          rules={[
            {
              type: "number",
              min: 1940,
              max: dayjs().format("YYYY") - 5,
            },
          ]}
        >
          <InputNumber
            disabled={state.loading}
            inputMode={"numeric"}
            onClick={selectValue}
            pattern={"[0-9]*"}
            placeholder={"YYYY"}
            size={"large"}
            style={{ width: "100%", maxWidth: 288 }}
          />
        </Form.Item>
        <Space>
          <Button
            disabled={state.loading || !personalDataDirty}
            htmlType={"submit"}
            shape={"round"}
            type={"primary"}
          >
            Save Changes
          </Button>
          <Button
            onClick={() => {
              emailPasswordForm.resetFields();
              setPersonalDataDirty(false);
            }}
            shape={"round"}
            type={"text"}
          >
            Cancel
          </Button>
        </Space>
      </StyledForm>
      <StyledForm
        autoComplete={"off"}
        form={emailPasswordForm}
        onFinish={onEmailPasswordFormFinish}
      >
        <Form.Item
          label={"Email"}
          labelCol={{ span: 24 }}
          name={"newEmail"}
          onChange={() => setEntranceDataDirty(true)}
          rules={[
            {
              message: "Please enter a valid email address",
            },
          ]}
          style={{ margin: 0 }}
        >
          <Input
            disabled={state.loading}
            id={"userEmail"}
            onChange={() => setEntranceDataDirty(true)}
            placeholder={"you@email.com"}
            size={"large"}
            style={{ maxWidth: 448 }}
          />
        </Form.Item>
        <Row align="start" wrap={isTablet} style={{ gap: 16 }}>
          <Form.Item
            labelCol={{ span: 24 }}
            label={"Old password"}
            name={"oldPassword"}
            onChange={e => setOldPassword(e.target.value)}
            rules={[
              {
                message: "",
                whitespace: false,
              },
            ]}
            style={{ margin: 0 }}
          >
            <Input.Password
              disabled={state.loading}
              id={"oldPasswordinput"}
              onChange={() => setEntranceDataDirty(true)}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            label={"New password"}
            labelCol={{ span: 24 }}
            name={"newPassword"}
            onChange={e => setNewPassword(e.target.value)}
            rules={[
              {
                required: !!oldPassword,
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
                message: () => (
                  <Space direction="vertical" style={{ marginTop: 4 }} size={2}>
                    {!/[a-z]/.test(newPassword) && (
                      <ValidationLabel span={24}>
                        One lowercase character
                      </ValidationLabel>
                    )}
                    {!/[A-Z]/.test(newPassword) && (
                      <ValidationLabel span={24}>
                        One uppercase character
                      </ValidationLabel>
                    )}
                    {newPassword?.length < 8 && (
                      <ValidationLabel span={24}>
                        8 characters minimum
                      </ValidationLabel>
                    )}
                    {(!newPassword ||
                      !/^(?=.*[0-9])|(?=.*[^0-9A-Za-z]).*$/.test(
                        newPassword
                      )) && (
                      <ValidationLabel span={24}>
                        One number or one special character
                      </ValidationLabel>
                    )}
                  </Space>
                ),
                whitespace: false,
              },
            ]}
            style={{ margin: 0 }}
          >
            <Input.Password
              disabled={state.loading}
              id={"newPasswordInput"}
              onChange={() => setEntranceDataDirty(true)}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            dependencies={["newPassword"]}
            label={"Confirm Password"}
            labelCol={{ span: 24 }}
            name={"confirmNewPassword"}
            rules={[
              {
                required: !!oldPassword,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
            style={{ margin: 0 }}
          >
            <Input.Password
              disabled={state.loading}
              id={"confirmNewPasswordInput"}
              onChange={() => setEntranceDataDirty(true)}
              size={"large"}
            />
          </Form.Item>
        </Row>
        <Form.Item
          label={"Enable Mfa (Multi-Factor Authentication)"}
          labelCol={{ span: 24 }}
          name={"enableMFA"}
          style={{ margin: 0 }}
        >
          <Switch
            checked={mfaValue}
            onChange={checked => {
              setMfaValue(checked);
              setEntranceDataDirty(true);
            }}
          />
        </Form.Item>
        <Space>
          <Button
            disabled={!entranceDataDirty || state.loading}
            htmlType={"submit"}
            shape={"round"}
            type={"primary"}
          >
            Save Changes
          </Button>
          <Button
            onClick={() => {
              emailPasswordForm.resetFields();
              setEntranceDataDirty(false);
            }}
            shape={"round"}
            type={"text"}
          >
            Cancel
          </Button>
        </Space>
      </StyledForm>
      {isTablet && (
        <Button
          block
          onClick={handleContact}
          shape={"round"}
          style={{ marginTop: 31 }}
          type={"primary"}
        >
          Contact OnTrajectory
        </Button>
      )}
    </StyledModal>
  );
};

export default ModalGoalsUserSettings;
