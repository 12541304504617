const OpenInNew = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.896"
    height="9.896"
    viewBox="0 0 9.896 9.896"
  >
    <path
      d="M13.3,13.3H5.6V5.6H9.448V4.5H5.6A1.1,1.1,0,0,0,4.5,5.6v7.7a1.1,1.1,0,0,0,1.1,1.1h7.7a1.1,1.1,0,0,0,1.1-1.1V9.448H13.3ZM10.548,4.5V5.6h1.974L7.117,11l.775.775,5.4-5.4V8.348h1.1V4.5Z"
      transform="translate(-4.5 -4.5)"
      fill={color}
    />
  </svg>
);

export default OpenInNew;
