import { useContext } from "react";
import State from "../../context";
import { Button, Flex, Row, theme } from "antd";
import ProspectsTable from "./components/ProspectsTable";
import AnalyticValue from "../../atom/AnalyticValue";
import ButtonDemoMode from "../../atom/ButtonDemoMode";

import { getCurrencyValueWithRoundSign } from "../../utils/helper/general";
import {
  getManagedUsersTotalProposedAmount,
  getProspectUsers,
} from "../../utils/helper/specialized";
import PlusOutlined from "../../icon/PlusOutlined";

const PageProspects = ({ instanceLabel = "prospect" }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const getTotalAum = () =>
    getProspectUsers(state).length
      ? getManagedUsersTotalProposedAmount(getProspectUsers(state))
      : 0;

  return (
    <>
      <Row justify={"space-between"}>
        <Flex gap={100} align={"start"} style={{ marginBottom: 20 }}>
          <AnalyticValue
            label={`Total ${instanceLabel}s`}
            value={getProspectUsers(state).length}
          />
          <AnalyticValue
            label={"Total Proposed AUM"}
            value={getCurrencyValueWithRoundSign(getTotalAum())}
          />
        </Flex>
        <Flex gap={16}>
          <ButtonDemoMode
            organization={state.organization?.name}
            userManagerEmail={state._id}
          />
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              state.setKeyValue("activeEmail", null);
              state.openModal("addProspectModal");
            }}
            size="small"
            shape="round"
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.buttonAddUserBackground,
              textTransform: "capitalize",
            }}
            type="primary"
          >
            Add New {instanceLabel}
          </Button>
        </Flex>
      </Row>
      <ProspectsTable instanceLabel={instanceLabel} />
    </>
  );
};

export default PageProspects;
