import styled from "styled-components";
import { Flex } from "antd";

export const NewsCard = styled(Flex)`
  color: #17191c;
  border-bottom: 1px solid #e1ecf1;
  padding-bottom: 11px;

  .title {
    line-height: 28px;
    font-size: 22px;
    margin: 0 0 6px;
  }

  .date {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 2px;
  }

  .description {
    line-height: 18px;
    font-size: 14px;
    margin: 0 0 6px;
  }

  .source {
    font-size: 11px;
    color: #546170;
    margin: 0;
  }

  .button {
    width: fit-content;
    font-size: 16px;
    color: #21409a;
    padding: unset;
  }
`;
