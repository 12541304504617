import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  padding-top: 52px;
  overflow: auto;

  .title {
    color: #0b2a46;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 21px;
  }

  .ant-slider-vertical {
    .ant-slider-dot,
    .ant-slider-dot-active {
      background: #ffffff50;
      border-color: #ffffff50;
      border-radius: 23px;
      height: 4px;
      width: 15px;
      z-index: 1;
    }

    .ant-slider-mark-text {
      color: #0b2a46;
      font-size: 20px;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      width: 160px;
    }

    .ant-slider-handle::before,
    .ant-slider-handle::after {
      visibility: hidden;
    }
  }
`;

export default StylesContainer;
