import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    line-height: 29px;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-weight: bold;
    color: #131523;
    border-bottom: 1px solid #bec8ce;
    margin-bottom: 26px;
  }
`;

export default StylesContainer;
