import { useState } from "react";
import { Row, theme } from "antd";

import { RiskToleranceLabel, StyledSlider } from "./styles";

const RiskToleranceSection = ({ updateProposalData }) => {
  const { token } = theme.useToken();
  const [sliderValue, setSliderValue] = useState(25);

  const handleSliderChange = value => {
    const [roundedSliderValue, riskToleranceType] = getChartCalculations(value);
    setSliderValue(roundedSliderValue);
    updateProposalData("riskTolerance", riskToleranceType);
  };

  const getChartCalculations = value => {
    if (value < 10) {
      return [5, "preservation"];
    } else if (value < 20) {
      return [15, "conservative"];
    } else if (value < 30) {
      return [25, "moderate"];
    } else if (value < 40) {
      return [35, "growth"];
    } else {
      return [45, "equity"];
    }
  };

  return (
    <div style={{ textAlign: "center", maxWidth: 700, margin: "0 auto" }}>
      <h3 className="title">Risk Tolerance</h3>
      <p className="description">
        Every investment involves some risk. We can look at portfolios that
        match your level of desired risk.
      </p>
      <Row
        justify="space-evenly"
        style={{ color: token.colorNavy, marginBottom: 21 }}
      >
        <RiskToleranceLabel>Preservation</RiskToleranceLabel>
        <RiskToleranceLabel>Conservative</RiskToleranceLabel>
        <RiskToleranceLabel>Moderate</RiskToleranceLabel>
        <RiskToleranceLabel>Growth</RiskToleranceLabel>
        <RiskToleranceLabel>Equity</RiskToleranceLabel>
      </Row>
      <div
        style={{
          borderLeft: `1px solid ${token.colorGrey1}`,
        }}
      >
        <div style={{ overflow: "hidden" }}>
          <div
            className="risk-tolerance-upper-chart-part"
            style={{
              height: 116,
              background:
                "conic-gradient(transparent 0deg 80deg, #B8D8E6 82deg 260deg, transparent 262deg)",
              transform: `translateY(${50 - sliderValue}px) rotateX(${
                (50 - sliderValue) * 1.5
              }deg)`,
            }}
          ></div>
        </div>
        <StyledSlider
          value={sliderValue}
          min={0}
          max={50}
          step={1}
          tooltip={{ open: false }}
          trackStyle={{
            height: 15,
            background: "linear-gradient(90deg, #4D83B2 0%, #39495D 100%",
            borderRadius: 35,
            cursor: "default",
          }}
          railStyle={{
            height: 15,
            background: "#E7EBEE",
            borderRadius: 10,
            boxShadow: "inset 0 2px 3px #00000029",
            cursor: "default",
          }}
          onChange={value => setSliderValue(value)}
          onAfterChange={value => handleSliderChange(value)}
        />
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              height: 68,
              background:
                "conic-gradient(#bbb 94deg, transparent 96deg 274deg, #bbb 276deg)",
              boxShadow: "inset 0 10px 10px white",
              transform: `translateY(-${(50 - sliderValue) / 2}px) rotateX(${
                (50 - sliderValue) * 0.75
              }deg)`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default RiskToleranceSection;
