import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 32px;
  }

  & .ant-modal-header {
    margin-bottom: 24px;
  }

  & .ant-modal-footer {
    margin-top: 40px;
  }

  .list-title {
    font-size: 18px;
    color: #3e5377;
    border-bottom: 1px solid #7ebaba;
  }

  .list {
    gap: 32px;
    flex-wrap: nowrap;
    overflow: auto;
  }

  @media screen and (max-width: 576px) {
    &.ant-modal .ant-modal-content {
      height: calc(100vh - 36px);
      overflow: auto;
    }

    .list {
      flex-wrap: wrap;
    }
  }
`;

export default StyledModal;
