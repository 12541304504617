import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .label {
    line-height: 16px;
    font-size: 12px;
    color: #a1aed0;
  }

  .value {
    line-height: 16px;
    font-size: 16px;
    color: #ffffff;
  }
`;

export default StylesContainer;
