const IconPenny = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_357_30)">
      <path
        d="M9.86899 20C9.31135 20 8.75032 19.9559 8.19098 19.8661C6.62349 19.6134 5.48442 18.2774 5.48612 16.6921V14.4557V11.6989C5.48442 11.0444 5.48272 10.3764 5.48272 10.2391C5.48272 8.85894 5.93495 7.67887 6.7918 6.82435C7.67075 5.94778 8.88803 5.46965 10.1291 5.51204C11.3889 5.55443 12.5415 6.10207 13.3729 7.05324C14.8724 8.76738 14.8231 11.409 13.2607 13.0688C12.3936 13.9912 11.1713 14.4795 9.72958 14.4795C9.58337 14.4795 8.91183 14.4727 8.25389 14.4676V16.6938C8.25389 16.9193 8.4137 17.1092 8.63471 17.1448C11.0336 17.5314 13.4562 16.7769 15.1138 15.1221C17.9462 12.2974 17.9462 7.70092 15.1138 4.87453C13.7435 3.50797 11.9193 2.75687 9.97609 2.75687C8.03288 2.75687 6.20867 3.50966 4.83838 4.87453C3.21139 6.49712 2.47524 8.90641 2.86967 11.3191C2.90537 11.5395 3.09578 11.6989 3.32189 11.6989H3.87103C4.00363 11.6989 4.11074 11.8057 4.11074 11.9379V14.215C4.11244 14.3472 4.00533 14.4541 3.87273 14.4541H3.32359C3.32359 14.4541 3.32189 14.4541 3.32019 14.4541C1.7323 14.4541 0.396018 13.3215 0.139302 11.7616C-0.39793 8.47745 0.627231 5.17464 2.88327 2.92472C4.77378 1.03934 7.29333 0 9.97439 0C12.6555 0 15.175 1.03934 17.0672 2.92642C20.9775 6.82604 20.9775 13.1723 17.0672 17.0719C15.1733 18.9607 12.5704 20 9.87069 20H9.86899ZM8.24879 11.7091C8.94413 11.7158 9.59527 11.7209 9.72788 11.7209C10.4062 11.7209 10.9009 11.5446 11.2427 11.1818C11.8445 10.5409 11.8649 9.52357 11.2886 8.86233C10.9656 8.49271 10.5201 8.28077 10.0339 8.26382C9.54767 8.24856 9.08864 8.42659 8.74522 8.77077C8.4137 9.10139 8.24539 9.59478 8.24539 10.2374C8.24539 10.3747 8.24709 11.0512 8.24879 11.7091Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconPenny;
