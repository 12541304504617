import logo from "../images/logo.svg";

export default {
  advisorDashboardPageActiveTabColor: "#DBE247",
  adminHeaderBorderBottomColor: "#C6C6C6",
  adminPieChartColorPrimary: "#296469",
  adminPieChartColorSecondary: "#DBE247",
  adminPieChartColorTertiary: "#03494E",
  adminPieChartColorRest: "#749B9E",
  colorAdminAnalyticValue: "#131523",
  colorPrimaryButton: "#428A16",
  colorPrimary: "#215054",
  colorPrimaryButtonText: "#FFFFFF",
  colorRadioButton: "#4F8083",
  colorTertiaryButton: "#1B5568",
  quarterlyGoalAumColor: "#DBE247",
  quarterlyGoalClientColor: "#4F8083",
  quarterlyGoalProposalColor: "#749B9E",
  logoSrc: logo,
  pageDashboardRightSideSectionBackground: "#749b9e",
  pageDashboardRightSideSectionTitleColor: "#ffffff",
  pageDashboardRightSideSectionTitleCopy: "Market News & Updates",
  sidebarAvatarBackground: "#6DC1C7",
  sidebarAvatarColor: "#FFFFFF",
  sidebarBackground: "#03494E",
  sidebarIconColor: "#9AB6B8",
  sidebarIconColorActive: "#FEFEFE",
  sidebarMenuItemHoverBackground: "#052D2C",
  topProductsPieChartPallet: ["#296469", "#DBE247", "#03494E", "#749B9E"],
};
