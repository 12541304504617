import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #4897d110;
  padding: 20px 28px;
  border-radius: 12px;

  .description {
    line-height: 19px;
    color: #516474;
  }
`;

export default StylesContainer;
