import styled from "styled-components";
import { Flex } from "antd";

export const StyledLayout = styled(Flex)`
  height: 100vh;
  background: #f2f2f2;
  padding: 27px 24px;
`;

export const StyledContent = styled(Flex)`
  height: 100%;
  background: #ffffff;
  border-radius: 12px;
  padding: 60px 32px;
  box-sizing: border-box;
  overflow: auto;
`;
