import { useContext } from "react";
import { useLocation } from "react-router-dom";
import State from "../../../../context";
import SecurityGate from "../../../../provider/SecurityGate";
import SectionClientDetails from "./components/SectionClientDetails";
import SectionClientsList from "./components/SectionClientsList";

const PageNewNextLevelClients = () => {
  const location = useLocation();
  const [state] = useContext(State);

  return (
    <SecurityGate state={state}>
      {location.state?.clientEmail ? (
        <SectionClientDetails
          clientData={state.managedUsers?.find(
            it => it.email === location.state.clientEmail
          )}
        />
      ) : (
        <SectionClientsList />
      )}
    </SecurityGate>
  );
};

export default PageNewNextLevelClients;
