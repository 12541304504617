export const LIST_MILESTONES_QUESTION = [
  {
    id: "career_1",
    key: "career_1",
    label: "Change in job role / career / business / working conditions",
    type: "career",
    value: null,
  },
  {
    id: "career_2",
    key: "career_2",
    label: "Job loss (being fired or laid off)",
    type: "career",
    value: null,
  },
  {
    id: "career_3",
    key: "career_3",
    label: "Beginning or ending formal studies",
    type: "career",
    value: null,
  },
  {
    id: "career_4",
    key: "career_4",
    label: "Spouse beginning / ending work outside the home",
    type: "career",
    value: null,
  },
  {
    id: "career_5",
    key: "career_5",
    label: "Major change in working conditions / conflict with supervisor",
    type: "career",
    value: null,
  },
  {
    id: "career_6",
    key: "career_6",
    label: "Retirement from work (phased in, full, shift to volunteer, etc.)",
    type: "career",
    value: null,
  },
  {
    id: "marriage_1",
    key: "marriage_1",
    label: "Getting married",
    type: "marriage",
    value: null,
  },
  {
    id: "marriage_2",
    key: "marriage_2",
    label: "Divorce / Separation",
    type: "marriage",
    value: null,
  },
  {
    id: "marriage_3",
    key: "marriage_3",
    label: "Marital reconciliation",
    type: "marriage",
    value: null,
  },
  {
    id: "marriage_4",
    key: "marriage_4",
    label: "Death of a spouse",
    type: "marriage",
    value: null,
  },
  {
    id: "marriage_custom__1",
    key: "marriage_custom__1",
    label: null,
    type: "marriage",
    value: null,
  },
  {
    hidden: true,
    id: "marriage_custom__2",
    key: "marriage_custom__2",
    label: null,
    type: "marriage",
    value: null,
  },
  {
    hidden: true,
    id: "marriage_custom__3",
    key: "marriage_custom__3",
    label: null,
    type: "marriage",
    value: null,
  },
  {
    hidden: true,
    id: "marriage_custom__4",
    key: "marriage_custom__4",
    label: null,
    type: "marriage",
    value: null,
  },
  {
    hidden: true,
    id: "marriage_custom__5",
    key: "marriage_custom__5",
    label: null,
    type: "marriage",
    value: null,
  },
  {
    id: "family_1",
    key: "family_1",
    label: "Pregnancy",
    type: "family",
    value: null,
  },
  {
    id: "family_2",
    key: "family_2",
    label:
      "New family dynamic (birth, adoption, parent moving in, new grandchild, etc)",
    type: "family",
    value: null,
  },
  {
    id: "family_3",
    key: "family_3",
    label: "Death of a close family member or loved one",
    type: "family",
    value: null,
  },
  {
    id: "family_4",
    key: "family_4",
    label: "Child leaving home (marriage, college, etc.)",
    type: "family",
    value: null,
  },
  {
    id: "family_5",
    key: "family_5",
    label: "Child starting private school",
    type: "family",
    value: null,
  },
  {
    id: "health_1",
    key: "health_1",
    label: "Major personal injury or illness",
    type: "health",
    value: null,
  },
  {
    id: "health_2",
    key: "health_2",
    label: "Major health change of a child",
    type: "health",
    value: null,
  },
  {
    id: "health_3",
    key: "health_3",
    label: "Major health change of a parent",
    type: "health",
    value: null,
  },
  {
    id: "health_4",
    key: "health_4",
    label: "Caring for child or loved one with special needs",
    type: "health",
    value: null,
  },
  {
    id: "health_custom__1",
    key: "health_custom__1",
    label: "",
    type: "health",
    value: null,
  },
  {
    hidden: true,
    id: "health_custom__2",
    key: "health_custom__2",
    label: "",
    type: "health",
    value: null,
  },
  {
    hidden: true,
    id: "health_custom__3",
    key: "health_custom__3",
    label: "",
    type: "health",
    value: null,
  },
  {
    hidden: true,
    id: "health_custom__4",
    key: "health_custom__4",
    label: "",
    type: "health",
    value: null,
  },
  {
    hidden: true,
    id: "health_custom__5",
    key: "health_custom__5",
    label: "",
    type: "health",
    value: null,
  },
  {
    id: "assets_1",
    key: "assets_1",
    label: "Significant investment gain / loss",
    type: "assets",
    value: null,
  },
  {
    id: "assets_2",
    key: "assets_2",
    label: "Significant inheritance, windfall, payout, or bonus received",
    type: "assets",
    value: null,
  },
  {
    id: "assets_3",
    key: "assets_3",
    label: "Major change in home status (moving, renovating, repairs, etc.)",
    type: "assets",
    value: null,
  },
  {
    id: "assets_4",
    key: "assets_4",
    label: "Taking on a new mortgage",
    type: "assets",
    value: null,
  },
  {
    id: "assets_5",
    key: "assets_5",
    label:
      "Taking on new debt (car, student loans, appliances, personal, etc.)",
    type: "assets",
    value: null,
  },
  {
    id: "generosity_1",
    key: "generosity_1",
    label: "Increase / decrease volunteering role / time commitment",
    type: "generosity",
    value: null,
  },
  {
    id: "generosity_2",
    key: "generosity_2",
    label: "Increase / decrease in charitable contributions",
    type: "generosity",
    value: null,
  },
  {
    id: "generosity_3",
    key: "generosity_3",
    label: "Increase / decrease in financial gift giving",
    type: "generosity",
    value: null,
  },
  {
    id: "generosity_custom__1",
    key: "generosity_custom__1",
    label: "",
    type: "generosity",
    value: null,
  },
  {
    hidden: true,
    id: "generosity_custom__2",
    key: "generosity_custom__2",
    label: "",
    type: "generosity",
    value: null,
  },
  {
    hidden: true,
    id: "generosity_custom__3",
    key: "generosity_custom__3",
    label: "",
    type: "generosity",
    value: null,
  },
  {
    hidden: true,
    id: "generosity_custom__4",
    key: "generosity_custom__4",
    label: "",
    type: "generosity",
    value: null,
  },
  {
    hidden: true,
    id: "generosity_custom__5",
    key: "generosity_custom__5",
    label: "",
    type: "generosity",
    value: null,
  },
];

export const STEPS_CONTENT = {
  career_marriage: {
    tableData: ["career", "marriage"],
    title: "Career & Marriage",
  },
  family_health: {
    tableData: ["family", "health"],
    title: "Family & Health",
  },
  assets_generosity: {
    tableData: ["assets", "generosity"],
    title: "Assets, Liabilities & Generosity",
  },
};
