import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    & .ant-modal-header {
      margin-bottom: 20px;
    }

    & .ant-modal-content {
      padding: 24px 32px 10px;
    }
  }

  .ant-input-number {
    height: 40px;
    background: #ebf1f3;
    border-color: transparent;
    padding: 4px;
  }
`;

export default StyledModal;
