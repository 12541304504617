import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-header {
    margin-bottom: 32px;
  }

  &.ant-modal .ant-modal-content {
    padding: 25px 33px 40px;
  }

  & .ant-input-number {
    width: 206px;
    background-color: #ebf1f3;
    border-color: transparent;
  }

  &.ant-modal .ant-modal-footer {
    margin-top: 58px;
  }
`;

export default StyledModal;
