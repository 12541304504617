import { useContext } from "react";
import State from "../../../context/user/State";
import UI from "../../../../../provider/UI";
import { Flex, Image } from "antd";
import ModalProfile from "../components/ModalProfile";

import StylesContainer from "./styles";

import mmiUserTheme from "../../../themes/user";

import logo from "../../../images/logo.png";
import { ReactComponent as Slogan } from "../../../images/slogan.svg";
import { ReactComponent as BurgerButton } from "../../../images/burger_button.svg";

const MmiAssetManagerLayout = ({ children }) => {
  const [state] = useContext(State);

  return (
    <UI themeConfig={mmiUserTheme}>
      <StylesContainer justify={"center"}>
        <Flex style={{ width: "100%", maxWidth: 500 }} vertical>
          <Flex
            align={"center"}
            justify={"space-between"}
            style={{
              marginBottom: 16,
            }}
          >
            <Flex align={"end"} gap={7}>
              <Image preview={false} src={logo} width={120} />
              <div style={{ marginBottom: 4 }}>
                <Slogan />
              </div>
            </Flex>
            <BurgerButton
              className={"pointer"}
              onClick={() => state.openModal("profileModal")}
            />
          </Flex>
          {children}
        </Flex>

        <ModalProfile
          closeModal={state.closeModal}
          open={state.profileModal}
          openModal={state.openModal}
        />
      </StylesContainer>
    </UI>
  );
};

export default MmiAssetManagerLayout;
