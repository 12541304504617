import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    margin-bottom: 34px;
  }

  & .ant-modal-footer {
    margin-top: 33px;
  }

  .hero-section {
    background: #4897d126;
    border-radius: 12px;
    padding: 20px;
  }

  .ant-form-item .ant-form-item-control-input {
    max-width: 200px;
  }

  .ant-input-number-outlined {
    width: 100%;
    background-color: #ebf1f3;
    border: unset;
  }
`;

export default StyledModal;
