import { Button, Flex } from "antd";

const CardGenerosity = () => (
  <Flex className={"card-next-up"}>
    <Flex gap={16} vertical>
      <h5 className={"title"}>Generosity</h5>
      <p className={"description"}>
        It’s better to give than to receive.
        <br /> Let’s talk about strategy and impact.
      </p>
    </Flex>
    <Flex align={"center"}>
      <Button
        onClick={() =>
          window.open("https://onegive.oneascent.com/login", "_blank")
        }
        shape={"round"}
        type={"primary"}
      >
        Visit OneGive
      </Button>
    </Flex>
  </Flex>
);

export default CardGenerosity;
