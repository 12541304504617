import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    background: linear-gradient(180deg, #001141 0%, #081c4f 100%);
    border-radius: 12px;
    padding: 16px 16px 4px;
  }

  &.ant-modal .ant-modal-header {
    background: transparent;
  }

  .ant-modal-title {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 48px;
  }

  &.ant-modal .ant-modal-body {
    max-height: 60vh;
    overflow: auto;
  }
`;

export default StyledModal;
