import LogoRivershares from "../images/rivershares_logo_dark.svg";
import RiversharesNavBarMask from "../images/mask.svg";

export default {
  colorPrimary: "#4897D1",
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimaryButtonText: "#FFFFFF",
  comparisonProductsColors: ["#E48361", "#83C390", "#679BCE"],
  logoSrc: LogoRivershares,
  sidebarBackground: `url(${RiversharesNavBarMask}) #006395 right`,
  sidebarIconColorActive: "#FFFFFF",
  tenantColorPrimary: "#4897D1",
};
