import PageProposalDashboard from "../page/PageProposalDashboard";
import PageProposalGoals from "../../proposal/page/PageProposalGoals";

import Goals from "../../../icon/Goals";
import GoalsDashboard from "../../../icon/GoalsDashboard";

export default [
  {
    path: "/oneascent-proposal/dashboard",
    Component: PageProposalDashboard,
    Icon: GoalsDashboard,
    text: "Dash",
  },
  {
    path: "/oneascent-proposal/goals",
    Component: PageProposalGoals,
    Icon: Goals,
    text: "Investments",
  },
].filter(it => !it.hidden);
