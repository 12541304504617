import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    position: relative;
    width: 1230px;
    padding: 71px 80px 20px 78px;
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 27px;
    margin-top: 43px;

    h3 {
      color: #2a2a2a;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.46px;
      margin: 0;
    }

    h4 {
      color: #2a2a2a;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: -0.46px;
      margin: 0;
    }

    p {
      color: #2a2a2a;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: -0.32px;
      margin: 0;
    }

    .ant-divider {
      color: #c9c8c8;
      margin: 16px 0;
    }
  }
`;

export default StylesContainer;
