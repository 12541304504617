import { useEffect, useState } from "react";
import { Button, Flex, Select, Table } from "antd";

import StyledModal from "./styles";

import { PRIORITIES_DEFAULT_STATE } from "../ModalPriorities/constant";

import Close from "../../../../../../icon/Close";

const ModalPrioritiesQuickAdd = ({
  loading,
  onCancel,
  open,
  onSave,
  selectedValues,
  showWarning,
}) => {
  const [values, setValues] = useState(
    [
      ...PRIORITIES_DEFAULT_STATE.step1.values,
      ...PRIORITIES_DEFAULT_STATE.step2.values,
      ...PRIORITIES_DEFAULT_STATE.step3.values,
      ...PRIORITIES_DEFAULT_STATE.step4.values,
    ].map(it => ({
      ...it,
      priority: 0,
    }))
  );

  useEffect(() => {
    if (!open) return;

    setValues(lastState =>
      lastState.map(it => {
        const customPriority = selectedValues.findIndex(
          that => that === it.label
        );

        if (customPriority !== "-1") {
          return {
            ...it,
            priority: customPriority + 1,
          };
        } else {
          return it;
        }
      })
    );
  }, [open]);

  const handlePriorityChange = ({ label, priority }) => {
    if (priority !== 0 && values.find(it => it.priority === priority)) {
      showWarning("You cannot have two selections with the same priority.");
    }

    setValues(lastState =>
      lastState
        .map(it => (it.priority === priority ? { ...it, priority: 0 } : it))
        .map(it =>
          it.label === label
            ? {
                ...it,
                priority,
              }
            : it
        )
    );
  };

  const TablePriorities = ({ dataSource }) => (
    <Table dataSource={dataSource} pagination={false} size={"small"}>
      <Table.Column dataIndex={"label"} title={"Value"} />
      <Table.Column
        align={"right"}
        dataIndex={"priority"}
        render={(priority, allValues) => (
          <Select
            onChange={newPriority =>
              handlePriorityChange({
                label: allValues.label,
                priority: newPriority,
              })
            }
            options={[
              {
                value: 0,
                label: 0,
              },
              {
                value: 1,
                label: 1,
              },
              {
                value: 2,
                label: 2,
              },
              {
                value: 3,
                label: 3,
              },
              {
                value: 4,
                label: 4,
              },
              {
                value: 5,
                label: 5,
              },
            ]}
            value={priority}
            variant={"borderless"}
          />
        )}
        title={"Priority"}
        width={54}
      />
    </Table>
  );

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} size={"large"}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => onSave(values)}
            shape={"round"}
            size={"large"}
            style={{ background: "#428916" }}
            type={"primary"}
          >
            Save & Close
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={"Quick-add Priorities"}
      width={830}
    >
      <Flex>
        <TablePriorities dataSource={values.slice(0, 8)} />
        <TablePriorities dataSource={values.slice(8)} />
      </Flex>
    </StyledModal>
  );
};

export default ModalPrioritiesQuickAdd;
