import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  padding: 41px 24px 20px;
  overflow: auto;

  .title {
    color: #0b2a46;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 11px;
  }

  .label-or {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }
`;

export default StylesContainer;
