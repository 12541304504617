const TilesView = ({ color }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_414_131)">
      <path
        d="M8 0H2C0.89543 0 0 0.89543 0 2V8C0 9.10457 0.89543 10 2 10H8C9.10457 10 10 9.10457 10 8V2C10 0.89543 9.10457 0 8 0Z"
        fill={color}
      />
      <path
        d="M20 0H14C12.8954 0 12 0.89543 12 2V8C12 9.10457 12.8954 10 14 10H20C21.1046 10 22 9.10457 22 8V2C22 0.89543 21.1046 0 20 0Z"
        fill={color}
      />
      <path
        d="M8 12H2C0.89543 12 0 12.8954 0 14V20C0 21.1046 0.89543 22 2 22H8C9.10457 22 10 21.1046 10 20V14C10 12.8954 9.10457 12 8 12Z"
        fill={color}
      />
      <path
        d="M20 12H14C12.8954 12 12 12.8954 12 14V20C12 21.1046 12.8954 22 14 22H20C21.1046 22 22 21.1046 22 20V14C22 12.8954 21.1046 12 20 12Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_414_131">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TilesView;
