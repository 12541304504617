import { Col, Flex, Image, Radio, Row, Space } from "antd";

import { RadioGroupLabel } from "./styles";

import demo from "./images/demo.png";

const SectionTimeHorizon = ({
  ButtonsContainer,
  proposalData,
  updateProposalData,
}) => (
  <Flex gap={50} justify={"space-between"} wrap={"wrap"}>
    <div style={{ maxWidth: 640, marginTop: 30 }}>
      <h2 className="title" style={{ margin: "0 0 38px" }}>
        Time Horizon and Investment Experience
      </h2>
      <Row gutter={[50, 40]} style={{ marginBottom: 67 }}>
        <Col sm={24} xs={24} md={12} lg={10}>
          <Radio.Group
            onChange={e =>
              updateProposalData("beginWithdrawal", e.target.value)
            }
            value={proposalData.beginWithdrawal}
          >
            <RadioGroupLabel style={{ marginBottom: 12 }}>
              When do you plan to begin withdrawal of at least 5% of my
              investments annually.
            </RadioGroupLabel>
            <Space direction="vertical" size={33}>
              <Radio value={"-3"}>0 - 3 years</Radio>
              <Radio value={"3-5"}>3 - 5 years</Radio>
              <Radio value={"5-10"}>5 - 10 years</Radio>
              <Radio value={"10-"}>More than 10 years</Radio>
            </Space>
          </Radio.Group>
        </Col>
        <Col sm={24} xs={24} md={12} lg={14}>
          <Radio.Group
            onChange={e =>
              updateProposalData("investmentKnowledge", e.target.value)
            }
            value={proposalData.investmentKnowledge}
          >
            <RadioGroupLabel style={{ marginBottom: 33 }}>
              Which Statement best describes your knowledge of investments?
            </RadioGroupLabel>
            <Space direction="vertical" size={20}>
              <Radio value={"I know virtually nothing about investing"}>
                I know virtually nothing about investing
              </Radio>
              <Radio
                value={
                  "My understanding of the markets and investing is minimal"
                }
              >
                My understanding of the markets and investing is minimal
              </Radio>
              <Radio
                value={
                  "Somewhat knowledgeable. I have a basic comprehension of investing"
                }
              >
                Somewhat knowledgeable. I have a basic comprehension of
                investing.
              </Radio>
              <Radio
                value={
                  "Highly knowledgeable. I have a good understanding of how the stock and bond markets work, and I spend considerable time keeping up with financial matters"
                }
              >
                Highly knowledgeable. I have a good understanding of how the
                stock and bond markets work, and I spend considerable time
                keeping up with financial matters
              </Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
      <ButtonsContainer />
    </div>
    <Image height={600} preview={false} src={demo} width={343} />
  </Flex>
);

export default SectionTimeHorizon;
