import styled from "styled-components";
import { Spin, theme } from "antd";

const Container = styled.div`
  .ant-spin-fullscreen {
    background: ${({ token }) => token.overlay_background};
    backdrop-filter: blur(17px);

    .ant-spin .ant-spin-dot-holder {
      color: ${({ token }) => token.spin_color};
    }
  }
`;

const Overlay = ({ loading = false, children }) => {
  const { token } = theme.useToken();

  return (
    <>
      <Container token={token}>
        <Spin fullscreen size={"large"} spinning={loading} />
      </Container>
      <div style={{ visibility: loading ? "hidden" : "" }}>{children}</div>
    </>
  );
};

export default Overlay;
