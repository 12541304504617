import styled from "styled-components";
import { Form } from "antd";

const StyledForm = styled(Form)`
  &.ant-form {
    color: #1e1919;

    .ant-radio-wrapper {
      color: #1e1919;
    }

    .ant-input-number .ant-input-number-input {
      color: #1e1919;
    }

    .ant-form-item {
      margin: 0;

      .ant-form-item-label > label {
        color: #1e1919;
      }
    }
  }
`;

export default StyledForm;
