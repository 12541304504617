import { Flex } from "antd";
import CardSponsor from "../CardSponsor";

import StyledModal, { StyledButton } from "./styles";

import { ReactComponent as CloseIcon } from "../../../../images/blue_light_close.svg";

const ModalSponsorDetails = ({ openModal, sponsorData, open, handleClose }) => {
  const handleSeeAllSponsors = () => {
    handleClose();
    openModal("sponsorsListModal");
  };

  return (
    <StyledModal
      closeIcon={<CloseIcon />}
      footer={
        <Flex gap={8} vertical>
          <StyledButton
            block
            onClick={handleSeeAllSponsors}
            shape={"round"}
            style={{ background: "#4EABE9", color: "#FFFFFF" }}
          >
            See all sponsors
          </StyledButton>
          <StyledButton
            block
            onClick={handleClose}
            shape={"round"}
            style={{ color: "#105DAE" }}
            type={"text"}
          >
            Close
          </StyledButton>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={"Sponsor Detail"}
      width={342}
    >
      <CardSponsor sponsorData={sponsorData} />
    </StyledModal>
  );
};

export default ModalSponsorDetails;
