import { Flex } from "antd";
import dayjs from "dayjs";

import StylesContainer from "./styles";

import { ReactComponent as Logo } from "./image/logo.svg";

const Header = ({ personaName }) => (
  <StylesContainer>
    <Logo />
    <Flex className={"description"} vertical>
      <b>Personalized Proposal</b>
      <span>{personaName}</span>
      <span>{dayjs().format("MM/DD/YYYY")}</span>
    </Flex>
  </StylesContainer>
);

export default Header;
