import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .label {
    color: #192849;
  }

  .ant-input-number {
    width: 159px;
    border-radius: 24px;

    .ant-input-number-handler-wrap {
      right: 10px;
    }
  }
`;

export default StylesContainer;
