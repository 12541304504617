const TableView = ({ color }) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 0H10C8.89543 0 8 0.89543 8 2V4C8 5.10457 8.89543 6 10 6H22C23.1046 6 24 5.10457 24 4V2C24 0.89543 23.1046 0 22 0Z"
      fill={color}
    />
    <path
      d="M4 0H2C0.89543 0 0 0.89543 0 2V4C0 5.10457 0.89543 6 2 6H4C5.10457 6 6 5.10457 6 4V2C6 0.89543 5.10457 0 4 0Z"
      fill={color}
    />
    <path
      d="M22 8H10C8.89543 8 8 8.89543 8 10V12C8 13.1046 8.89543 14 10 14H22C23.1046 14 24 13.1046 24 12V10C24 8.89543 23.1046 8 22 8Z"
      fill={color}
    />
    <path
      d="M4 8H2C0.89543 8 0 8.89543 0 10V12C0 13.1046 0.89543 14 2 14H4C5.10457 14 6 13.1046 6 12V10C6 8.89543 5.10457 8 4 8Z"
      fill={color}
    />
    <path
      d="M22 16H10C8.89543 16 8 16.8954 8 18V20C8 21.1046 8.89543 22 10 22H22C23.1046 22 24 21.1046 24 20V18C24 16.8954 23.1046 16 22 16Z"
      fill={color}
    />
    <path
      d="M4 16H2C0.89543 16 0 16.8954 0 18V20C0 21.1046 0.89543 22 2 22H4C5.10457 22 6 21.1046 6 20V18C6 16.8954 5.10457 16 4 16Z"
      fill={color}
    />
  </svg>
);

export default TableView;
