const CloudsOutlined = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.747"
    height="21.987"
    viewBox="0 0 24.747 21.987"
  >
    <path
      id="Icon_metro-bubbles"
      data-name="Icon metro-bubbles"
      d="M25.944,24.5a2.508,2.508,0,0,0,1.375,2.238v.342a5.114,5.114,0,0,1-4.409-1.548,6.907,6.907,0,0,1-1.778.231c-3.417,0-6.187-2.462-6.187-5.5s2.77-5.5,6.187-5.5,6.187,2.462,6.187,5.5a5.073,5.073,0,0,1-1.146,3.189A2.5,2.5,0,0,0,25.944,24.5ZM13.57,5.143c5.982,0,10.848,3.88,11,8.711a8.43,8.43,0,0,0-3.434-.72,8.186,8.186,0,0,0-5.459,2.023,6.787,6.787,0,0,0-2.36,5.109,6.592,6.592,0,0,0,.6,2.745c-.112,0-.225,0-.338,0a13.584,13.584,0,0,1-1.715-.108c-2.363,2.363-5.183,2.786-7.909,2.848v-.578c1.472-.721,2.75-2.035,2.75-3.537a4.149,4.149,0,0,0-.046-.616,8.413,8.413,0,0,1-4.078-6.945C2.571,9.144,7.5,5.143,13.57,5.143Z"
      transform="translate(-2.571 -5.143)"
      fill="#fff"
    />
  </svg>
);

export default CloudsOutlined;
