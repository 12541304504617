import styled from "styled-components";
import { Table } from "antd";

const StyledTable = styled(Table)`
  &.ant-table-wrapper {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    width: 100%;

    table {
      border-spacing: 0 10px;
    }

    .ant-table-thead > tr > th {
      background: #fff;
      border: unset;
      color: #216799;
      padding: 0;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        border-bottom: 1px solid #81b6dc;
        border-top: 1px solid #81b6dc;
        color: #222;
        line-height: 140%;
        padding: 12px;

        &:first-of-type {
          border-bottom-left-radius: 8px;
          border-left: 1px solid #81b6dc;
          border-top-left-radius: 8px;
        }

        &:last-of-type {
          border-bottom-right-radius: 8px;
          border-right: 1px solid #81b6dc;
          border-top-right-radius: 8px;
        }
      }

      .ant-input-outlined {
        border: unset;
        padding: unset;
      }
    }
  }
`;

export default StyledTable;
