import styled from "styled-components";

export const ProductListHeading = styled.div`
  color: #3e5377;
  font-size: 18px;
  border-bottom: 1px solid #7ebaba;
  display: block;
  margin-bottom: 10px;
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  padding: 8px 8px;
  margin-left: -8px;
  overflow-x: auto;
`;

export const AllProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
