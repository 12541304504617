const ThumbDown = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="25"
    viewBox="0 0 50 25"
  >
    <g id="Group_9370" data-name="Group 9370" transform="translate(-0.117)">
      <rect
        id="Rectangle_3688"
        data-name="Rectangle 3688"
        width="50"
        height="25"
        rx="12.5"
        transform="translate(0.117)"
        fill={active ? "#34579A" : "#FFFFFF"}
      />
      <g
        id="Icon_ionic-md-thumbs-up"
        data-name="Icon ionic-md-thumbs-up"
        transform="translate(35.447 21.945) rotate(180)"
      >
        <path
          id="Path_7829"
          data-name="Path 7829"
          d="M1.671,16.889H9.236a1.614,1.614,0,0,0,1.5-1.029L13.252,9.9a1.683,1.683,0,0,0,.114-.616v-1.7A1.94,1.94,0,0,0,11.7,5.63H6.461l.792-3.585.026-.264A1.275,1.275,0,0,0,6.914.888L6.026,0,.484,5.6A1.693,1.693,0,0,0,0,6.791v8.445A1.658,1.658,0,0,0,1.671,16.889Z"
          transform="translate(4.926)"
          fill={active ? "#FFFFFF" : "#34579A"}
        />
        <path
          id="Path_7830"
          data-name="Path 7830"
          d="M0,0H2.815V9.852H0Z"
          transform="translate(0 7.037)"
          fill={active ? "#FFFFFF" : "#34579A"}
        />
      </g>
    </g>
  </svg>
);

export default ThumbDown;
