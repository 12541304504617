const EnvironmentsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.498"
    height="14.498"
    viewBox="0 0 14.498 14.498"
  >
    <g transform="translate(-2.25 -2.25)">
      <path
        d="M4.3,3H14.7A1.3,1.3,0,0,1,16,4.3V6.9a1.3,1.3,0,0,1-1.3,1.3H4.3A1.3,1.3,0,0,1,3,6.9V4.3A1.3,1.3,0,0,1,4.3,3Z"
        transform="translate(0 0)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.3,21H14.7A1.3,1.3,0,0,1,16,22.3v2.6a1.3,1.3,0,0,1-1.3,1.3H4.3A1.3,1.3,0,0,1,3,24.9V22.3A1.3,1.3,0,0,1,4.3,21Z"
        transform="translate(0 -10.201)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9,9H9"
        transform="translate(-3.4 -3.4)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9,27H9"
        transform="translate(-3.4 -13.602)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default EnvironmentsIcon;
