import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../context";
import { Flex, Input, Table } from "antd";
import AnalyticValue from "../../../atom/AnalyticValue";

import StylesContainer from "./styles";

import {
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../utils/helper/general";
import { openProposal } from "../../../utils/request/manager";
import { getAllManagedProspectsOfAdvisors } from "../../../utils/helper/specialized";

const getColumns = ({ searchedText, organization }) => [
  {
    dataIndex: "clientName",
    filteredValue: [searchedText],
    key: "clientName",
    onFilter: (value, record) =>
      String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
      String(record.email).toLowerCase().includes(value.toLowerCase()),
    render: (clientName, restValues) =>
      restValues.proposalSaved ? (
        <a
          onClick={() =>
            openProposal({ userEmail: restValues.email, organization })
          }
        >
          {clientName}
        </a>
      ) : (
        clientName
      ),
    sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    title: "Prospect Name",
  },
  {
    dataIndex: "email",
    key: "email",
    render: value => <a href={"mailto:" + value}>{value}</a>,
    sorter: (a, b) => a.email.localeCompare(b.email),
    title: "Email",
  },
  {
    dataIndex: "advisor",
    key: "advisor",
    sorter: (a, b) => a.advisor.localeCompare(b.advisor),
    title: "Advisor",
  },
  {
    dataIndex: "firmName",
    key: "firmName",
    sorter: (a, b) => a.firmName.localeCompare(b.firmName),
    title: "Firm name",
  },
  {
    align: "right",
    dataIndex: "assets",
    key: "assets",
    sorter: (a, b) => a.assets.localeCompare(b.assets),
    title: "Assets",
  },
  {
    align: "right",
    dataIndex: "lastAccessed",
    key: "lastAccessed",
    sorter: (a, b) => dayjs(a.lastAccessed) - dayjs(b.lastAccessed),
    title: "Last Accessed",
  },
];

const PageAdminProspects = () => {
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const getTableData = () => {
    return getAllManagedProspectsOfAdvisors(state.managedUserManagers).map(
      it => ({
        key: it.email,
        clientName: it.lastName + ", " + it.firstName,
        email: it.email,
        advisor: it.advisorEmail,
        firmName: it.firmName ?? it.brokerDealerName,
        assets: getCurrencyValue(it.proposedAmount),
        lastAccessed: it.lastLoginTime,
        proposalSaved: !!it.proposalSavedDate,
      })
    );
  };

  return (
    <StylesContainer>
      <header>Prospects</header>
      <Flex
        gap={30}
        style={{
          marginBottom: 16,
        }}
      >
        <Input
          style={{
            maxWidth: 228,
            height: 40,
            borderRadius: 25,
          }}
          placeholder="Search..."
          onChange={event => setSearchedText(event.target.value)}
        />
        <AnalyticValue label="Total Prospects" value={getTableData().length} />
        <AnalyticValue
          label="Total Proposed AUM"
          value={getCurrencyValueWithRoundSign(
            getAllManagedProspectsOfAdvisors(state.managedUserManagers).reduce(
              (acc, it) =>
                it.proposalSavedDate ? acc + +it.proposedAmount : acc,
              0
            )
          )}
        />
      </Flex>
      <Table
        dataSource={getTableData()}
        columns={getColumns({
          searchedText,
          organization: state.organization?.name,
        })}
        pagination={false}
        loading={state.loading}
        scroll={{
          x: 1450,
        }}
      />
    </StylesContainer>
  );
};

export default PageAdminProspects;
