import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button, Flex, Row, theme } from "antd";
import ArchiveGoalDialog from "./components/ArchiveGoalDialog";
import RestoreGoalDialog from "./components/RestoreGoalDialog";
import ChartIncomeWithTypes from "../../../../../../molecule/ChartIncomeWithTypes";
import CardProduct from "../../../../../../molecule/CardProduct";
import MenuActions from "../../../../../../atom/MenuActions";
import ChartSkeleton from "../../../../../../atom/ChartSkeleton";

import StylesContainer from "./styles";

import { getLiveAssessment } from "../../../../../../utils/request/regularApp";
import { getGrowthChartConfig } from "../../../../../../utils/chart";
import { getPercentValue } from "../../../../../../utils/helper/general";
import { isOrganizationRivershares } from "../../../../../../utils/helper/specialized";

import { ReactComponent as ActionsButton } from "../../../../../../icon/actions_menu.svg";
import { DownloadOutlined } from "@ant-design/icons";

const ProposalGoalPreview = ({
  archivedGoals,
  goalsList,
  handleArchiveGoal,
  handleCardClick,
  handleCopyGoal,
  handleDownloadProposalClick,
  handleRestoreGoal,
  handleViewGoal,
  organization,
  productData,
  productsList,
  showError,
}) => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [growthChartSeries, setGrowthChartSeries] = useState([]);
  const [liveAssessment, setLiveAssessment] = useState();
  const [archiveGoalDialogOpened, setArchiveGoalDialogOpened] = useState(false);
  const [restoreGoalDialogOpened, setRestoreGoalDialogOpened] = useState(false);
  const [archivedGoalData, setArchivedGoalData] = useState();

  useEffect(() => {
    if (archivedGoals && archivedGoals[productData.goalId]) {
      setArchivedGoalData(archivedGoals[productData.goalId]);
    }
  }, [archivedGoals]);

  useEffect(() => {
    setLoading(true);

    const requestBody = {
      organization,
      productId: productData.productId ?? productData._id,
      objective: {
        ...productData,
        birthYear: undefined,
        currentAgeRange: undefined,
        lengthOfInvestment: undefined,
        withdrawalLevel: getPercentValue(productData.withdrawalLevel),
      },
    };

    getLiveAssessment(requestBody)
      .then(data => {
        setLiveAssessment({
          ...data,
          productId: productData._id,
        });
        setGrowthChartSeries(getGrowthChartSeries(data));
        setLoading(false);
      })
      .catch(({ response }) => {
        showError(response?.data?.error ?? response?.data?.message);
      });
  }, []);

  const getGrowthChartSeries = calculationsData => {
    const series = [];

    calculationsData?.grandTotalColumn &&
      series.push({
        name: "Calculated progress",
        lineWidth: 2,
        color: "#628488",
        zoneAxis: "x",
        marker: {
          enabled: false,
        },
        data: calculationsData.grandTotalColumn,
      });

    return series;
  };

  const getProductCard = () => {
    const product = productsList.find(it => it._id === productData.productId);

    return (
      <CardProduct
        handleCardClick={handleCardClick}
        labels={[product?.riskTolerance, product?.allocationType]}
        product={product}
        width={"390px"}
      />
    );
  };

  const getActions = () =>
    archivedGoalData
      ? [
          {
            key: "1",
            label: (
              <div onClick={() => setRestoreGoalDialogOpened(true)}>
                Restore
              </div>
            ),
          },
        ]
      : [
          {
            key: "1",
            label: (
              <div onClick={() => handleCopyGoal(productData.goalId)}>
                Copy Goal
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div onClick={() => setArchiveGoalDialogOpened(true)}>
                Archive Goal
              </div>
            ),
            disabled: goalsList && Object.keys(goalsList).length <= 1,
          },
        ];

  return (
    <StylesContainer className={"proposal-goal-preview-container"} vertical>
      {archivedGoalData && (
        <Flex
          className={"archived-goal-banner"}
          align={"middle"}
          justify={"center"}
          style={{ background: token.colorGrey3 }}
        >
          <span style={{ fontSize: 12 }}>
            <b>ARCHIVED</b>{" "}
            {dayjs(archivedGoalData.timestamp).format("MM/DD/YYYY h:mma")}
          </span>
        </Flex>
      )}
      <Row
        justify={"space-between"}
        style={{ marginTop: archivedGoalData && 15, marginBottom: 15 }}
      >
        <Flex className={"risk-tolerance-container"} vertical>
          <span style={{ color: token.colorGrey1 }}>Investment Objective</span>
          <b style={{ lineHeight: 1, fontSize: 24, color: "#1E1919" }}>
            {!isOrganizationRivershares(organization) && "Drive "}
            <span style={{ textTransform: "capitalize" }}>
              {productData.investmentObjective}
            </span>
          </b>
        </Flex>
        <Flex className={"actions-container"} gap={8}>
          <Flex align={"end"} vertical>
            <span style={{ fontSize: 12, color: token.colorGrey1 }}>
              Created
            </span>
            <span style={{ lineHeight: 1, fontSize: 12 }}>
              {dayjs(productData?.timestamp).format("MM/DD/YYYY h:mma")}
            </span>
          </Flex>
          <MenuActions items={getActions()}>
            <Button
              icon={<ActionsButton />}
              shape={"circle"}
              size={"small"}
              style={{ background: "#E6F4EF", width: 32 }}
              type={"text"}
            />
          </MenuActions>
        </Flex>
      </Row>
      <ChartSkeleton
        loading={loading}
        height={productData?.contributions === 0 ? 271 : 147}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...getGrowthChartConfig({
              birthYear:
                productData.currentAge &&
                dayjs().format("YYYY") - productData.currentAge,
              height: productData?.contributions === 0 ? 271 : 147,
            }),
            series: growthChartSeries,
          }}
        />
      </ChartSkeleton>
      <ChartIncomeWithTypes
        loading={loading}
        productsList={productsList}
        productsLiveAssessment={liveAssessment ? [liveAssessment] : []}
        productData={productData}
      />
      {getProductCard()}
      <Flex justify={"space-between"} size={16} style={{ marginTop: 22 }} wrap>
        <Button
          disabled={archivedGoalData}
          icon={<DownloadOutlined />}
          onClick={() =>
            handleDownloadProposalClick(
              productData.goalId,
              productData.productId
            )
          }
          shape={"round"}
          style={{ height: 36 }}
        >
          Download Proposal
        </Button>
        <Button
          disabled={archivedGoalData}
          onClick={() => handleViewGoal(productData.goalId)}
          shape={"round"}
          style={{ height: 36 }}
          type={"primary"}
        >
          View Proposal
        </Button>
      </Flex>

      <ArchiveGoalDialog
        onCancel={() => setArchiveGoalDialogOpened(false)}
        onConfirm={() => {
          setArchiveGoalDialogOpened(false);
          handleArchiveGoal(productData.goalId);
        }}
        open={archiveGoalDialogOpened}
      />
      <RestoreGoalDialog
        onCancel={() => setRestoreGoalDialogOpened(false)}
        onConfirm={() => {
          setRestoreGoalDialogOpened(false);
          handleRestoreGoal(productData.goalId);
        }}
        open={restoreGoalDialogOpened}
      />
    </StylesContainer>
  );
};

export default ProposalGoalPreview;
