import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  width: 456px;
  min-width: 456px;
  background: #ffffff;
  padding: 24px;
  border: 1px solid #bec8ce;
  border-radius: 8px;
  box-sizing: border-box;

  .chart-navbar-container {
    left: 40px;
  }

  .archived-goal-banner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 25px;
    border-radius: 8px 8px 0 0;
  }
`;

export default StylesContainer;
