import { Row } from "antd";

const IconContainer = ({
  children,
  background,
  size = 42,
  pointer,
  onClick,
}) => (
  <Row
    align="middle"
    justify="center"
    style={{
      height: size,
      width: size,
      borderRadius: "50%",
      backgroundColor: background,
      cursor: pointer ? "pointer" : "default",
    }}
    onClick={onClick}
  >
    {children}
  </Row>
);

export default IconContainer;
