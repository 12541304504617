import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  &.content-container {
    color: #1e1e1e;
    padding: 28px 46px;

    h1 {
      font-size: 29px;
      line-height: 115%;
      margin: 0;
    }

    p {
      line-height: 120%;
      margin: 0 0 8px;
    }
  }
`;

export default StylesContainer;
