import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    position: relative;
    width: 1230px;
    padding: 71px 80px 20px 78px;
  }

  main {
    align-items: start;
    display: flex;
    gap: 77px;
    justify-content: space-between;
    margin: 70px 0 30px;

    h2 {
      color: #000;
      font-size: 36px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0 0 10px;
    }

    h4 {
      color: #2a2828;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 29px;
      margin: 0 0 24px;
    }

    p {
      color: #2a2828;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      margin: 0;
    }
  }

  .legend-container {
    background: #689fb6;
    border-radius: 8px;
    box-sizing: border-box;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    padding: 17px 20px 16px 23px;
    text-align: right;
    width: 238px;

    span {
      overflow: hidden;
    }

    .marker {
      background: #88d19d;
      border: 2px solid #fefefe;
      height: 55px;
      width: 55px;
    }
  }

  .chart-container {
    background: #f0f9f9;
    border-radius: 12px;
  }
`;

export default StylesContainer;
