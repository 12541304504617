import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #001141;
  padding: 16px;
  border-radius: 12px;

  .title {
    font-size: 20px;
    color: #ffffff;
  }

  &.view-all {
    background: #081c4f;
  }

  .cell-title {
    line-height: 20px;
    font-size: 18px;
    font-weight: 400;
    color: #4eabe9;
  }

  .cell-value {
    font-family:
      Roboto Condensed,
      sans-serif;
    font-size: 18px;
  }

  .mmi-member-disclaimer {
    font-size: 12px;
    font-weight: 500;
    color: #b9eafd;
  }

  .ant-table-wrapper table {
    border-spacing: 8px 0;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    background: transparent;
    color: #ffffff;
    border-bottom-color: transparent;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    line-height: 14px;
    border-bottom-color: #38456b;
  }

  .ant-table-wrapper .ant-table-thead > tr > th::before,
  .ant-table-wrapper .ant-table-thead > tr > td::before {
    display: none;
  }

  .ant-table-wrapper .ant-table {
    font-weight: bold;
    background: #001141;
    color: #ffffff;
  }

  &.view-all .ant-table-wrapper .ant-table {
    background: #081c4f;
  }

  .ant-table-wrapper .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: unset;
  }

  .ant-table-wrapper
    .ant-table-tbody
    .ant-table-row
    > .ant-table-cell-row-hover {
    background: transparent;
  }

  .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 0;
  }

  .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 8px 0;
  }
`;

export default StylesContainer;
