import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Input, Modal, Row, Space, theme } from "antd";
import FilterCategory from "../../../../../molecule/FilterCategory";

import Close from "../../../../../icon/Close";
import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as Filter } from "../images/filter.svg";

const FilterHeading = styled.span`
  color: #a0a0a0;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
  display: block;
`;

const FiltersContainer = ({
  productsList,
  filteredProducts,
  productFiltersApplied,
  searchTerm,
  setSearchTerm,
  assetManagerFilters,
  broadCategoryFilters,
  globalCategoryFilters,
  productCategoryFilters,
  handleAssetManagerFilterChange,
  handleBroadCategoryFilterChange,
  handleGlobalCategoryFilterChange,
  handleProductCategoryFilterChange,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });
  const [filtersModalOpened, setFiltersModalOpened] = useState(false);

  return isSmallScreen ? (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row
        wrap={false}
        align="middle"
        style={{ gap: 16, margin: "6px 0 30px" }}
      >
        <Input
          size="large"
          style={{ borderRadius: 20 }}
          placeholder="Search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
        />
        <Filter
          style={{ cursor: "pointer" }}
          onClick={() => setFiltersModalOpened(true)}
        />
      </Row>
      <Modal
        open={filtersModalOpened}
        title={
          <h3 style={{ fontSize: 18, color: token.colorNavy }}>
            All Products{" "}
            <span style={{ fontWeight: 400 }}>({productsList.length})</span>
          </h3>
        }
        style={{ top: 36 }}
        onCancel={() => setFiltersModalOpened(false)}
        closeIcon={<Close />}
        footer={false}
      >
        <FilterHeading>Asset Managers</FilterHeading>
        <FilterCategory
          categoryKey="assetManager"
          defaultValues={assetManagerFilters}
          onFilterChange={selectedValues => {
            handleAssetManagerFilterChange(selectedValues);
          }}
          productFiltersApplied={productFiltersApplied}
          filteredProducts={filteredProducts}
          productsList={productsList}
        />
        <FilterHeading>Broad Categories</FilterHeading>
        <FilterCategory
          categoryKey="broadCategory"
          defaultValues={broadCategoryFilters}
          onFilterChange={selectedValues => {
            handleBroadCategoryFilterChange(selectedValues);
          }}
          productFiltersApplied={productFiltersApplied}
          filteredProducts={filteredProducts}
          productsList={productsList}
        />
        {productFiltersApplied && filteredProducts?.length > 0 && (
          <>
            <FilterHeading>Global Categories</FilterHeading>
            <FilterCategory
              categoryKey="globalCategory"
              defaultValues={globalCategoryFilters}
              onFilterChange={selectedValues => {
                handleGlobalCategoryFilterChange(selectedValues);
              }}
              disableBasedOnParent={true}
              hideUnavailableOptions={true}
              productFiltersApplied={productFiltersApplied}
              filteredProducts={filteredProducts}
              productsList={productsList}
            />
            <FilterHeading>Product Categories</FilterHeading>
            <FilterCategory
              categoryKey="productCategory"
              defaultValues={productCategoryFilters}
              onFilterChange={selectedValues => {
                handleProductCategoryFilterChange(selectedValues);
              }}
              disableBasedOnParent={true}
              hideUnavailableOptions={true}
              productFiltersApplied={productFiltersApplied}
              filteredProducts={filteredProducts}
              productsList={productsList}
            />
          </>
        )}
      </Modal>
    </Space>
  ) : (
    <Space direction="vertical">
      <b style={{ fontSize: 22, color: token.colorNavy }}>
        All Products{" "}
        <span style={{ fontWeight: 400 }}>({productsList.length})</span>
      </b>
      <Input
        size="large"
        style={{ borderRadius: 20, margin: "20px 0 24px" }}
        placeholder="Search..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
      />
      <FilterHeading>Asset Managers</FilterHeading>
      <FilterCategory
        categoryKey="assetManager"
        defaultValues={assetManagerFilters}
        onFilterChange={selectedValues => {
          handleAssetManagerFilterChange(selectedValues);
        }}
        productFiltersApplied={productFiltersApplied}
        filteredProducts={filteredProducts}
        productsList={productsList}
      />
      <FilterHeading>Broad Categories</FilterHeading>
      <FilterCategory
        categoryKey="broadCategory"
        defaultValues={broadCategoryFilters}
        onFilterChange={selectedValues => {
          handleBroadCategoryFilterChange(selectedValues);
        }}
        productFiltersApplied={productFiltersApplied}
        filteredProducts={filteredProducts}
        productsList={productsList}
      />
      {productFiltersApplied && filteredProducts?.length > 0 && (
        <>
          <FilterHeading>Global Categories</FilterHeading>
          <FilterCategory
            categoryKey="globalCategory"
            defaultValues={globalCategoryFilters}
            onFilterChange={selectedValues => {
              handleGlobalCategoryFilterChange(selectedValues);
            }}
            disableBasedOnParent={true}
            hideUnavailableOptions={true}
            productFiltersApplied={productFiltersApplied}
            filteredProducts={filteredProducts}
            productsList={productsList}
          />
          <FilterHeading>Product Categories</FilterHeading>
          <FilterCategory
            categoryKey="productCategory"
            defaultValues={productCategoryFilters}
            onFilterChange={selectedValues => {
              handleProductCategoryFilterChange(selectedValues);
            }}
            disableBasedOnParent={true}
            hideUnavailableOptions={true}
            productFiltersApplied={productFiltersApplied}
            filteredProducts={filteredProducts}
            productsList={productsList}
          />
        </>
      )}
    </Space>
  );
};

export default FiltersContainer;
