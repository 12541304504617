import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import State from "../../../../context";
import Overlay from "../../../../atom/Overlay";
import ProposalPdfContentHolder from "../../pdf/component/ProposalPdfContentHolder";
import ViewProposalGoal from "./components/ViewProposalGoal";
import ViewProposalGoals from "./components/ViewProposalGoals";

import { PDF_PAGES_LIST } from "../../constants";

import { getHtml2pdfPromiseList } from "../../../../utils/pdf";
import {
  getOrganizationManagerData,
  getUserManagerData,
} from "../../../../utils/request/manager";

const PageLevelProposal = () => {
  const location = useLocation();
  const [state] = useContext(State);

  useEffect(() => {
    getUserManagerData()
      .then(data => {
        state.setKeyValue("userManagerData", data);
      })
      .catch(() => {
        getOrganizationManagerData()
          .then(data => {
            state.setKeyValue("userManagerData", data);
          })
          .catch(console.log);
      });
  }, []);

  useEffect(() => {
    state.setKeyValue("proposalViewMode", "goals");
  }, [location]);

  useEffect(() => {
    if (state.generatePdfMode && !state.loading) {
      // calculations ready
      state.setKeyValue("generatePdfMode", false);

      const goalLastSavedProps = getGoalDataById(state.activeGoalId);

      const pagesList = PDF_PAGES_LIST.filter(
        it => it.type === goalLastSavedProps?.investmentObjective
      );

      if (!pagesList.length) return;

      getHtml2pdfPromiseList(pagesList)
        .output("bloburl")
        .then(function (pdf) {
          let pdfWindow = window.open("", "_blank");
          pdfWindow.document.write(
            "<iframe  width='100%' height='100%' src='" + pdf + "'></iframe>"
          );
          pdfWindow.document.title = "Level Report";

          state.setKeyValue("showOverlay", false);
        });
    }
  }, [state]);

  const handleAddGoal = () => {
    state.setKeyValue("activeGoal", undefined);
    state.setKeyValue("proposalViewMode", "goal");
  };

  const handleCloseGoal = () => {
    state.setKeyValue("activeGoal", undefined);
    state.setKeyValue("proposalViewMode", "goals");
  };

  const handleCopyGoal = activeGoal => {
    state.setKeyValue("activeGoal", activeGoal);
    state.setKeyValue("proposalViewMode", "goal");

    setTimeout(() => {
      state.setKeyValue("activeGoal", undefined);
    }, 1000);
  };

  const getGoalDataById = goalId => {
    if (!goalId) return;

    const goalHistory = state.getPreferenceValue("productMap")[goalId];

    return goalHistory[goalHistory.length - 1];
  };

  const handleDownloadProposal = goalId => {
    if (!goalId) {
      console.log(`Goal with id "${goalId}" do not exist`);
      return;
    }

    state.setKeyValue("activeGoalId", goalId);
    state.setKeyValue("loading", true);
    state.setKeyValue("showOverlay", true);
    state.setKeyValue("generatePdfMode", true);
  };

  const handleViewGoal = goalId => {
    state.setKeyValue("activeGoal", goalId);
    state.setKeyValue("proposalViewMode", "goal");
  };

  return (
    <Overlay loading={state.loading || state.proposalViewMode === "pdf"}>
      {state.getPreferenceValue("productMap") &&
      state.proposalViewMode === "goals" ? (
        <ViewProposalGoals
          handleAddGoal={handleAddGoal}
          handleCopyGoal={handleCopyGoal}
          handleDownloadProposal={handleDownloadProposal}
          handleViewGoal={handleViewGoal}
          productsList={state.productsList}
        />
      ) : (
        <ViewProposalGoal
          activeGoal={state.activeGoal}
          handleCloseGoal={handleCloseGoal}
          handleDownloadProposal={handleDownloadProposal}
          productsList={state.productsList}
        />
      )}

      <ProposalPdfContentHolder
        activeGoalData={getGoalDataById(state.activeGoalId)}
      />
    </Overlay>
  );
};

export default PageLevelProposal;
