import axios from "axios";
import dayjs from "dayjs";

export const addConversationDislike = ({ conversationId, email }) =>
  axios
    .post(
      `${process.env.REACT_APP_PENNY_MAIN_API}/conversation/dislike/${conversationId}`,
      {
        email,
      }
    )
    .then(({ data }) => data);

export const addConversationLike = ({ conversationId, email }) =>
  axios
    .post(
      `${process.env.REACT_APP_PENNY_MAIN_API}/conversation/like/${conversationId}`,
      {
        email,
      }
    )
    .then(({ data }) => data);

export const deleteConversation = id =>
  axios.delete(`${process.env.REACT_APP_PENNY_MAIN_API}/conversation/${id}`);

export const getAllConversations = () =>
  axios
    .get(`${process.env.REACT_APP_PENNY_MAIN_API}/conversation`)
    .then(response =>
      response.data.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt))
    );

export const getConversationById = id =>
  axios
    .get(`${process.env.REACT_APP_PENNY_MAIN_API}/conversation/${id}`)
    .then(({ data }) => data);

export const updateConversation = ({ id, payload }) =>
  axios.patch(
    `${process.env.REACT_APP_PENNY_MAIN_API}/conversation/${id}`,
    payload
  );
