import styled from "styled-components";

const StylesContainer = styled.div`
  color: #2a2828;
  font-size: 18px;
  line-height: 29px;

  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    position: relative;
    width: 1230px;
    padding: 71px 80px 20px 78px;
  }

  h2 {
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0 0 24px;
  }

  h5 {
    color: #2a2828;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 32px;
  }

  p {
    margin: 0;
  }

  .table {
    background: #f0f9f9;
    border-radius: 12px;
    padding: 42px;

    p {
      color: #45596c;
      font-size: 24px;
      font-style: normal;
      line-height: 33px;
      margin: 0;
      text-transform: capitalize;
    }

    ul {
      padding-left: 20px;

      li {
        color: #45596c;
        font-size: 18px;
        font-style: normal;
        line-height: 33px;
        text-transform: capitalize;
      }
    }
  }
`;

export default StylesContainer;
