import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  max-width: 100vw;
  top: 0;
  padding: 0;

  & .ant-modal-content {
    height: 100vh;
    padding: 0;
  }

  & .ant-modal-body {
    height: 100%;
  }
`;

export default StyledModal;
