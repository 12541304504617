import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    top: 20px;

    .ant-modal-content {
      padding: 33px 30px;
      color: #000;
    }

    .ant-modal-header {
      margin-bottom: 32px;
    }

    .ant-modal-footer {
      margin: 0;
    }

    .ant-modal-title {
      color: #000;
      font-family: Roboto, sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .ant-modal-body {
      font-family: Roboto, sans-serif;
    }

    .step-init-description {
      font-size: 18px;
      line-height: 140%;
      margin: 30px 0;
      padding: 0 21px;
    }
  }

  @media screen and (max-width: 568px) {
    &.ant-modal {
      margin: 0;
      max-width: 100%;
      top: 0;

      .ant-modal-header {
        margin-bottom: 13px;
      }

      .ant-modal-title {
        font-size: 22px;
      }

      .ant-modal-content {
        border-radius: 0;
        height: calc(100vh - 24px);
        max-height: 100vh;
        padding: 33px 20px;
      }

      .mobile-logo-container {
        padding: 0 10px 34px;
      }

      .step-init-description {
        font-size: 16px;
      }
    }
  }
`;

export default StyledModal;
