import { lazy } from "react";

const LoginOneAscentPage = lazy(() => import("../page/PageWelcome"));
const PageNotFound = lazy(() => import("../../../page/PageNotFound"));
const PagePublicQuizMilestones = lazy(
  () => import("../page/PagePublicQuizMilestones")
);
const PagePublicQuizPriorities = lazy(
  () => import("../page/PagePublicQuizPriorities")
);
const PagePublicQuizPerspectives = lazy(
  () => import("../page/PagePublicQuizPerspectives")
);

export default {
  "": LoginOneAscentPage,
  "*": PageNotFound,
  "questionnaire-milestones": PagePublicQuizMilestones,
  "questionnaire-perspectives": PagePublicQuizPerspectives,
  "questionnaire-priorities": PagePublicQuizPriorities,
};
