const Garbage = ({ fillColor }) => (
  <svg
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g transform="translate(2969 1009)">
      <g transform="translate(-2964.092 -1003.325)">
        <path
          d="M4.5,9H17.684"
          transform="translate(0 -3.07)"
          fill="none"
          stroke="#264a5c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M17.754,5.93V16.184a1.465,1.465,0,0,1-1.465,1.465H8.965A1.465,1.465,0,0,1,7.5,16.184V5.93m2.2,0V4.465A1.465,1.465,0,0,1,11.162,3h2.93a1.465,1.465,0,0,1,1.465,1.465V5.93"
          transform="translate(-1.535 0)"
          fill="none"
          stroke="#264a5c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M15,16.5v4.395"
          transform="translate(-5.373 -6.908)"
          fill="none"
          stroke="#264a5c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M21,16.5v4.395"
          transform="translate(-8.443 -6.908)"
          fill="none"
          stroke="#264a5c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export default Garbage;
