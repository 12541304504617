import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-header {
      margin-bottom: 32px;

      .ant-modal-title {
        color: #000;
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
      }
    }

    .ant-modal-footer {
      margin-top: 22px;
    }

    .ant-btn.ant-btn-round.ant-btn-lg {
      font-size: 14px;
    }
  }

  .markdown-container {
    background: #ecf5f5;
    border-radius: 8px;
    box-sizing: border-box;
    height: 286px;
    overflow: auto;
    padding: 0 16px 10px;

    .mdxeditor-root-contenteditable {
      height: 100%;

      ._contentEditable_uazmk_379 {
        height: 100%;
      }
    }

    .mdxeditor-toolbar {
      background: #ecf5f5;
    }
  }
`;

export default StyledModal;
