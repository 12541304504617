import styled from "styled-components";
import { Col, Row, Table, theme, Typography } from "antd";

const StyledTable = styled(Table)`
  margin-top: 16px;

  &.ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    font-weight: 500;
    color: #516474;
    vertical-align: bottom;
  }

  &.ant-table-wrapper .ant-table-thead > tr > th {
    background: #e7ebee80;
    border-bottom-color: transparent;
  }

  &.ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom-color: transparent;
  }

  &.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
    border-top: 1px solid #bec8ce;
  }

  &.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 16px 8px;
  }
`;

const TypicalAnalysis = styled.div`
  position: relative;
  background: #e7f1f7;
  padding: 17px 24px 14px 33px;
  box-sizing: border-box;
  border-radius: 8px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 11px;
    height: 100%;
    background: #4b94cd;
    border-radius: 8px 0 0 8px;
  }
`;

const getTableColumns = token => [
  {
    key: "indexName",
    dataIndex: "indexName",
    title: "Index Name",
  },
  {
    key: "etf",
    dataIndex: "etf",
    title: (
      <span>
        ETF
        <br /> Ticker
      </span>
    ),
  },
  {
    key: "weight",
    dataIndex: "weight",
    title: (
      <span>
        Weight of
        <br />
        Portfolio
      </span>
    ),
    align: "right",
    width: "10%",
  },
  {
    key: "weighted",
    dataIndex: "weighted",
    title: (
      <span>
        Weighted %<br /> Violation
      </span>
    ),
    align: "right",
    render: value => <b style={{ color: token.colorError }}>{value}</b>,
  },
  {
    key: "company",
    dataIndex: "company",
    title: "Company",
  },
  {
    key: "issue",
    dataIndex: "issue",
    title: "Issue",
  },
];

const PortfolioHoldings = ({ product, xRayData }) => {
  const { token } = theme.useToken();

  return (
    <div>
      <Typography.Paragraph style={{ fontSize: 18, margin: 0 }}>
        Alignment:
      </Typography.Paragraph>
      <Typography.Paragraph style={{ fontSize: 21, marginBottom: 10 }} strong>
        Holdings Overview for {product?.riskTolerance} Benchmark
      </Typography.Paragraph>
      <Row wrap={false} align={"middle"} style={{ gap: 50 }}>
        <Col flex={"auto"}>
          <Typography.Text>
            OneAscent’s values-aligned process has the unique ability to
            identify, exclude, and remove holdings from your portfolio in real
            time as your portfolio is constructed. This X-Ray report illustrates
            our ability to uncover and remove positions that should be excluded.
          </Typography.Text>
        </Col>
        <Col flex={"281px"}>
          <TypicalAnalysis>
            <Typography.Text>
              We have identified that{" "}
              <b style={{ color: token.colorError }}>8%</b> of a typical
              portfolio that matches your risk tolerance may not align with your
              values.
            </Typography.Text>
          </TypicalAnalysis>
        </Col>
      </Row>
      <StyledTable
        dataSource={xRayData?.tableData?.map((it, index) => ({
          ...it,
          key: index,
        }))}
        size={"small"}
        columns={getTableColumns(token)}
        pagination={false}
        bordered={false}
      />
    </div>
  );
};

export default PortfolioHoldings;
