import styled from "styled-components";
import { Dropdown } from "antd";

const StyledDropdown = styled(Dropdown)`
  align-items: center;
  background: #209f00;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  min-width: 36px;
  width: 36px;

  &.ant-dropdown-open {
    background: #b8edaa;
    color: #202020;
  }
`;

export default StyledDropdown;
