import styled from "styled-components";

const StylesContainer = styled.div`
  box-sizing: border-box;
  color: #052c2b;
  font-size: 16px;
  height: 1590px;
  line-height: 26px;
  padding: 71px 80px;
  position: relative;
  width: 1230px;

  main {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
  }

  h4 {
    border-bottom: 1px solid #bec8ce;
    color: #234744;
    font-size: 26px;
    font-weight: 300;
    margin: 0 0 32px;
    padding: 10px 0;
  }

  .description {
    margin: 54px 0 66px;
    padding-right: 70px;
  }

  .signature-line {
    border-bottom: 1px solid #707070;
    width: 250px;
  }

  table {
    border-spacing: 0;
    margin-top: 12px;
    width: 380px;

    thead th {
      border-bottom: 1px solid #70707050;
      text-align: left;
    }

    tbody td {
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export default StylesContainer;
