const EmergencySavingsIcon = ({
  background = "#1b5568",
  color = "#FFFFFF",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g transform="translate(-0.346 0.384)">
      <g>
        <circle
          cx="21"
          cy="21"
          r="21"
          transform="translate(0.346 -0.384)"
          fill={background}
        />
        <path
          d="M14.763,1.928A12.192,12.192,0,1,0,26.955,14.12,12.192,12.192,0,0,0,14.763,1.928ZM10.191,14.12a4.572,4.572,0,1,1,4.572,4.572,4.572,4.572,0,0,1-4.572-4.572ZM24.621,18.2,20.4,16.453a6.112,6.112,0,0,0,0-4.667l4.225-1.75a10.7,10.7,0,0,1,0,8.167ZM18.846,4.262,17.1,8.487a6.112,6.112,0,0,0-4.667,0L10.68,4.262a10.7,10.7,0,0,1,8.167,0ZM4.9,10.037l4.225,1.75a6.112,6.112,0,0,0,0,4.667L4.9,18.2a10.7,10.7,0,0,1,0-8.167ZM10.68,23.978l1.75-4.225a6.112,6.112,0,0,0,4.667,0l1.75,4.225a10.7,10.7,0,0,1-8.167,0Z"
          transform="translate(6.584 7.496)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default EmergencySavingsIcon;
