import { Skeleton, Spin } from "antd";
import styled from "styled-components";

const StyledSkeletonNode = styled(Skeleton.Node)`
  &.ant-skeleton.ant-skeleton-element {
    width: 100%;
  }
`;

const ChartSkeleton = ({ children, loading, height = 300, width = "100%" }) =>
  loading ? (
    <StyledSkeletonNode active round style={{ width, height }}>
      <Spin />
    </StyledSkeletonNode>
  ) : (
    children
  );

export default ChartSkeleton;
