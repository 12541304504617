import { useEffect } from "react";
import { Button, Flex, Form, Input, Modal, Row, theme } from "antd";

import Close from "../../../../icon/Close";

const ModalAddFolder = ({ onSuccess, onCancel, open }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldValue("newFolderName", "");
    }
  }, [open]);

  return (
    <Modal
      className={"create-folder-modal"}
      closeIcon={<Close />}
      footer={
        <Row justify="space-between">
          <Button type={"text"} shape={"round"} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            htmlType={"submit"}
            form={"formNewFolder"}
            type="primary"
            shape="round"
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
            }}
          >
            Create
          </Button>
        </Row>
      }
      open={open}
      onCancel={onCancel}
      title={"Create New Folder"}
    >
      <Flex align={"center"} justify={"center"} style={{ padding: 40 }}>
        <Form
          id={"formNewFolder"}
          form={form}
          layout={"vertical"}
          onFinish={onSuccess}
        >
          <Form.Item
            label={"Folder Name"}
            name={"newFolderName"}
            rules={[
              {
                required: true,
                message: "Folder Name is required",
              },
            ]}
          >
            <Input placeholder={"Folder Name"} />
          </Form.Item>
        </Form>
      </Flex>
    </Modal>
  );
};

export default ModalAddFolder;
