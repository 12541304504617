const GoalsDashboard = () => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3.01758C9.8984 3.01758 6.17965 5.20508 4.10153 8.70508C2.07809 12.2598 2.07809 16.5801 4.10153 20.0801C6.17965 23.6348 9.8984 25.7676 14 25.7676C18.0468 25.7676 21.7656 23.6348 23.8437 20.0801C25.8672 16.5801 25.8672 12.2598 23.8437 8.70508C21.7656 5.20508 18.0468 3.01758 14 3.01758ZM14 28.3926C8.96871 28.3926 4.37496 25.7676 1.85934 21.3926C-0.656286 17.0723 -0.656286 11.7676 1.85934 7.39258C4.37496 3.07227 8.96871 0.392578 14 0.392578C18.9765 0.392578 23.5703 3.07227 26.0859 7.39258C28.6015 11.7676 28.6015 17.0723 26.0859 21.3926C23.5703 25.7676 18.9765 28.3926 14 28.3926ZM17.0625 19.6426C17.0625 21.3379 15.6406 22.7051 14 22.7051C12.3047 22.7051 10.9375 21.3379 10.9375 19.6426C10.9375 18.4395 11.6484 17.4004 12.6875 16.9082V6.95508C12.6875 6.24414 13.2343 5.64258 14 5.64258C14.7109 5.64258 15.3125 6.24414 15.3125 6.95508V16.9082C16.2968 17.4004 17.0625 18.4395 17.0625 19.6426ZM8.74996 10.8926C7.76559 10.8926 6.99996 10.127 6.99996 9.14258C6.99996 8.21289 7.76559 7.39258 8.74996 7.39258C9.67965 7.39258 10.5 8.21289 10.5 9.14258C10.5 10.127 9.67965 10.8926 8.74996 10.8926ZM7.87496 14.3926C7.87496 15.377 7.05465 16.1426 6.12496 16.1426C5.14059 16.1426 4.37496 15.377 4.37496 14.3926C4.37496 13.4629 5.14059 12.6426 6.12496 12.6426C7.05465 12.6426 7.87496 13.4629 7.87496 14.3926ZM21.875 16.1426C20.8906 16.1426 20.125 15.377 20.125 14.3926C20.125 13.4629 20.8906 12.6426 21.875 12.6426C22.8047 12.6426 23.625 13.4629 23.625 14.3926C23.625 15.377 22.8047 16.1426 21.875 16.1426ZM21 9.14258C21 10.127 20.1797 10.8926 19.25 10.8926C18.2656 10.8926 17.5 10.127 17.5 9.14258C17.5 8.21289 18.2656 7.39258 19.25 7.39258C20.1797 7.39258 21 8.21289 21 9.14258Z"
      fill="currentColor"
    />
  </svg>
);

export default GoalsDashboard;
