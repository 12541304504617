import { useMediaQuery } from "react-responsive";
import { Flex, Image, Progress, theme } from "antd";

import StylesContainer from "./styles";

import { ReactComponent as ReorderUp } from "../ModalPerspectives/images/re_order_up.svg";

const QuestionnaireContentReorderable = ({
  Footer,
  props,
  reorderValue,
  totalSteps,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMD });

  const ValueRow = ({ isFirstRow, isLastRow, value, rowIndex }) => (
    <Flex className={"value-row"} justify={"space-between"}>
      <Flex align={"center"} gap={10}>
        <span className={"index"}>{props.customOrder && rowIndex}</span>
        <span className={"value"}>{value.label}</span>
      </Flex>
      <Flex gap={10}>
        <ReorderUp
          className={`icon-reorder ${isFirstRow ? "disabled" : ""}`}
          onClick={() =>
            !isFirstRow &&
            reorderValue({
              value,
              newIndex: rowIndex - 2,
            })
          }
        />
        <ReorderUp
          className={`icon-reorder ${isLastRow ? "disabled" : ""}`}
          onClick={() =>
            !isLastRow && reorderValue({ value, newIndex: rowIndex })
          }
          style={{ transform: "rotate(180deg)" }}
        />
      </Flex>
    </Flex>
  );

  return (
    <StylesContainer align={"center"} gap={isSmallScreen ? 18 : 30} vertical>
      <Image preview={false} src={props.imageSrc} />
      <Progress
        percent={(props.stepIndex / totalSteps) * 100}
        showInfo={false}
        size={[isSmallScreen ? "100%" : 474, 17]}
        strokeColor={"#216799"}
      />
      <h3 className={"title"}>{props.title}</h3>
      <Flex gap={10} style={{ width: "100%" }} vertical>
        {props.values?.map((it, index) => (
          <ValueRow
            isFirstRow={index === 0}
            isLastRow={index === props.values.length - 1}
            value={it}
            rowIndex={index + 1}
            key={index}
          />
        ))}
      </Flex>
      {Footer && <Footer />}
    </StylesContainer>
  );
};

export default QuestionnaireContentReorderable;
