import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .title {
    color: #000;
    font-size: 26px;
    font-weight: 800;
    line-height: 30px;
    margin: 0 12px;
  }

  .divider {
    background: #749b9e10;
    border-radius: 25px;
    height: 6px;
    width: 184px;
  }

  .card {
    background: #e6f4ef;
    border-radius: 12px;
    cursor: pointer;
    padding: 18px;

    .title {
      color: #1e1919;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin: 0;
    }

    .description {
      color: #1e1919;
      line-height: 17px;
      margin: 0;
    }

    .icon-container {
      background: #fff;
      border-radius: 50%;
      color: #4f8083;
      height: 24px;
      min-width: 24px;
      padding-left: 2px;
      width: 24px;
    }

    &:hover {
      background: #c3e5d8;

      .icon-container {
        background: #4f8083;
        color: #fff;
      }
    }
  }
`;

export default StylesContainer;
