import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #e6f4ef;
  border: 1px solid #bbb;
  border-radius: 12px;
  cursor: pointer;
  margin: 17px 0 8px;
  min-width: 306px;
  width: 306px;
`;

export default StylesContainer;
