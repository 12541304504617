import { lazy } from "react";

const PageLogin = lazy(() => import("../../goals/pages/PageLogin"));
const PageNotFound = lazy(() => import("../../../page/PageNotFound"));
const PageSuperAdminWelcome = lazy(
  () => import("../../super_admin/pages/PageWelcome")
);
const PageWelcome = lazy(() => import("../page/PageWelcome"));

export default {
  login: PageLogin,
  "login-super-admin": PageSuperAdminWelcome,
  "": PageWelcome,
  "*": PageNotFound,
};
