import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  ol {
    margin: 16px 0 30px;

    li {
      margin-bottom: 12px;
    }
  }
`;

export default StylesContainer;
