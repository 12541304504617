import styled from "styled-components";
import { Modal, Tabs } from "antd";

const StyledModal = styled(Modal)`
  .ant-layout-header {
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    min-height: 117px;
    padding: 25px 20px 30px;

    .title {
      color: #2a2828;
      display: -webkit-box;
      font-size: 22px;
      line-clamp: 1;
      margin: 4px 0 0;
      overflow: hidden;
      text-transform: capitalize;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-close {
    background: #ffffff;
    border-radius: 50%;
    height: 40px;
    right: 28px;
    top: 36px;
    width: 40px;
  }

  .ant-modal-close .ant-modal-close-icon {
    color: #333;
    font-size: 24px;
  }

  .modal-product-details-actions-menu-toggler {
    position: absolute;
    top: 24px;
    z-index: 1;
  }

  @media (max-width: 991px) {
    .ant-modal-close {
      right: 19px;
      top: 19px;
    }

    .modal-product-details-actions-menu-toggler {
      bottom: -20px;
      top: unset;
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  &.product-details-tabs {
    height: calc(100% + 45px);
    position: relative;
    top: -45px;
  }

  &.product-details-tabs > .ant-tabs-nav .ant-tabs-ink-bar {
    background: #ffffff;
    border-radius: 4px 4px 0 0;
    height: 6px;
  }

  &.product-details-tabs .ant-tabs-content-holder {
    height: fit-content;
    max-height: 70vh;
    min-height: 420px;
    overflow-y: auto;
    padding: 0 40px;
  }

  &.ant-tabs .ant-tabs-content {
    height: 100%;
  }

  &.product-details-tabs > .ant-tabs-nav {
    padding: 0 36px;
  }

  @media (max-width: 991px) {
    &.ant-tabs .ant-tabs-content-holder {
      padding: 0 20px 15px;
    }
  }
`;

export default StyledModal;
