import { Flex } from "antd";
import ChartPersonality from "../../../ChartPersonality";

import { getListUseOfMoney } from "../../../../../../constant";

export const EMPTY_COMMON_GROUND_TEXT =
  "It may come as no surprise to the couple that their inclinations in stewardship appear complementary. To put it another way, there are few if any areas of significant overlap! This need not be cause for concern. Rather, it's an invitation for discussion and understanding. Complementary tendencies are powerful when considered and applied with mutual respect and trust. A financial advisor may be particularly helpful in mediating areas of disagreement, helping the couple appreciate one another's desires and perspectives.";

const TabCommonGround = ({ coupleCalculations, partnerA, partnerB }) => {
  const relatedTendencyTypes = coupleCalculations
    .filter(it => it.total > 110 && it.difference < 50)
    .map(it => it.type);

  return (
    <Flex gap={10}>
      {relatedTendencyTypes.length ? (
        <Flex gap={20} vertical>
          {relatedTendencyTypes.map((tendencyType, index) => {
            const useOfMoneyData = getListUseOfMoney(partnerA, partnerB).find(
              it => it.type === tendencyType
            );

            return (
              <Flex className={"copy-container"} key={index} vertical>
                <h4 className={"title"}>{tendencyType}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: useOfMoneyData?.descriptionCommonGround,
                  }}
                />
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <Flex className={"copy-container"} vertical>
          <p dangerouslySetInnerHTML={{ __html: EMPTY_COMMON_GROUND_TEXT }} />
        </Flex>
      )}

      <ChartPersonality labelAccentValues={relatedTendencyTypes} />
    </Flex>
  );
};

export default TabCommonGround;
