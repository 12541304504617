import styled from "styled-components";
import { Button } from "antd";

export const HiddenFieldContainer = styled.div`
  height: 28px;
  border-radius: 4px;
  background: linear-gradient(115deg, #e3e3e3 0%, #f2f2f2 37%, #e2e2e2 100%);
`;

export const QuestionButton = styled(Button)`
  &.ant-btn.ant-btn-round.ant-btn-sm {
    display: flex;
    gap: 8px;
    align-items: center;
    background: #e6f4ef;
    color: #0551a7;
    border-color: transparent;

    &:hover {
      background: #0551a7;
      color: #ffffff;
    }
  }
`;
