import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-header {
    margin-bottom: 19px;
  }

  &.ant-modal .ant-modal-footer {
    margin-top: 32px;
  }

  & .ant-input,
  & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #ebf1f3;
    border-color: transparent;
  }
`;

export default StyledModal;
