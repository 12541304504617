export const tableItemTypes = [
  { type: "Regular_Income", title: "Regular Income" },
  { type: "Social_Security", title: "Social Security" },
  { type: "Pension", title: "Pension" },
  { type: "Rental_Income", title: "Rental Income" },
  { type: "Trust_Income", title: "Trust Income" },
  { type: "Bonuses", title: "Bonuses" },
  { type: "Insurance_Pay-Out", title: "Insurance Pay-Out" },
  { type: "Residual_Income", title: "Residual Income" },
  { type: "Other_Income", title: "Other Income" },
  { type: "Spouse_Income", title: "Spouse Income" },
  { type: "Spouse_Social_Security", title: "Spouse Social Security" },
  { type: "Fixed_Annuity", title: "Fixed Annuity" },
  { type: "Base_Expenses", title: "Basic Expenses" },
  { type: "Vehicle_Payment", title: "Vehicle Payment" },
  { type: "Rent", title: "Rent" },
  { type: "Mortgage", title: "Mortgage" },
  { type: "Credit_Card", title: "Credit Card" },
  { type: "Daycare", title: "Daycare" },
  { type: "Vacation", title: "Vacation" },
  { type: "College_Payment", title: "College Payment" },
  { type: "Loan_Payment", title: "Loan Payment" },
  { type: "Insurance_Premium", title: "Insurance Premium" },
  { type: "Property_Taxes", title: "Property Taxes" },
  { type: "Other_Expense", title: "Other Expense" },
  { type: "Expense_Group", title: "Expense Group" },
  { type: "Brokerage_Account", title: "Brokerage Account" },
  { type: "Mutual_Funds", title: "Mutual Funds" },
  { type: "IRA-Roth", title: "IRA-Roth" },
  { type: "IRA-Traditional", title: "IRA-Traditional/SEP" },
  { type: "401k/403b", title: "401k/403b/TSP" },
  { type: "529_Plan", title: "529 Plan" },
  { type: "Savings/Checking", title: "Savings/Checking" },
  { type: "Home_Equity", title: "Home Equity" },
  { type: "Health_Savings_Account", title: "Health Savings Account" },
  { type: "Other_Account", title: "Other Account" },
  { type: "401k-Roth", title: "401k-Roth" },
  { type: "457b", title: "457b" },
  { type: "cryptocurrency", title: "Cryptocurrency Account" },
];
