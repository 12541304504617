import WantToTalkGoalsImage from "../pages/PageDash/components/SectionIntroducingAdvice/images/demo.png";
import sidebar_logo from "../../../image/ot_logo_short.svg";

export default {
  colorPrimary: "#296469",
  comparisonProductsColors: ["#7908A3", "#0076FF", "#001A45"],
  fontFamily: "Inter, sans-serif",
  incomeChartWithTypesContributionsColor: "#4997D1",
  incomeChartWithTypesNavbarButtonBackground: "#FFFFFF",
  incomeChartWithTypesNavbarButtonColor: "#2A2828",
  incomeChartWithTypesNavbarContainerBackground: "#D9F3F7",
  layoutBackground: "#E3EBEC",
  pageDashAllEpisodesButtonBackground: "#296469",
  pageDashCreateNewGoalsButtonBackground: "#296469",
  pageDashCreateNewGoalsButtonColor: "#FFFFFF",
  page_dash_section_want_talk_button_background: "#296469",
  page_dash_section_want_talk_button_color: "#FFFFFF",
  page_dash_section_want_talk_button_copy: "Get Advice",
  page_dash_section_want_talk_copy: `You can click the “Get Advice” button any time to get in touch with a financial professional.`,
  pageDashNavbarBackground: "",
  pageDashWantToTalkImageLink: WantToTalkGoalsImage,
  pageDashWantToTalkLimitedContent:
    "And, for a limited time, your first three questions are free.",
  pageDashWantToTalkTitleContent:
    "Want to talk to a Licensed Independent Financial<br/> Advisor",
  pageGoalUpdateProgressButtonBackground: "#03494E",
  pageGoalUpdateProgressButtonColor: "#FFFFFF",
  sidebarAvatarBackground: "#749B9E",
  sidebarAvatarColor: "#FFFFFF",
  sidebarBackground: "#052D2C",
  sidebarIconColor: "#9BABAB",
  sidebarIconColorActive: "#FFFFFF",
  sidebarLogo: sidebar_logo,
  tenantColorPrimary: "#296469",
};
