import { Space, Typography } from "antd";

const InputContainer = ({
  children,
  style,
  labelText,
  labelColor = "#516474",
}) => (
  <Space direction="vertical" style={{ width: "100%", ...style }} size={0}>
    {labelText && (
      <Typography.Text
        style={{ color: labelColor, whiteSpace: "break-spaces" }}
      >
        {labelText}
      </Typography.Text>
    )}
    {children}
  </Space>
);

export default InputContainer;
