import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    align-items: end;
    border-bottom: 1px solid #c9c8c8;
    display: flex;
    justify-content: space-between;
    min-height: 76px;
    padding: 20px 32px;

    h3 {
      color: #215054;
      font-size: 23px;
      font-style: normal;
      line-height: 15px;
      margin: 0;
    }
  }

  main {
    padding: 50px 55px;

    h3 {
      color: #02494e;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      margin: 0;
      max-width: 250px;
    }
  }
`;

export default StylesContainer;
