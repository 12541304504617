import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #001141;
  border-radius: 12px;
  padding: 14px 16px;

  .carousel {
    width: calc(100% + 16px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .carousel::-webkit-scrollbar {
    display: none;
  }
`;

export default StylesContainer;
