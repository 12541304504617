import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    height: 100%;
    margin: 0;
    max-width: 100vw;
    padding: 0;
    top: 0;

    .ant-modal-content {
      border-radius: unset;
      height: 100vh;
      max-height: unset;
      padding: 0;
    }

    .ant-modal-body {
      height: 100%;
    }
  }
`;

export default StyledModal;
