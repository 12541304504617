import { useContext } from "react";
import ReactPlayer from "react-player";
import State from "../../../../context";
import { Button, Flex, theme } from "antd";
import SectionAdvisorDashboardAnalytics from "../../../../organism/SectionAdvisorDashboardAnalytics";
import SecurityGate from "../../../../provider/SecurityGate";

import StylesContainer from "./styles";

import { openProposalWithConfig } from "../../../../utils/helper/specialized";

import IconPenny from "../../images/IconPenny";
import LevelFeatureIcon from "./images/LevelFeatureIcon";
import ModalQuarterlyGoals from "../../../../organism/ModalQuarterlyGoals";
import { updateQuarterlyGoals } from "../../../../utils/request/manager";

const PageDashboard = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const handleUpdateQuarterlyGoals = requestBody => {
    state.setKeyValue("loading", true);

    updateQuarterlyGoals(requestBody)
      .then(() => {
        state.setKeyValue("openModalQuarterlyGoals", false);
        state.showSuccess("Quarterly Goals updated.");
        state.setAdminData();
      })
      .catch(console.log);
  };

  return (
    <SecurityGate state={state}>
      <StylesContainer token={token}>
        <header>
          <h3>Dashboard</h3>
          <Flex align={"center"} gap={23}>
            {state.access?.level && (
              <Button
                onClick={() => openProposalWithConfig({})}
                shape={"round"}
                size={"small"}
                style={{
                  width: 140,
                  fontSize: 13,
                  color: "#1B5568",
                  borderColor: "#1B5568",
                }}
              >
                Demo Mode
              </Button>
            )}
            <Button
              disabled={!state.access?.penny}
              icon={<IconPenny />}
              shape={"round"}
              onClick={() => state.openModal("openModalAskAdvice")}
              style={{ background: token.buttonAskPennyBackground }}
              type={"primary"}
            >
              <span>
                Ask<b>Penny</b>
              </span>
            </Button>
          </Flex>
        </header>

        <main>
          <Flex gap={20} vertical>
            <SectionAdvisorDashboardAnalytics />
          </Flex>
          <Flex className={"news-updates-container"} vertical>
            <h3 className={"title"}>
              {token.pageDashboardRightSideSectionTitleCopy}
            </h3>
            <div>
              <ReactPlayer
                controls={true}
                height={195}
                url={token.advisorDashboardPageVideoLink}
                width={"100%"}
              />
            </div>
            <Flex className={"unlock-features-section"} gap={10}>
              <div>
                <LevelFeatureIcon />
              </div>
              <Flex gap={4} vertical>
                <h5 className={"title"}>Want to unlock more Level features?</h5>
                <p className={"description"}>
                  From planning to portfolios, we can help you with the tools to
                  grow your firm.{" "}
                  <a href={"https://clevercx.com/"} target={"_blank"}>
                    Learn more
                  </a>{" "}
                  or{" "}
                  <a
                    href={
                      "https://meetings.hubspot.com/andrew-weiler?uuid=ebc09a2c-9b3d-46b2-b585-a677950d22ce"
                    }
                    target={"_blank"}
                  >
                    schedule time
                  </a>{" "}
                  with one of our team members.
                </p>
              </Flex>
            </Flex>
            <div className={"news-section"}>
              <h4 className={"header"}>INDUSTRY NEWS</h4>
              <div className={"body"}>
                <p className={"description"}>Coming soon</p>
              </div>
            </div>
          </Flex>
        </main>

        <ModalQuarterlyGoals
          handleUpdateQuarterlyGoals={handleUpdateQuarterlyGoals}
          initialState={state.quarterly}
          loading={state.loading}
          onClose={() => state.closeModal("openModalQuarterlyGoals")}
          open={state.openModalQuarterlyGoals}
        />
      </StylesContainer>
    </SecurityGate>
  );
};

export default PageDashboard;
