import styled from "styled-components";
import { Row } from "antd";

export const StylesContainer = styled(Row)`
  position: relative;
  flex-direction: column;
  width: 441px;
  min-width: 441px;
  background: #ffffff;
  padding: 24px;
  border: 1px solid #bec8ce;
  border-radius: 8px;
  box-sizing: border-box;
`;

export const ArchivedGoalBanner = styled(Row)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25px;
  border-radius: 8px 8px 0 0;
`;
