import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  padding: 51px 20px 12px;
  overflow: auto;

  .subtitle {
    color: #0b2a46;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
`;

export default StylesContainer;
