import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-body {
    height: 60vh;
    overflow: auto;
  }
`;

export default StyledModal;
