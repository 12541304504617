import styled from "styled-components";
import { Flex, Input, Modal, Tag } from "antd";

export const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 32px 31px 24px 29px;
  }

  &.ant-modal .ant-modal-header {
    margin-bottom: 19px;
  }

  .header-title {
    font-size: 22px;
    font-weight: 500;
    color: #23292c;
  }

  .header-description {
    font-weight: 400;
    color: #4b4747;
  }

  .section-prompt {
    background: #e6f4ef;
    border-radius: 12px;
    padding: 15px 24px 24px;
    margin-bottom: 31px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #1e1919;
    border-radius: 16px;
    border-color: transparent;
  }

  .ant-select .ant-select-arrow {
    color: #354a5f;
  }

  .status-marker {
    line-height: 19px;
    padding: 3px 8px;
    border: 1px solid transparent;
    border-radius: 6px;
    cursor: pointer;

    &.active {
      background: #749b9e;
      color: #ffffff;
      border-color: #03494e;
    }

    &.inactive {
      background: #bfbfbf;
      color: #ffffff;
    }
  }
`;

export const StyledTag = styled(Tag.CheckableTag)`
  font-size: 14px;
  margin: 0;

  &:not(.ant-tag-checkable-checked) {
    background: #ffffff;
    color: #1e1919;
    border-color: #585858;
  }
`;

export const AddNewTagInput = styled(Input)`
  height: 23px;
  width: 126px;
  border-color: #585858;
`;

export const ResponseContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background: #ebf1f3;
  padding: 23px;
  border-radius: 12px;

  &.active {
    border: 1px solid #707070;
  }

  & textarea.ant-input {
    height: 195px;
    line-height: 19px;
    color: #1e1919;
    padding: 22px 22px 21px 18px;
    border: 1px solid #a5a3a3;
    border-radius: 6px;
    resize: none;
  }
`;
