import { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../context";
import { Flex, Layout, Row, theme } from "antd";
import SectionAskAdvice from "../../organism/SectionAskAdvice";
import MenuGoalsUser from "../../ecosystem/goals/components/MenuGoalsUser";
import MenuSidebar from "../../molecule/MenuSidebar";
import ModalContactUs from "../../organism/ModalContactUs";
import ModalGoalsUserSettings from "../../organism/ModalGoalsUserSettings";
import Header from "./components/Header";

import StyledLayout from "./styles";
import "./index.css";

import {
  getOrganizationManagerData,
  getUserManagerData,
} from "../../utils/request/manager";

const { Content, Sider, Footer } = Layout;

const LayoutProposal = ({ routes, children }) => {
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenLGMin });

  useEffect(() => {
    getUserManagerData()
      .then(data => {
        state.setKeyValue("userManagerData", data);
      })
      .catch(() => {
        getOrganizationManagerData()
          .then(data => {
            state.setKeyValue("userManagerData", data);
          })
          .catch(console.log);
      });
  }, []);

  const handleButtonBackToDashboard = () => {
    state.viewMilestonesQuestionnaire &&
      state.setKeyValue("viewMilestonesQuestionnaire", false);
    state.viewPerspectiveIndividualResults &&
      state.setKeyValue("viewPerspectiveIndividualResults", false);
    state.viewPerspectiveCoupleResults &&
      state.setKeyValue("viewPerspectiveCoupleResults", false);
    state.viewPrioritiesCoupleResults &&
      state.setKeyValue("viewPrioritiesCoupleResults", false);

    state.setKeyValue("showButtonBackToDashboard", false);
  };

  return (
    <StyledLayout hasSider token={token}>
      {!isSmallScreen && (
        <Sider width={80}>
          <MenuSidebar itemsLayoutVertical routes={routes} />
          <MenuGoalsUser />
        </Sider>
      )}

      <Layout>
        <Header
          handleButtonBackToDashboard={handleButtonBackToDashboard}
          openModal={state.openModal}
          organization={state.organization?.name ?? state.orgName}
          showButtonBackToDashboard={state.showButtonBackToDashboard}
        />
        <Row
          justify={"center"}
          style={{
            height: "100%",
          }}
        >
          <Content
            className="tab-background"
            style={{
              maxWidth: 1550,
              overflow: "auto",
            }}
          >
            {children}
          </Content>
        </Row>
      </Layout>
      {isSmallScreen && (
        <Footer>
          <Flex align={"center"} justify={"space-between"}>
            <MenuSidebar itemsLayoutVertical routes={routes} />
            <MenuGoalsUser />
          </Flex>
        </Footer>
      )}

      <SectionAskAdvice setState={setState} state={state} />
      <ModalContactUs />
      <ModalGoalsUserSettings />
    </StyledLayout>
  );
};

export default LayoutProposal;
