import LogoRivershares from "../images/rivershares_logo_dark.svg";
import RiversharesNavBarMask from "../images/mask.svg";

export default {
  adminPieChartColorPrimary: "#F36835",
  adminPieChartColorSecondary: "#BEC8CE",
  adminPieChartColorTertiary: "#354A5F",
  adminPieChartColorRest: "#72C0C7",
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorAdminAnalyticValue: "#131523",
  colorPrimary: "#4897D1",
  colorPrimaryButton: "#428A16",
  colorPrimaryButtonText: "#FFFFFF",
  colorSecondaryButton: "#4897D1",
  colorSecondaryButtonText: "#FFFFFF",
  colorTertiaryButton: "#E7EBEE",
  colorTertiaryButtonText: "#516474",
  comparisonProductsColors: ["#E48361", "#83C390", "#679BCE"],
  logoSrc: LogoRivershares,
  proposalGuideFontPrimary: "Barlow, sans-serif",
  sidebarBackground: `url(${RiversharesNavBarMask}) #006395 right`,
  sidebarIconColor: "#BEC8CE",
  sidebarIconColorActive: "#FFFFFF",
  tenantColorPrimary: "#4897D1",
};
