import PageDash from "../pages/PageDash";
import PageGoal from "../pages/PageGoal";
import PageGoals from "../pages/PageGoals";

import Goals from "../../../icon/Goals";
import DashboardIconLarge from "../../../icon/DashboardIconLarge";

export default [
  {
    path: "/dashboard",
    Component: PageDash,
    Icon: DashboardIconLarge,
    text: "Dash",
  },
  {
    path: "/goals",
    Component: PageGoals,
    Icon: Goals,
    text: "Goals",
    relatedPath: "goal",
  },
  {
    path: "/goal/:id",
    Component: PageGoal,
  },
];
