import styled from "styled-components";
import { Modal } from "antd";

const StylesContainer = styled(Modal)`
  &.ant-modal {
    top: 5vh;

    .ant-modal-close {
      right: 30px;
      z-index: 999;
    }

    .ant-modal-header {
      margin-bottom: 16px;

      .ant-modal-title {
        color: #000;
        font-family: Roboto, sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    .ant-modal-content {
      padding: 33px 30px;
    }

    .ant-modal-body {
      max-height: 70vh;
      overflow: auto;
    }

    .ant-modal-footer {
      margin-top: 30px;
    }
  }

  .page-container {
    border-radius: 12px;
    box-shadow: 0 3px 6px #00000029;
  }

  .ant-table-wrapper {
    .ant-table-thead > tr > th {
      background: #fff;
      font-family: Roboto, sans-serif;
      padding: 0 16px 8px;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody > tr > td {
      border-bottom-color: #e0e0e0;
      color: #222;
      font-family: Roboto, sans-serif;
      padding: 4px 16px;

      &.drag-icon-cell {
        padding-left: 24px;
      }
    }
  }

  .preview-image-cell .ant-image {
    border: 1px solid #999;
    border-radius: 4px;
    width: 42px;
  }
`;

export default StylesContainer;
