import { ReactComponent as Bookmark } from "./images/bookmark.svg";
import { ReactComponent as ChartArea } from "./images/chart_area.svg";
import { ReactComponent as PlayCircle } from "./images/play_circle.svg";

export default {
  asideCards: [
    {
      title: "Learning Center",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempus ac nisi nec euismod.",
      Icon: Bookmark,
    },
    {
      title: "Market Watch",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempus ac nisi nec euismod.",
      Icon: ChartArea,
    },
    {
      title: "News Updates",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempus ac nisi nec euismod.",
      Icon: PlayCircle,
    },
  ],
  recentProposals: [
    {
      name: "Bob & Marla Singer",
      started: "June 2nd, 2023",
      details: "100k over 15 yrs",
    },
    {
      name: "Bob & Marla Singer",
      started: "June 2nd, 2023",
      details: "100k over 15 yrs",
      product: "Peak Allocation Model",
    },
    {
      name: "Bob & Marla Singer",
      started: "June 2nd, 2023",
      details: "100k over 15 yrs",
      product: "Peak Allocation Model",
    },
  ],
  statistic: [
    {
      amount: "$257.4M",
      description: "Total Proposed AUM",
      percent: "8",
    },
    {
      amount: "56",
      description: "Proposals this quarter",
      percent: "8",
    },
    {
      amount: "107",
      description: "Total Clients",
      percent: "2",
    },
  ],
};
