import logo from "../image/logo_legacy.svg";
import slogan from "../image/slogan_dark.svg";

export default {
  adminPieChartColorPrimary: "#F36835",
  adminPieChartColorSecondary: "#BEC8CE",
  adminPieChartColorTertiary: "#354A5F",
  adminPieChartColorRest: "#72C0C7",
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorAdminAnalyticValue: "#131523",
  colorPrimary: "#4897D1",
  colorPrimaryButton: "#428A16",
  colorPrimaryButtonText: "#FFFFFF",
  colorRadioButton: "#5DCA83",
  colorSecondaryButton: "#4897D1",
  colorSecondaryButtonText: "#FFFFFF",
  colorTertiaryButton: "#E7EBEE",
  colorTertiaryButtonText: "#516474",
  financialProductEtfColor: "#1f4789",
  logoSrc: logo,
  modalFinancialProductDetailsHeaderBackground: "#E7EBEE",
  proposalGuideFontPrimary: "Museo Sans, sans-serif",
  proposalGuideCheckbox: {
    colorBorder: "#5F6060",
    colorText: "#234744",
  },
  proposalGuideRadio: {
    colorBorder: "#8396A6",
    colorPrimary: "#5DCA83",
    colorText: "#15284B",
    fontSize: 16,
    lineWidth: 2,
  },
  sidebarBackground:
    "transparent linear-gradient(121deg, #15284B 0%, #337CA5 100%) left center ",
  sidebarIconColor: "#BEC8CE",
  sidebarIconColorActive: "#FFFFFF",
  sidebarMenuItemHoverBackground: "#152A4E",
  slogan_src: slogan,
  tenantColorPrimary: "#4897D1",
};
