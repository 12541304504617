import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    top: 10px;

    .ant-modal-content {
      padding: 24px;
    }

    .ant-modal-header {
      margin-bottom: 32px;
    }

    .ant-modal-title {
      color: #071a4d;
      font-family: Roboto, sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    .ant-modal-body {
      max-height: 70vh;
      overflow: auto;
    }

    .ant-modal-footer {
      margin-top: 32px;
    }
  }

  .description {
    color: #000;
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
    margin: 0 0 32px;
  }

  .sub-title {
    color: #000;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0 0 10px;
  }

  h4 {
    color: #071a4d;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin: 0;
  }

  .markdown-container {
    background: #ecf5f5;
    border-radius: 8px;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    padding: 0 16px 10px;
    width: 416px;

    .mdxeditor-root-contenteditable {
      height: calc(100% - 50px);

      ._contentEditable_uazmk_379 {
        height: 100%;
      }
    }

    .mdxeditor-toolbar {
      background: #ecf5f5;
    }
  }

  .ant-table-wrapper .ant-table-tbody .ant-table-cell {
    padding: 4px;
  }
`;

export default StyledModal;
