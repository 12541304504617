import { lazy } from "react";

const PageChangePassword = lazy(() => import("../pages/PageChangePassword"));
const PageNotFound = lazy(() => import("../../../page/PageNotFound"));
const PageWelcome = lazy(() => import("../pages/PageWelcome"));

export default {
  "": PageWelcome,
  "mmi/changePassword": PageChangePassword,
  "*": PageNotFound,
};
