import styled from "styled-components";
import { Form } from "antd";

export const StyledInput = styled(Form.Item)`
  position: relative;
  max-width: 395px;
  margin: 0;
  z-index: 3;

  &.ant-form-item .ant-form-item-label > label {
    font-size: 22px;
    font-weight: bold;
    color: #002b49;
  }

  &.ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label {
    padding: 0;
  }
`;
