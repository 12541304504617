import styled from "styled-components";

const StylesContainer = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #ffffff;

    &::before {
      display: none;
    }
  }

  .ant-table-wrapper tr > td:nth-child(6),
  .ant-table-wrapper tr > th:nth-child(6),
  .ant-table-wrapper
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td:nth-child(6) {
    background: #6dc1c730;
  }
`;

export default StylesContainer;
