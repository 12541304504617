import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #ffffff;
  border-radius: 12px;
  padding: 24px 22px;

  & .title {
    font-size: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
  }
`;

export default StylesContainer;
