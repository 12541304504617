const LevelFeatureIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.967"
    height="30.747"
    viewBox="0 0 28.967 30.747"
  >
    <path
      d="M15.373,30.747A15.373,15.373,0,0,1,15.373,0,15.7,15.7,0,0,1,28.029,6.583a4.915,4.915,0,0,1-.519,6.382l-2.432,2.428,2.349,2.345a4.916,4.916,0,0,1,.523,6.377A15.631,15.631,0,0,1,15.373,30.747Zm0-26.511a11.138,11.138,0,0,0,0,22.275A11.385,11.385,0,0,0,24.51,21.64a.7.7,0,0,0-.076-.9l-2.349-2.345L20.506,19.98a7.225,7.225,0,0,1-5.133,2.294,6.926,6.926,0,0,1-5.044-2.19,6.843,6.843,0,0,1-1.841-5.191,6.956,6.956,0,0,1,6.66-6.417,7.223,7.223,0,0,1,5.36,2.3c.153.152.878.89,1.587,1.613l2.421-2.418a.7.7,0,0,0,.077-.9A11.458,11.458,0,0,0,15.373,4.236Zm-.013,8.473h-.076a2.682,2.682,0,0,0-2.569,2.471,2.647,2.647,0,0,0,.71,2.01c1,1.074,2.662,1.217,4.082-.2l1.595-1.6c-.708-.722-1.436-1.462-1.588-1.616A3.055,3.055,0,0,0,15.36,12.709Z"
      fill="#fff"
    />
  </svg>
);

export default LevelFeatureIcon;
