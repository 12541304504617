export default {
  chart: {
    type: "pie",
    height: 134,
    width: 150,
    backgroundColor: "transparent",
  },
  xAxis: {
    visible: false,
  },
  tooltip: {
    outside: true,
    formatter: function () {
      return `${this.key}: <b>${this.y}</b> time(s)`;
    },
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      size: 112,
      innerSize: "60%",
      dataLabels: {
        enabled: false,
      },
      center: ["50%", "50%"],
      borderColor: "#ffffff",
      borderWidth: 2,
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
};
