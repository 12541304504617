import styled from "styled-components";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  border-color: transparent;

  & .ant-btn-icon {
    position: absolute;
    top: 9px;
    left: 10px;
  }
`;
