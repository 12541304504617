import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .values-container {
    width: 225px;
  }

  .label {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .value {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export default StylesContainer;
