import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 32px;
  }

  &.ant-modal .ant-modal-header {
    margin-bottom: 33px;
  }

  &.ant-modal .ant-modal-title {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #105dae;
  }

  &.ant-modal .ant-modal-footer {
    margin-top: 32px;
  }

  & .ant-input,
  & .ant-input-number {
    background: #ebf1f3;
    border-color: transparent;
  }
`;

export default StyledModal;
