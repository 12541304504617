import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    align-items: end;
    border-bottom: 1px solid #c9c8c8;
    display: flex;
    justify-content: space-between;
    padding: 20px 32px;

    .title {
      color: #215054;
      font-size: 23px;
      font-style: normal;
      line-height: 15px;
    }

    .ant-btn {
      height: 36px;
    }
  }

  .body {
    padding: 63px 29px;
  }
`;

export default StylesContainer;
