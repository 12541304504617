import styled from "styled-components";
import { Flex } from "antd";

export const NewsCard = styled(Flex)`
  width: 244px;
  height: 144px;
  background: #e5f1f5;
  border-radius: 12px;
  padding: 16px;

  .date {
    font-size: 10px;
    color: #546170;
    margin-top: 4px;
  }

  .image-container {
    width: 127px;
    border-radius: 12px;
    overflow: hidden;
  }

  .description-container {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 3px;
  }

  .description {
    line-height: 18px;
    color: #21409a;
    margin: 0;
    cursor: pointer;
  }

  .source {
    display: -webkit-box;
    font-size: 10px;
    color: #546170;
    margin: 0;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
