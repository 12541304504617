import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import historicalData from "./constant";

const SectionHistorical = ({ product }) => {
  const options = {
    series: [
      {
        credits: {
          enabled: false,
        },
        data: historicalData[product?.name]?.map(it => [
          new Date(it.Date).getTime(),
          it.Close,
        ]),
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, HighStock.getOptions().colors[0]],
            [
              1,
              HighStock.color(HighStock.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        name: "SPY",
        threshold: null,
        type: "area",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        options={options}
        highcharts={HighStock}
        constructorType={"stockChart"}
      />
    </div>
  );
};

export default SectionHistorical;
