import { Flex } from "antd";
import ChartPersonality from "../../../ChartPersonality";

import { getListUseOfMoney } from "../../../../../../constant";

export const EMPTY_BLIND_SPOTS_TEXT =
  "Combined, the couple does not seem to naturally neglect any of the Four Uses of Money. On the one hand, their approach is diversified, without too many eggs in one basket. On the other hand, drastic times may call for drastic measures! A financial advisor may be particularly helpful in identifying situations that call for all-out Spending, Saving, Giving, or Investing, helping the couple ramp down one or two of the other Uses for a season.";

const TabBlindSpots = ({ coupleCalculations, partnerA, partnerB }) => {
  const relatedTendencyTypes = coupleCalculations
    .filter(it => it.total < 50)
    .map(it => it.type);

  return (
    <Flex gap={10}>
      {relatedTendencyTypes.length ? (
        <Flex gap={20} vertical>
          {relatedTendencyTypes.map((tendencyType, index) => {
            const useOfMoneyData = getListUseOfMoney(partnerA, partnerB).find(
              it => it.type === tendencyType
            );

            return (
              <Flex className={"copy-container"} key={index} vertical>
                <h4 className={"title"}>{tendencyType}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: useOfMoneyData?.descriptionBlindSpots,
                  }}
                />
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <Flex className={"copy-container"} vertical>
          <p dangerouslySetInnerHTML={{ __html: EMPTY_BLIND_SPOTS_TEXT }} />
        </Flex>
      )}

      <ChartPersonality labelAccentValues={relatedTendencyTypes} />
    </Flex>
  );
};

export default TabBlindSpots;
