import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  .ant-modal .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-title {
    line-height: 34px;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    color: #546170;
    margin-bottom: 45px;
  }
`;

export default StyledModal;
