import { Flex } from "antd";

import { ValueContainer } from "./styles";

const LeadsAnalyticsSection = ({ leadsList }) => {
  const getLeadsConverted = () =>
    leadsList?.reduce(
      (acc, it) => acc + it.valueMap?.leadInitial?.isConverted || 0,
      0
    ) ?? 0;

  const getLeadsRating = () => {
    const totalLeadsRate = leadsList?.reduce(
      (acc, it) => acc + it.valueMap?.leadInitial?.rate || 0,
      0
    );

    return totalLeadsRate
      ? ((leadsList?.length / totalLeadsRate) * 10)?.toFixed(2)
      : 0;
  };

  return (
    <Flex gap={24}>
      <ValueContainer>
        <b style={{ fontSize: 32, color: "#296469" }}>
          {leadsList?.length ?? 0}
        </b>
        <div style={{ fontSize: 15, color: "#296469" }}>Total Active Leads</div>
      </ValueContainer>
      <ValueContainer>
        <b style={{ fontSize: 32, color: "#296469" }}>{getLeadsConverted()}</b>
        <div style={{ fontSize: 15, color: "#296469" }}>Leads Converted</div>
      </ValueContainer>
      <ValueContainer>
        <b style={{ fontSize: 32, color: "#296469" }}>{getLeadsRating()}</b>
        <div style={{ fontSize: 15, color: "#296469" }}>
          Average Lead Rating
        </div>
      </ValueContainer>
    </Flex>
  );
};

export default LeadsAnalyticsSection;
