const User = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="14.153"
    height="16.512"
    viewBox="0 0 14.153 16.512"
  >
    <path
      d="M11.477,10.506A4.128,4.128,0,1,0,7.349,6.378,4.126,4.126,0,0,0,11.477,10.506Zm2.831,1.179H14a5.7,5.7,0,0,1-5.05,0H8.646A4.247,4.247,0,0,0,4.4,15.932v1.062a1.77,1.77,0,0,0,1.769,1.769H16.784a1.77,1.77,0,0,0,1.769-1.769V15.932A4.247,4.247,0,0,0,14.307,11.686Z"
      transform="translate(-4.4 -2.25)"
    />
  </svg>
);

export default User;
