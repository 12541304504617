const CloseOutlined = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g id="Group_6761" transform="translate(2926 1009)">
      <g id="Group_6760" transform="translate(-3035.555 -1569.109)">
        <rect
          id="Rectangle_2735"
          width="32"
          height="32"
          rx="16"
          transform="translate(109.555 560.109)"
          fill="#d9f3f7"
        />
      </g>
      <g id="Close_-_Large" transform="translate(-2914.025 -997.025)">
        <path
          id="Path_1788"
          d="M3123.5,963.153l8.7,8.7"
          transform="translate(-3123.5 -963.153)"
          fill="none"
          stroke="#1b5568"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          id="Path_1789"
          d="M0,0,8.7,8.7"
          transform="translate(8.696 0) rotate(90)"
          fill="none"
          stroke="#1b5568"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export default CloseOutlined;
