import styled from "styled-components";
import { Table } from "antd";

export const StyledTable = styled(Table)`
  &.ant-table-wrapper {
    .ant-table-thead > tr > th {
      background: transparent;
    }

    .ant-table-tbody > tr > td {
      border-bottom-color: ${({ token }) => token.colorGrey2};
    }

    .ant-table-thead > tr > th,
    .ant-table-thead > tr > td {
      font-weight: 500;
      border-bottom-color: #8093a3;
      vertical-align: bottom;
      background: #fff;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      display: none;
    }
  }
`;
