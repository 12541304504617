import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    position: relative;
    width: 1230px;
    padding: 71px 80px 20px 78px;
  }

  main {
    margin-top: 48px;

    h2 {
      color: #0a3b66;
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
    }

    h3 {
      color: #0a3b66;
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
    }

    p {
      color: #2a2828;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      margin: 0;
    }
  }
`;

export default StylesContainer;
