import { useEffect } from "react";
import { Button, Flex, Form, Input, Modal, theme } from "antd";

import Close from "../../../../icon/Close";

const ModalRenameFolder = ({ activeFolderName, onCancel, onSuccess, open }) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  useEffect(() => {
    if (open) {
      form.setFieldValue("newFolderName", activeFolderName);
    }
  }, [open]);

  return (
    <Modal
      className={"rename-folder-modal"}
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            form={"formRenameFolder"}
            htmlType={"submit"}
            shape={"round"}
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
            }}
            type={"primary"}
          >
            Save
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={"Rename Folder"}
    >
      <Flex align={"center"} justify={"center"} style={{ padding: 40 }}>
        <Form
          form={form}
          id={"formRenameFolder"}
          layout={"vertical"}
          onFinish={onSuccess}
        >
          <Form.Item
            label={"Folder Name"}
            name={"newFolderName"}
            rules={[
              {
                message: "Folder Name is required",
                required: true,
              },
            ]}
          >
            <Input className={"input-custom"} placeholder={"New Folder Name"} />
          </Form.Item>
        </Form>
      </Flex>
    </Modal>
  );
};

export default ModalRenameFolder;
