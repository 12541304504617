import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 21px 20px 10px 32px;
  }

  & .ant-modal-body {
    padding: 24px 2px 2px;
  }

  &.ant-modal .ant-modal-footer {
    text-align: start;
  }

  & .ant-modal-body {
    height: 463px;
    overflow: auto;
  }
`;

export default StyledModal;
