const GrowRetirementSavingsIcon = ({
  background = "#1b5568",
  color = "#FFFFFF",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g transform="translate(0 0)">
      <circle
        cx="21"
        cy="21"
        r="21"
        transform="translate(0 0)"
        fill={background}
      />
      <path
        d="M3270,334.785v13.3"
        transform="translate(-3250.074 -315.768)"
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="3"
      />
      <path
        d="M3270,331.817v6.652"
        transform="translate(-3256.309 -306.148)"
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="3"
      />
      <path
        d="M3270,331.817v21.606"
        transform="translate(-3243.84 -321.104)"
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export default GrowRetirementSavingsIcon;
