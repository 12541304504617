import { useEffect } from "react";
import dayjs from "dayjs";
import { Button, Flex, Form, Input } from "antd";

import StyledModal from "./styles";

import Close from "../../../../icon/Close";

const ModalLeadQuestion = ({
  activeUserData,
  loading,
  open,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue(activeUserData?.valueMap?.leadInitial);
    }
  }, [open]);

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            htmlType={"submit"}
            form={"leadQuestionForm"}
            shape={"round"}
            type={"primary"}
          >
            Send
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={"Lead’s question"}
      width={568}
    >
      <Form
        form={form}
        id={"leadQuestionForm"}
        layout={"vertical"}
        onFinish={onSubmit}
      >
        <Flex justify={"space-between"} style={{ width: "100%" }}>
          <span>Question from lead</span>
          <span>Sent {dayjs().format("MM/DD/YY, h:mma")}</span>
        </Flex>
        <Form.Item name={"question"}>
          <Input.TextArea
            autoSize={{ minRows: 8, maxRows: 8 }}
            disabled
            id={"questionTextarea"}
            style={{ background: "#fff", border: "1px solid #C9C8C8" }}
          />
        </Form.Item>
        <Form.Item label={"Answer to Advice Question"} name={"answer"}>
          <Input.TextArea
            autoSize={{ minRows: 8, maxRows: 8 }}
            id={"answerTextarea"}
            style={{ background: "#fff", border: "1px solid #C9C8C8" }}
          />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default ModalLeadQuestion;
