import { useContext, useState } from "react";
import State from "../../../../../../context";
import { Button, Flex, Skeleton } from "antd";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalProductDetails from "../../../../../../organism/ModalProductDetails";
import ProposalGoalPreview from "../ProposalGoalPreview";

import StylesContainer from "./styles";

import {
  archiveProposalGoal,
  restoreProposalGoal,
} from "../../../../../../utils/request/manager";
import {
  isLockedByAdminView,
  isOrganizationLevel,
} from "../../../../../../utils/helper/specialized";

import { ReactComponent as ActionsButton } from "../../../../../../icon/actions_menu.svg";
import { ReactComponent as IconPlus } from "../../../../images/icon_plus_outlined.svg";

const ViewProposalGoals = ({
  handleAddGoal,
  handleCopyGoal,
  handleDownloadProposal,
  handleViewGoal,
  productsList,
}) => {
  const [state] = useContext(State);
  const [showArchivedGoals, setShowArchivedGoals] = useState(false);

  const getActions = () => [
    {
      key: "1",
      label: showArchivedGoals ? (
        <div onClick={() => setShowArchivedGoals(false)}>
          Hide archived goals
        </div>
      ) : (
        <div onClick={() => setShowArchivedGoals(true)}>
          Show archived goals
        </div>
      ),
      disabled:
        !state.getPreferenceValue("productMapArchive") ||
        !Object.keys(state.getPreferenceValue("productMapArchive"))?.length,
    },
  ];

  const handleArchiveGoal = goalId => {
    archiveProposalGoal({
      email: state._id,
      productMapId: goalId,
      organization: state.organization?.name,
    })
      .then(() => {
        state.showSuccess("Goal archived");
        state.setUserData();
      })
      .catch(console.log);
  };

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const handleRestoreGoal = goalId => {
    restoreProposalGoal({
      email: state._id,
      productMapId: goalId,
      organization: state.organization?.name,
    })
      .then(() => {
        state.showSuccess("Goal restored");
        state.setUserData();
      })
      .catch(console.log);
  };

  const handleDownloadProposalClick = (activeGoal, productId) => {
    if (isOrganizationLevel(state.organization?.name ?? state.orgName)) {
      handleDownloadProposal(activeGoal);
      return;
    }

    state.setKeyValue("activeGoal", activeGoal);
    state.setKeyValue("productDetailsId", productId);
    state.setKeyValue("selectedProduct", productId);

    localStorage.removeItem("growthChartSvg");
    localStorage.removeItem("barsChartSvg");
    localStorage.removeItem("pieChartSvg");
    localStorage.removeItem("pieChartWithLegendSvg");

    state.setKeyValue("proposalViewMode", "pdf");
    state.openModal("productDetailsModal");
  };

  return (
    <StylesContainer>
      <header>
        <h3>
          Level: <b>Investment Proposal</b>
        </h3>
      </header>
      <main>
        <Skeleton loading={state.loading}>
          <Flex
            align={"end"}
            className={"goals-page-header"}
            justify={"space-between"}
            style={{ marginBottom: 22, width: 456 }}
          >
            <h3>
              {state.personalInfo?.firstName} {state.personalInfo?.lastName}
            </h3>

            <Flex align={"center"} gap={16}>
              <Button
                disabled={isLockedByAdminView({
                  managerAccess: state.managerAccess,
                })}
                icon={<IconPlus />}
                onClick={handleAddGoal}
                shape={"round"}
                style={{ height: 36 }}
                type={"primary"}
              >
                New Goal
              </Button>
              <MenuActions items={getActions()}>
                <Button
                  icon={<ActionsButton />}
                  shape={"circle"}
                  size={"small"}
                  style={{ background: "#E6F4EF", width: 32 }}
                  type={"text"}
                />
              </MenuActions>
            </Flex>
          </Flex>
          <Flex align={"stretch"} gap={40}>
            {Object.keys(state.getPreferenceValue("productMap")).map(
              (goalId, index) => (
                <ProposalGoalPreview
                  archivedGoals={state.getPreferenceValue("productMapArchive")}
                  goalsList={state.getPreferenceValue("productMap")}
                  handleArchiveGoal={handleArchiveGoal}
                  handleCardClick={handleCardClick}
                  handleCopyGoal={handleCopyGoal}
                  handleDownloadProposalClick={handleDownloadProposalClick}
                  handleRestoreGoal={handleRestoreGoal}
                  handleViewGoal={handleViewGoal}
                  key={index}
                  organization={state.organization?.name}
                  productData={{
                    ...state.getPreferenceValue("productMap")[goalId][
                      state.getPreferenceValue("productMap")[goalId].length - 1
                    ],
                    goalId,
                  }}
                  productsList={productsList}
                  showError={state.showError}
                />
              )
            )}
            {showArchivedGoals &&
              Object.keys(state.getPreferenceValue("productMapArchive")).map(
                (goalId, index) => (
                  <ProposalGoalPreview
                    archivedGoals={state.getPreferenceValue(
                      "productMapArchive"
                    )}
                    goalsList={state.getPreferenceValue("productMap")}
                    handleArchiveGoal={handleArchiveGoal}
                    handleCardClick={handleCardClick}
                    handleCopyGoal={handleCopyGoal}
                    handleDownloadProposalClick={handleDownloadProposalClick}
                    handleRestoreGoal={handleRestoreGoal}
                    handleViewGoal={handleViewGoal}
                    key={index}
                    organization={state.organization?.name}
                    productData={{
                      ...state.getPreferenceValue("productMapArchive")[goalId]
                        .data[
                        state.getPreferenceValue("productMapArchive")[goalId]
                          .data.length - 1
                      ],
                      goalId,
                    }}
                    productsList={productsList}
                    showError={state.showError}
                  />
                )
              )}
          </Flex>

          <ModalProductDetails
            open={state.productDetailsModal}
            hand
            handleClose={() => state.closeModal("productDetailsModal")}
            product={productsList.find(it => it._id === state.productDetailsId)}
            productsList={productsList}
            investmentAssumptions={state.investmentAssumptions}
            organization={state.organization?.name?.toLowerCase()}
          />
        </Skeleton>
      </main>
    </StylesContainer>
  );
};

export default ViewProposalGoals;
