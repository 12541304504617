import styled from "styled-components";

const StylesContainer = styled.div`
  ol {
    margin: 16px 0 30px;

    li {
      margin-bottom: 12px;
    }
  }
`;

export default StylesContainer;
