import styled from "styled-components";
import { Select, Table } from "antd";

export const StyledTable = styled(Table)`
  font-family: Inter, sans-serif;
  padding: 0;
  background: transparent;

  table {
    border-spacing: 0 8px;

    .ant-table-row {
      background: #f0f4f7;
      cursor: pointer;

      .ant-table-cell {
        font-family: Inter, sans-serif;
        color: #354a5f;
      }
    }
  }

  &.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 12px 8px;
  }

  &.ant-table-wrapper .ant-table-thead > tr > th {
    font-weight: 500;
    background: transparent;
    color: #516474;
    border-bottom-color: transparent;

    &:before {
      display: none;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 228px;

  .ant-select-selector {
    border-radius: 23px;
  }
`;
