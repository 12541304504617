export default {
  chart: {
    type: "bar",
    height: 70,
    backgroundColor: "transparent",
    padding: 20,
    spacingBottom: 0,
    spacingTop: 5,
  },
  legend: false,
  title: false,
  credits: false,
  tooltip: {
    valueSuffix: " times",
  },
  plotOptions: {
    bar: {
      borderRadius: "12px",
      dataLabels: {
        enabled: true,
      },
      shadow: false,
      borderWidth: 0,
    },
    series: {
      dataLabels: {
        enabled: true,
        formatter: function () {
          return `Selected: ${this.y} time(s)`;
        },
      },
      pointWidth: 16, //column width
    },
  },
  yAxis: {
    visible: false,
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
};
