import PageAdminAdvisors from "../../admin/page/PageAdminAdvisors";
import PageAdminClients from "../../admin/page/PageAdminClients";
import PageAdminProposal from "../../admin/page/PageAdminProposal";
import PageAdminProspects from "../../admin/PageAdminProspects";
import PageMarketing from "../../../page/PageMarketing";
import PageProducts from "../../../page/PageProducts";

import Proposal from "../../../icon/Proposal";
import BoxOpen from "../../../icon/BoxOpen";
import DocumentPage from "../../../icon/DocumentPage";
import User from "../../../icon/User";
import Users from "../../../icon/Users";

export default [
  {
    path: "/oneascent-admin-portal/advisors",
    Component: PageAdminAdvisors,
    Icon: User,
    text: "Advisors",
  },
  {
    path: "/oneascent-admin-portal/products",
    Component: PageProducts,
    Icon: BoxOpen,
    text: "Products",
  },
  {
    path: "/oneascent-admin-portal/proposal",
    Component: PageAdminProposal,
    Icon: Proposal,
    text: "Proposal",
  },
  {
    path: "/oneascent-admin-portal/marketing",
    Component: PageMarketing,
    Icon: DocumentPage,
    text: "Marketing",
  },
  {
    path: "/oneascent-admin-portal/clients",
    Component: PageAdminClients,
    Icon: Users,
    text: "Clients",
  },
  {
    path: "/oneascent-admin-portal/prospects",
    Component: PageAdminProspects,
    Icon: Users,
    text: "Prospects",
  },
];
