const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <rect width="36" height="36" rx="18" fill="none" />
    <path
      d="M18.74,4.517A.889.889,0,1,0,17.483,3.26L11,9.743,4.517,3.26A.889.889,0,0,0,3.26,4.517L9.743,11,3.26,17.483A.889.889,0,1,0,4.517,18.74L11,12.257l6.483,6.483a.889.889,0,1,0,1.257-1.257L12.257,11Z"
      transform="translate(7 7)"
      fill="#1e1919"
      fillRule="evenodd"
    />
  </svg>
);

export default Close;
