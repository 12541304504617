import styled from "styled-components";
import { Slider } from "antd";

import slider_image from "../../images/slider_button.svg";

export const SliderContainer = styled.div`
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 40px;
`;

export const StyledSlider = styled(Slider)`
  z-index: 4;

  &.ant-slider-horizontal {
    margin: 0;
  }

  &.ant-slider-horizontal .ant-slider-rail,
  &.ant-slider .ant-slider-track {
    border-radius: 23px;
  }

  &.ant-slider .ant-slider-handle::before {
    background: url("${slider_image}") center;
    height: 70px;
    left: -25px;
    top: -21px;
    width: 70px;
  }

  &.ant-slider .ant-slider-handle::after {
    display: none;
  }
`;

export const RiskToleranceCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 13px;
  width: 170px;
  height: 263px;
  padding: 27px 15px;
  text-align: center;
  box-sizing: border-box;
  z-index: 4;

  & h4 {
    line-height: 20px;
    font-size: 22px;
    color: #ffffff;
    margin: 0;
  }

  & p {
    line-height: 16px;
    font-size: 13px;
    color: #ffffff;
    margin: 0;
  }

  &.disabled {
    background: #eaeaea;
  }

  &.disabled h4,
  &.disabled p {
    color: #575757;
  }
`;
