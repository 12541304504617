import { CognitoUser } from "amazon-cognito-identity-js";
import userPool from "./userPool";

const forgotPassword = username =>
  new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: err => {
        reject(err);
      },
    });
  });

export default forgotPassword;
