import styled from "styled-components";
import { Modal } from "antd";

const StylesContainer = styled(Modal)`
  &.ant-modal {
    .ant-modal-header {
      margin-bottom: 30px;

      .ant-modal-title {
        color: #000;
        font-family: Roboto, sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    .ant-modal-content {
      padding: 33px 30px;

      .ant-form-item {
        margin: 0 0 10px;

        .ant-form-item-label {
          padding-bottom: 0;

          > label {
            color: #000;
          }
        }
      }
    }

    .ant-modal-footer {
      margin-top: 30px;
    }
  }

  .label-copy-survey-link {
    color: #1c5d9a;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: #ecf5f5;
    border-radius: 8px;
    color: #1a1a1a;
    font-family: Roboto, sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 10px 16px;
  }

  .ant-btn.ant-btn-lg {
    font-size: 14px;
  }
`;

export default StylesContainer;
