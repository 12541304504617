import { useEffect, useState } from "react";
import { Button, InputNumber, Row, Space, theme } from "antd";
import InputContainer from "../../atom/InputContainer";

import StyledModal from "./styles";
import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../utils/helper/general";

import Close from "../../icon/Close";

const defaultState = {
  proposals: 0,
  clientConversion: 0,
  newAUM: 0,
};

const ModalQuarterlyGoals = ({
  open,
  onClose,
  handleUpdateQuarterlyGoals,
  initialState,
  loading,
}) => {
  const { token } = theme.useToken();
  const [updatedQuarterlyGoalsData, setUpdatedQuarterlyGoalsData] =
    useState(defaultState);

  useEffect(() => {
    setUpdatedQuarterlyGoalsData(initialState ?? defaultState);
  }, [open]);

  const handleValueChange = (key, value) =>
    setUpdatedQuarterlyGoalsData(lastState => ({
      ...lastState,
      [key]: value,
    }));

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Row>
          <Button
            type={"primary"}
            shape={"round"}
            onClick={() =>
              handleUpdateQuarterlyGoals(updatedQuarterlyGoalsData)
            }
            disabled={loading}
          >
            Update My Goals
          </Button>
          <Button type={"text"} shape={"round"} onClick={onClose}>
            Cancel
          </Button>
        </Row>
      }
      onCancel={onClose}
      open={open}
      title={"Edit My Quarterly Goals"}
      width={354}
    >
      <Space direction={"vertical"} size={20} style={{ width: "100%" }}>
        <InputContainer labelText={"Proposals"} labelColor={token.colorGrey1}>
          <InputNumber
            value={updatedQuarterlyGoalsData.proposals}
            onChange={value => handleValueChange("proposals", value)}
            onClick={selectValue}
          />
        </InputContainer>
        <InputContainer
          labelText={"Client Conversions"}
          labelColor={token.colorGrey1}
        >
          <InputNumber
            value={updatedQuarterlyGoalsData.clientConversion}
            onChange={value => handleValueChange("clientConversion", value)}
            onClick={selectValue}
          />
        </InputContainer>
        <InputContainer labelText={"New AUM"} labelColor={token.colorGrey1}>
          <InputNumber
            value={updatedQuarterlyGoalsData.newAUM}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onChange={value => handleValueChange("newAUM", value)}
            onClick={selectValue}
          />
        </InputContainer>
      </Space>
    </StyledModal>
  );
};

export default ModalQuarterlyGoals;
