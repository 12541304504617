export const CHANNEL_WITH_FILTERS_ID = 108481;

export const DEFAULT_PASSWORD = "Abcd1234";

export const MAX_FEATURED_EVENTS_AMOUNT = 3;

export const MAX_FEATURED_SPONSORS_AMOUNT = 6;

export const NEWS_CHANNEL_INSIGHT_ID = 106586;

export const NEWS_CHANNEL_MEMBER_ID = 107688;
