import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .section-title {
    line-height: 32px;
    font-weight: 400;
    font-size: 18px;
    color: #8396a6;
    margin: 0;
  }

  & .ant-divider-horizontal {
    border-top: 3px solid #e7ebee;
    margin: 10px 0 20px;
  }
`;

export default StylesContainer;
