import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #fff;
  border-radius: 12px;
  padding: 24px;

  h3 {
    color: #253856;
    font-size: 22px;
    margin: 0;
  }

  p {
    color: #253856;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
`;

export default StylesContainer;
