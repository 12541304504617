const Lock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M17.875 9.96875H16.4141V5.15625C16.4141 3.6373 15.183 2.40625 13.6641 2.40625H8.33594C6.81699 2.40625 5.58594 3.6373 5.58594 5.15625V9.96875H4.125C3.74473 9.96875 3.4375 10.276 3.4375 10.6562V18.9062C3.4375 19.2865 3.74473 19.5938 4.125 19.5938H17.875C18.2553 19.5938 18.5625 19.2865 18.5625 18.9062V10.6562C18.5625 10.276 18.2553 9.96875 17.875 9.96875ZM7.13281 5.15625C7.13281 4.49238 7.67207 3.95312 8.33594 3.95312H13.6641C14.3279 3.95312 14.8672 4.49238 14.8672 5.15625V9.96875H7.13281V5.15625ZM17.0156 18.0469H4.98438V11.5156H17.0156V18.0469ZM10.3984 15.0605V16.1992C10.3984 16.2937 10.4758 16.3711 10.5703 16.3711H11.4297C11.5242 16.3711 11.6016 16.2937 11.6016 16.1992V15.0605C11.7789 14.9332 11.9113 14.7529 11.9796 14.5456C12.048 14.3382 12.0488 14.1145 11.9819 13.9067C11.915 13.6989 11.7839 13.5176 11.6075 13.3891C11.431 13.2605 11.2183 13.1912 11 13.1912C10.7817 13.1912 10.569 13.2605 10.3925 13.3891C10.2161 13.5176 10.085 13.6989 10.0181 13.9067C9.95124 14.1145 9.95203 14.3382 10.0204 14.5456C10.0887 14.7529 10.2211 14.9332 10.3984 15.0605Z"
      fill="currentColor"
    />
  </svg>
);

export default Lock;
