import { Button, Flex } from "antd";
import TableMilestones from "../SectionMilestonesQuestionnaire/components/TableMilestones";
import {
  BoldItalicUnderlineToggles,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";

import StyledModal from "./styles";

import { isAdminView } from "../../../../../../utils/helper/specialized";

const ModalMilestones = ({
  handleMilestonesEdit,
  milestonesQuestions,
  milestonesNote,
  onCancel,
  open,
}) => (
  <StyledModal
    closeIcon={false}
    footer={
      <Flex justify={"space-between"}>
        <Button onClick={onCancel} shape={"round"} size={"large"}>
          Close
        </Button>
        <Button
          disabled={isAdminView()}
          onClick={handleMilestonesEdit}
          shape={"round"}
          size={"large"}
          style={{ background: "#428916" }}
          type={"primary"}
        >
          Edit Milestones
        </Button>
      </Flex>
    }
    onCancel={onCancel}
    open={open}
    title={"Milestones"}
    width={1061}
  >
    <p className={"description"}>
      Context is key. Select the life events that apply to your current
      situation. You and your advisor will keep these Milestones in mind as you
      form a financial plan.
    </p>
    <Flex gap={32}>
      <Flex gap={28} style={{ width: "100%" }} vertical>
        <Flex vertical>
          <h4>Marriage & Career</h4>
          <TableMilestones
            data={milestonesQuestions?.filter(
              it => (it.type === "marriage" || it.type === "career") && it.value
            )}
            readonly
          />
        </Flex>
        <Flex vertical>
          <h4>Health & Family</h4>
          <TableMilestones
            data={milestonesQuestions?.filter(
              it => (it.type === "health" || it.type === "family") && it.value
            )}
            hideTitle
            readonly
          />
        </Flex>
        <Flex vertical>
          <h4>Assets, Liabilities & Generosity</h4>
          <TableMilestones
            data={milestonesQuestions?.filter(
              it =>
                (it.type === "assets" || it.type === "generosity") && it.value
            )}
            hideTitle
            readonly
          />
        </Flex>
      </Flex>
      <Flex vertical>
        <div className={"sub-title"}>Notes</div>
        <MDXEditor
          className={"markdown-container"}
          markdown={milestonesNote ?? ""}
          plugins={[
            listsPlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                  <ListsToggle />
                </>
              ),
            }),
          ]}
        />
      </Flex>
    </Flex>
  </StyledModal>
);

export default ModalMilestones;
