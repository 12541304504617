import styled from "styled-components";

const StylesContainer = styled.div`
  box-sizing: border-box;
  color: #052c2b;
  font-size: 16px;
  height: 1590px;
  line-height: 26px;
  padding: 71px 80px;
  position: relative;
  width: 1230px;

  main {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 26px;
  }

  h4 {
    border-bottom: 1px solid #bec8ce;
    color: #234744;
    font-size: 26px;
    font-weight: 300;
    margin: 0;
    padding: 10px 0;
  }

  h5 {
    color: #2a2828;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0 0 9px;
  }

  .value-container {
    display: flex;
    gap: 8px;
  }
`;

export default StylesContainer;
