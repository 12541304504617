import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    margin: 0;
    position: relative;
    padding: 71px 80px 20px 78px;
    width: 1230px;
  }

  h2 {
    color: #0a3b66;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 48px 0 0;
  }

  h4 {
    color: #0a3b66;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.46px;
    margin: 46px 0 10px;
  }

  p {
    color: #2a2828;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    margin: 0 0 34px;
  }

  .selections-container {
    background: #f0f9f9;
    border-radius: 12px;
    display: flex;
    gap: 76px;
    padding: 24px 32px;
    width: fit-content;

    .value-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .label {
        color: #2a2a2a;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.36px;
      }

      .value {
        color: #2a2a2a;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: -0.4px;
        margin: 0;
      }
    }
  }
`;

export default StylesContainer;
