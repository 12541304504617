const BoxOpen = () => (
  <svg
    fill="currentColor"
    width="19.876"
    height="13.913"
    viewBox="0 0 19.876 13.913"
  >
    <path
      d="M13.218,9.2a1.505,1.505,0,0,1-1.286-.727l-2-3.31L7.942,8.478a1.51,1.51,0,0,1-1.289.73,1.428,1.428,0,0,1-.413-.059L1.986,7.931v5.528a.991.991,0,0,0,.752.963L9.451,16.1a2.019,2.019,0,0,0,.963,0l6.72-1.68a1,1,0,0,0,.752-.963V7.931L13.631,9.146A1.428,1.428,0,0,1,13.218,9.2Zm6.6-3.484-1.6-3.192a.507.507,0,0,0-.519-.276l-7.767.991,2.848,4.723a.51.51,0,0,0,.575.227L19.5,6.438a.511.511,0,0,0,.317-.717ZM1.65,2.528.051,5.72a.506.506,0,0,0,.314.714L6.51,8.189a.51.51,0,0,0,.575-.227l2.851-4.72-7.77-.991A.507.507,0,0,0,1.65,2.528Z"
      transform="translate(0.003 -2.247)"
    />
  </svg>
);

export default BoxOpen;
