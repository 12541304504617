import { Image } from "antd";

import logo from "../../images/logo_main.png";

const ManagerHeader = () => (
  <header
    style={{
      padding: "32px 32px 19px 46px",
      borderBottom: "1px solid #C6C6C6",
    }}
  >
    <Image preview={false} src={logo} width={231} />
  </header>
);

export default ManagerHeader;
