import { Button } from "antd";

import StylesContainer from "./styles";

const CompareCardPlaceholder = ({ handleClick }) => (
  <StylesContainer align={"center"} justify={"center"} onClick={handleClick}>
    <Button shape={"round"} style={{ height: 36 }}>
      Add Product to Compare
    </Button>
  </StylesContainer>
);

export default CompareCardPlaceholder;
