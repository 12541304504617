import { useContext } from "react";
import State from "../../../../context/admin/State";
import { Button, Flex } from "antd";
import ModalPrimary from "../ModalPrimary";

import { deleteSponsor } from "../../../../requests/global";

const ModalDeleteSponsor = ({ open }) => {
  const [state] = useContext(State);

  const handleClose = () => {
    state.closeModal("deleteSponsorModal");
  };

  const handleDeleteUser = () => {
    state.activeSponsorId &&
      deleteSponsor(state.activeSponsorId).then(() =>
        state.reFetch.sponsors().then(() => {
          handleClose();
          state.showSuccess("Sponsor deleted");
          state.setKeyValue("activeSponsorId", null);
        })
      );
  };

  return (
    <ModalPrimary
      footer={
        <Flex justify={"space-between"}>
          <Button
            onClick={handleClose}
            shape={"round"}
            size={"large"}
            type={"text"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            shape={"round"}
            size={"large"}
            style={{ width: 206, background: "#001141", color: "#FFFFFF" }}
          >
            Delete
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={"Delete Sponsor"}
      width={492}
    >
      <span>Are you sure you want to delete this sponsor?</span>
    </ModalPrimary>
  );
};

export default ModalDeleteSponsor;
