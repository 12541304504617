const IconGoals = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5547 2.75781L18.2344 3.92578C18.1836 3.97656 18.1328 4.02734 18.082 4.07812C17.4219 3.97656 16.7109 3.875 16 3.875C9.70312 3.875 4.625 9.00391 4.625 15.25C4.625 21.5469 9.70312 26.625 15.9492 26.625C22.2461 26.625 27.3242 21.5469 27.3242 15.25C27.3242 14.5391 27.2734 13.8281 27.1719 13.168C27.2227 13.1172 27.2734 13.0664 27.3242 13.0156L28.4922 11.6953C28.7969 12.8125 29 14.0312 29 15.25C29 22.4609 23.1602 28.25 16 28.25C8.78906 28.25 3 22.4609 3 15.25C3 8.08984 8.78906 2.25 16 2.25C17.2188 2.25 18.4375 2.45312 19.5547 2.75781ZM16.9141 7.22656C16.9141 7.32812 16.9648 7.48047 16.9648 7.63281L17.168 8.90234C16.8125 8.80078 16.4062 8.75 16 8.75C12.3945 8.75 9.5 11.6953 9.5 15.25C9.5 18.8555 12.3945 21.75 16 21.75C19.5547 21.75 22.5 18.8555 22.5 15.25C22.5 14.8438 22.4492 14.4375 22.3477 14.082L23.6172 14.2852C23.7695 14.2852 23.9219 14.3359 24.0234 14.3359C24.0742 14.6406 24.125 14.9453 24.125 15.25C24.125 19.7695 20.4688 23.375 16 23.375C11.4805 23.375 7.875 19.7695 7.875 15.25C7.875 10.7812 11.4805 7.125 16 7.125C16.3047 7.125 16.6094 7.17578 16.9141 7.22656ZM20.3164 12.1016L16.5586 15.8594C16.2539 16.1641 15.6953 16.1641 15.3906 15.8594C15.0859 15.5547 15.0859 14.9961 15.3906 14.6914L19.1484 10.9336L18.5898 7.37891C18.4375 6.51562 18.7422 5.70312 19.3516 5.14453L21.8906 2.91016C22.5508 2.30078 23.5664 2.60547 23.8203 3.46875L24.7344 6.51562L27.7812 7.42969C28.6445 7.68359 28.9492 8.69922 28.3398 9.35938L26.1055 11.8984C25.5469 12.5078 24.7344 12.8125 23.8711 12.6602L20.3164 12.1016ZM21.7383 10.6797L24.1758 11.0859C24.4297 11.1367 24.7344 11.0352 24.8867 10.832L26.7148 8.80078L24.3281 8.08984L21.7383 10.6797ZM23.1602 6.92188L22.4492 4.53516L20.418 6.36328C20.2148 6.51562 20.1133 6.82031 20.1641 7.07422L20.5703 9.51172L23.1602 6.92188Z"
      fill="currentColor"
    />
  </svg>
);

export default IconGoals;
