import styled from "styled-components";
import { Flex, Radio } from "antd";

export const DataContainer = styled(Flex)`
  min-height: 322px;
  padding: 2px 2px 0 40px;
  box-sizing: border-box;
  overflow: auto;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;

  .ant-radio-button-wrapper {
    height: 100%;
    background: #ffffff;
    color: #292828;
    padding: 3px 16px;
    border-color: transparent;
    border-radius: 25px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #292828;
    color: #ffffff;
    border-color: transparent;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;
