import styled from "styled-components";

export const StylesContainer = styled.div`
  main {
    max-width: 1300px;
    min-height: 100%;
    padding: 32px;
  }

  .section-ask-advice {
    background: #ffffff;
    border-radius: 12px;
    height: 779px;
    padding: 24px;
  }

  .section-video {
    background: transparent linear-gradient(179deg, #ffffff 0%, #e7f7fd 100%);
    border-radius: 12px;
    padding: 24px;

    .title {
      line-height: 28px;
      font-size: 22px;
      color: #253856;
      margin: 0 0 8px;
    }

    .description {
      line-height: 22px;
      font-size: 16px;
      color: #253856;
      margin: 0 0 13px;
    }

    iframe {
      border-radius: 12px;
    }
  }
`;

export default StylesContainer;
