import styled from "styled-components";
import { Table } from "antd";

const StyledTable = styled(Table)`
  &.ant-table-wrapper .ant-table-tbody {
    .row-folder {
      background: #6dc1c710;

      .cell-name {
        color: ${({ token }) => token.colorUiText};
        font-weight: 700;
      }
    }
`;

export default StyledTable;
