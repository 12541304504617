export default {
  height: 175,
  width: 238,
  xField: "name",
  yField: "percent",
  radius: 0.8,
  isPercent: true,
  innerRadius: 0.2,
  tooltip: {
    formatter: data => {
      return {
        name: data.name,
        value: data.percent + "%",
      };
    },
  },
  showMarkers: false,
  colorField: "color",
  color: ({ color }) => {
    return color;
  },
  barBackground: {},
  barStyle: {
    lineCap: "round",
  },
  xAxis: false,
};
