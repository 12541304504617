import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  height: 100%;
  overflow: auto;

  header {
    background: #0b2a46;
    box-sizing: border-box;
    height: 280px;
    position: relative;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      border-top: 20px solid #2165b1;
      border-bottom: 20px solid #32a2de;
      bottom: 0;
      width: 100%;
    }

    .content {
      color: #fff;
      padding: 42px 50px;
    }

    .title {
      font-size: 48px;
      font-weight: 600;
      line-height: normal;
      margin: 25px 0 0;
    }

    .subtitle {
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      margin: 25px 0 12px;
    }

    .description {
      font-size: 16px;
      line-height: normal;
      margin: 0;
      max-width: 623px;
      text-align: center;
    }
  }

  @media screen and (max-width: ${({ token }) => token.screenMD + "px"}) {
    header {
      height: 220px;

      .content {
        padding: 12px;
      }

      .title {
        font-size: 24px;
      }

      .description {
        font-size: 14px;
      }
    }
  }
`;

export default StylesContainer;
