import styled from "styled-components";

const StylesContainer = styled.div`
  header {
    margin-bottom: 40px;
    text-align: center;

    .title {
      color: #000;
      font-size: 26px;
      font-weight: 800;
      margin: 0 0 8px;
    }

    .description {
      color: #1e1919;
      font-size: 14px;
      font-weight: 500;
      margin: 0 30px;
    }
  }

  textarea.ant-input {
    border-color: #999;
  }

  .ant-checkbox-wrapper {
    color: #1e1919;
    gap: 20px;
    padding: 0 20px;

    &::after {
      display: none;
    }

    .ant-checkbox {
      align-self: start;

      .ant-checkbox-inner {
        height: 25px;
        width: 25px;

        &::after {
          height: 14px;
          top: 45%;
          width: 9px;
        }
      }

      span {
        padding: 0;
      }
    }
  }

  footer {
    margin-top: 40px;
  }
`;

export default StylesContainer;
