import axios from "axios";
import Cookies from "js-cookie";

export const addEvent = payload =>
  axios
    .post(`${process.env.REACT_APP_MMI_API_DOMAIN}/events`, payload)
    .then(({ data }) => data);

export const addCompany = payload =>
  axios
    .post(`${process.env.REACT_APP_MMI_API_DOMAIN}/companies`, payload)
    .then(({ data }) => data);

export const addSponsor = payload =>
  axios
    .post(`${process.env.REACT_APP_MMI_API_DOMAIN}/sponsors`, payload)
    .then(({ data }) => data);

export const addUser = payload =>
  axios
    .post(`${process.env.REACT_APP_MMI_API_DOMAIN}/users`, payload)
    .then(({ data }) => data);

export const deleteCompany = companyId =>
  axios.delete(
    `${process.env.REACT_APP_MMI_API_DOMAIN}/companies/${companyId}`
  );

export const deleteEvent = eventId =>
  axios.delete(`${process.env.REACT_APP_MMI_API_DOMAIN}/events/${eventId}`);

export const deleteSponsor = sponsorId =>
  axios.delete(`${process.env.REACT_APP_MMI_API_DOMAIN}/sponsors/${sponsorId}`);

export const deleteUser = userId =>
  axios.delete(`${process.env.REACT_APP_MMI_API_DOMAIN}/users/${userId}`);

export const getAdminDataByEmail = email =>
  axios
    .get(
      `${
        process.env.REACT_APP_MMI_API_DOMAIN
      }/users/getbyemail/admin/${encodeURIComponent(email)}`
    )
    .then(({ data }) => data[0]);

export const getCompanies = () =>
  axios
    .get(`${process.env.REACT_APP_MMI_API_DOMAIN}/companies`)
    .then(({ data }) => data);

export const getEvents = () =>
  axios
    .get(`${process.env.REACT_APP_MMI_API_DOMAIN}/events`)
    .then(({ data }) => data);

export const getLeagues = () =>
  axios
    .get(`${process.env.REACT_APP_MMI_API_DOMAIN}/leagues`)
    .then(({ data }) => data);

export const getSponsors = () =>
  axios
    .get(`${process.env.REACT_APP_MMI_API_DOMAIN}/sponsors`)
    .then(({ data }) => data);

export const getUserEmailByJwt = () =>
  axios
    .get(`${process.env.REACT_APP_MMI_API_DOMAIN}/auth`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("mmi-token")}`,
        "Content-Type": "application/json",
      },
    })
    .then(({ data }) => data.payload.email);

export const getUserDataByEmail = email =>
  axios
    .get(
      `${
        process.env.REACT_APP_MMI_API_DOMAIN
      }/users/getbyemail/${encodeURIComponent(email)}`
    )
    .then(({ data }) => data);

export const getUsers = () =>
  axios
    .get(`${process.env.REACT_APP_MMI_API_DOMAIN}/users`)
    .then(({ data }) => data);

export const updateCompany = ({ companyId, payloadData }) =>
  axios.patch(
    `${process.env.REACT_APP_MMI_API_DOMAIN}/companies/${companyId}`,
    payloadData
  );

export const updateEvent = ({ eventId, payloadData }) =>
  axios.patch(
    `${process.env.REACT_APP_MMI_API_DOMAIN}/events/${eventId}`,
    payloadData
  );

export const updateSponsor = ({ sponsorId, payloadData }) =>
  axios.patch(
    `${process.env.REACT_APP_MMI_API_DOMAIN}/sponsors/${sponsorId}`,
    payloadData
  );

export const updateUser = ({ userId, payloadData }) =>
  axios.patch(
    `${process.env.REACT_APP_MMI_API_DOMAIN}/users/${userId}`,
    payloadData
  );

export const updateUserEmail = ({ userId, newEmail }) =>
  axios
    .put(
      `${process.env.REACT_APP_MMI_API_DOMAIN}/users/updateEmail/${userId}`,
      {
        email: newEmail,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(({ data }) => data);

export const uploadFile = ({ file, folderName }) =>
  axios
    .post(
      `${process.env.REACT_APP_MMI_API_STORAGE_DOMAIN}/storage/${folderName}`,
      {
        file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(({ data }) => data[0]);
