import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Flex, theme } from "antd";
import ChartComparisonGrowth from "../../../../../molecule/ChartComparisonGrowth";
import ChartIncomeWithTypes from "../../../../../molecule/ChartIncomeWithTypes";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { getLiveAssessment } from "../../../../../utils/request/regularApp";
import {
  getCurrencyValue,
  getPercentValue,
} from "../../../../../utils/helper/general";

import { ReactComponent as IncomeChartLegend } from "./image/income_chart_legend.svg";
import { ReactComponent as IncomeCheckmark } from "./image/income_checkmark.svg";
import { ReactComponent as ElevateEliminate } from "./image/elevate_eliminate.svg";

const ReportPageProposalIncome = ({
  personaName,
  productsList,
  proposalData,
  setLoading,
}) => {
  const { token } = theme.useToken();
  const [productsLiveAssessment, setProductsLiveAssessment] = useState();

  useEffect(() => {
    if (!proposalData) return;

    setLoading(true);

    getLiveAssessment({
      organization: "OneAscent",
      objective: {
        ...proposalData,
        advisorFee: getPercentValue(proposalData.advisorFee),
        birthYear: proposalData.currentAge ? undefined : proposalData.birthYear,
        withdrawalLevel: getPercentValue(proposalData.withdrawalLevel ?? 0),
      },
      productId: proposalData.productId,
    }).then(liveAssessment => {
      setProductsLiveAssessment([liveAssessment]);

      // leave time for charts rendering
      setTimeout(() => setLoading(false), 1000);
    });
  }, [proposalData]);

  return (
    <StylesContainer id={"reportPageProposalIncome"}>
      <Flex className={"page-container"} vertical>
        <ReportHeader personaName={personaName} />

        <h2>
          What we talked about: <b>Income</b>
        </h2>
        <p>
          We all have different investment goals and objectives from maximizing
          retirement income, to funding a career change or to simply leave a
          financial legacy for the next generation. You have chosen to drive
          growth in your investment account. Growth investors seek to build
          long-term appreciation of capital without a need to withdraw from the
          account over time. Let’s drive growth differently.
        </p>

        <IncomeCheckmark />
        <Flex gap={60}>
          <div style={{ width: 750 }}>
            {productsLiveAssessment && (
              <ChartComparisonGrowth
                height={300}
                productsList={productsList}
                productsLiveAssessment={productsLiveAssessment}
                proposalData={proposalData}
              />
            )}
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                backgroundColor: "transparent",
                height: 300,
                spacingTop: 0,
                type: "pie",
                width: 245,
              },
              tooltip: {
                outside: true,
                style: {
                  zIndex: 9999,
                },
              },
              title: {
                text: null,
              },
              credits: {
                enabled: false,
              },
              plotOptions: {
                pie: {
                  size: 158,
                  innerSize: "60%",
                  dataLabels: {
                    enabled: false,
                  },
                  showInLegend: true,
                  center: ["50%", "50%"],
                  borderColor: "#ffffff",
                  borderWidth: 2,
                },
              },
              legend: {
                enabled: true,
                layout: "vertical",
                align: "center",
                verticalAlign: "bottom",
                borderWidth: 0,
                labelFormatter: function () {
                  return `<div style="width:245px; white-space: normal;">${
                    this.y + "% " + this.name
                  }</div>`;
                },
                itemStyle: {
                  fontSize: 14,
                },
              },
              exporting: {
                buttons: {
                  contextButton: {
                    enabled: false,
                  },
                },
              },
              colors: token.chartColors,
              series: [
                {
                  colorByPoint: true,
                  data: productsList
                    .find(product => product.name === proposalData?.productName)
                    ?.assets?.map(it => ({
                      name: it.assetName,
                      y: it.investmentPercent,
                    })),
                  name: "Selected",
                  size: "100%",
                },
              ],
            }}
          />
        </Flex>

        <h4>Your Selections</h4>
        <div className={"selections-container"} style={{ marginBottom: 50 }}>
          <div className={"value-container"}>
            <span className={"label"}>Investment Objective</span>
            <p className={"value"} style={{ textTransform: "capitalize" }}>
              {proposalData?.investmentObjective}
            </p>
          </div>
          <div className={"value-container"}>
            <span className={"label"}>Length of Investment</span>
            <p className={"value"}>
              {proposalData?.investmentDuration} year(s)
            </p>
          </div>
          <div className={"value-container"}>
            <span className={"label"}>Risk Tolerance</span>
            <p className={"value"} style={{ textTransform: "capitalize" }}>
              {proposalData?.riskTolerance}
            </p>
          </div>
          <div className={"value-container"}>
            <span className={"label"}>Initial Investment</span>
            <p className={"value"}>
              {getCurrencyValue(proposalData?.investmentAmount)}
            </p>
          </div>
          <div className={"value-container"}>
            <span className={"label"}>Advisor Fee</span>
            <p className={"value"}>
              {getPercentValue(proposalData?.advisorFee)}
            </p>
          </div>
        </div>

        <Flex gap={90} justify={"space-between"}>
          <Flex flex={"706px"} justify={"space-between"} vertical>
            {productsLiveAssessment && (
              <ChartIncomeWithTypes
                height={320}
                hideChartTypeButtons
                loading={false}
                productData={proposalData}
                productsList={productsList}
                productsLiveAssessment={productsLiveAssessment}
                showLegend={false}
              />
            )}
            <div className={"selections-container"}>
              <div className={"value-container"}>
                <span className={"label"}>Annual Income</span>
                <p className={"value"}>
                  {productsLiveAssessment?.length
                    ? getCurrencyValue(
                        productsLiveAssessment[0].incomeBarchart?.withdrawalSize
                      )
                    : ""}
                </p>
              </div>
              <div className={"value-container"}>
                <span className={"label"}>Withdrawal Rate</span>
                <p className={"value"}>
                  {getPercentValue(proposalData?.advisorFee)}
                </p>
              </div>
            </div>
          </Flex>

          <Flex gap={143} justify={"space-between"} vertical>
            <IncomeChartLegend />
            <ElevateEliminate />
          </Flex>
        </Flex>
      </Flex>
    </StylesContainer>
  );
};

export default ReportPageProposalIncome;
