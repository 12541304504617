import { useState } from "react";
import { Button, Flex, Upload } from "antd";

import StyledModal from "./styles";

import { importCSVManagedUserTemplate } from "../../../../utils/request/manager";

import Close from "../../../../icon/Close";
import { ReactComponent as UploadIcon } from "./images/upload.svg";
import { ReactComponent as DownloadIcon } from "./images/download.svg";

const ModalImportManagedUser = ({
  instanceLabel,
  loading,
  onCancel,
  onFinish,
  open,
}) => {
  const [file, setFile] = useState();

  const handleFileChange = event => {
    if (event.fileList?.length) {
      setFile(event.fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            disabled={!file || loading}
            onClick={() => onFinish(file)}
            shape={"round"}
            type={"primary"}
          >
            Done
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={`Import ${instanceLabel}s`}
      width={561}
    >
      <Flex gap={37} vertical>
        <Flex align={"center"} gap={25}>
          <div className="step-marker">1</div>
          <Button
            block
            icon={<DownloadIcon />}
            onClick={() => importCSVManagedUserTemplate({ instanceLabel })}
            shape={"round"}
            size={"large"}
            style={{
              background: "#4F8083",
              color: "#FFFFFF",
              textTransform: "capitalize",
            }}
          >
            Download {instanceLabel}s Template
          </Button>
        </Flex>
        <Flex gap={25}>
          <div className="step-marker">2</div>
          <Flex vertical>
            <h5 style={{ fontSize: 16, color: "#0D1238", margin: "0 0 10px" }}>
              Populate the template with your data
            </h5>
            <p style={{ color: "#707070", margin: 0 }}>
              If you don’t have data for certain columns, just leave them blank.
              Do not delete or rename columns.
            </p>
          </Flex>
        </Flex>
        <Flex gap={25}>
          <div className="step-marker">3</div>
          <Upload.Dragger
            name={"file"}
            maxCount={1}
            beforeUpload={() => false}
            onChange={handleFileChange}
          >
            <UploadIcon />
            <h5 className={"title"}>
              Upload your {instanceLabel}s (.xls, .xlsx, .csv)
            </h5>
            <p className={"description"}>
              Drag here or <u>browse</u>
            </p>
          </Upload.Dragger>
        </Flex>
      </Flex>
    </StyledModal>
  );
};

export default ModalImportManagedUser;
