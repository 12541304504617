import { useContext, useMemo } from "react";
import State from "../../../../context";
import { ConfigProvider, Flex, Tabs, theme } from "antd";
import RecentClientCard from "./components/RecentClientCard";
import RecentLeadCard from "./components/RecentLeadCard";
import RecentProposalCard from "./components/RecentProposalCard";

import StylesContainer from "./styles";

import {
  getLatestSavedProposalProps,
  isManagerAccessNextOnly,
  isOrganizationOneascent,
} from "../../../../utils/helper/specialized";

const RecentUsersSection = ({ hideClients, showLeads }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const isOneascent = isOrganizationOneascent(
    state.orgName ?? state.organization?.name
  );

  const getDefaultActiveTab = () => {
    if (hideClients) {
      return "leads";
    } else {
      return "clients";
    }
  };

  const clientsList = useMemo(
    () =>
      state.managedUsers
        ?.filter(it => {
          // saved proposal required
          if (it.valueMap?.productMap) {
            // isConverted is major client marker
            if (it.valueMap?.leadInitial?.isConverted) {
              return true;
            }

            if (!it.valueMap?.leadInitial) {
              if (
                it.userType === "ConvertedProspect" ||
                it.userType === "Registered"
              ) {
                return true;
              }
            }
          }

          return false;
        })
        ?.sort(
          (a, b) =>
            (getLatestSavedProposalProps(b.valueMap?.productMap)?.timestamp ??
              b.valueMap?.leadInitial?.createdAt) -
            (getLatestSavedProposalProps(a.valueMap?.productMap)?.timestamp ??
              a.valueMap?.leadInitial?.createdAt)
        ),
    [state.managedUsers]
  );

  const leadsList = useMemo(() => {
    if (!state.managedUsers) return [];

    if (isManagerAccessNextOnly(state.access)) {
      // assigned by admin lead
      return state.managedUsers
        ?.filter(
          it =>
            it.entryPoint === "addedByOrgManager" &&
            !it.valueMap?.leadInitial?.isConverted
        )
        ?.sort(
          (a, b) =>
            (getLatestSavedProposalProps(b.valueMap?.productMap)?.timestamp ??
              b.valueMap?.leadInitial?.createdAt) -
            (getLatestSavedProposalProps(a.valueMap?.productMap)?.timestamp ??
              a.valueMap?.leadInitial?.createdAt)
        );
    }

    if (state.access?.level && state.access?.next) {
      return state.managedUsers
        ?.filter(it => {
          // not converted user is major lead marker
          if (!it.valueMap?.leadInitial?.isConverted) {
            if (it.entryPoint === "addedByOrgManager") {
              // assigned lead proposal can't be saved, show as it is
              return true;
            }

            // saved proposal required before checking user type
            if (it.valueMap?.productMap) {
              if (it.userType === "Assigned" || it.userType === "Prospect") {
                return true;
              }
            }
          }

          return false;
        })
        ?.sort(
          (a, b) =>
            (getLatestSavedProposalProps(b.valueMap?.productMap)?.timestamp ??
              b.valueMap?.leadInitial?.createdAt) -
            (getLatestSavedProposalProps(a.valueMap?.productMap)?.timestamp ??
              a.valueMap?.leadInitial?.createdAt)
        );
    }

    return state.managedUsers
      ?.filter(
        it =>
          it.userType === "Affiliated" ||
          (it.userType === "Assigned" &&
            !it.valueMap?.leadInitial?.isConverted) ||
          it.userType === "Lead"
      )
      ?.sort(
        (a, b) =>
          (getLatestSavedProposalProps(b.valueMap?.productMap)?.timestamp ??
            b.valueMap?.leadInitial?.createdAt) -
          (getLatestSavedProposalProps(a.valueMap?.productMap)?.timestamp ??
            a.valueMap?.leadInitial?.createdAt)
      );
  }, [state.managedUsers]);

  const items = [
    {
      key: "clients",
      label: isOneascent ? "Recent Proposals" : "Recent Clients",
      children: (
        <Flex gap={15} vertical>
          {clientsList
            ?.slice(0, 3)
            ?.map((it, index) =>
              isOneascent ? (
                <RecentProposalCard
                  key={index}
                  organization={state.organization?.name ?? state.orgName}
                  userData={it}
                />
              ) : (
                <RecentClientCard
                  key={index}
                  organization={state.organization?.name ?? state.orgName}
                  userData={it}
                />
              )
            )}
        </Flex>
      ),
      hidden: hideClients,
    },
    {
      key: "leads",
      label: "Recent Leads",
      children: (
        <Flex gap={15} vertical>
          {leadsList?.slice(0, 3)?.map((it, index) => (
            <RecentLeadCard key={index} leadData={it} />
          ))}
        </Flex>
      ),
      hidden: !showLeads,
    },
  ].filter(it => !it.hidden);

  return (
    <StylesContainer gap={18} vertical>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: token.advisorDashboardPageActiveTabColor,
              lineWidthBold: 5,
            },
          },
        }}
      >
        <Tabs defaultActiveKey={getDefaultActiveTab()} items={items}></Tabs>
      </ConfigProvider>
    </StylesContainer>
  );
};

export default RecentUsersSection;
