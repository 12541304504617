export const CONVERSATION_COMPLEXITY_LIST = [
  "",
  "Beginner",
  "Intermediate",
  "Advanced",
];

export const CONVERSATION_RELEVANCE_LIST = ["", "Low", "Medium", "High"];

export const DEFAULT_TAGS_LIST = [
  "Retirement",
  "Strategy",
  "Income",
  "Education",
];
