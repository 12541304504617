import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, ConfigProvider, Flex, Input, Table, theme } from "antd";
import MenuActions from "../../../../atom/MenuActions";
import ModalArchiveManagedUser from "../../../../molecule/ModalArchiveManagedUser";
import ModalClientDetails from "../../components/ModalClientDetails";
import ModalConvertIntoLead from "../../components/ModalConvertIntoLead";
import ModalImportManagedUser from "../../components/ModalImportManagedUser";
import SecurityGate from "../../../../provider/SecurityGate";

import StylesContainer from "./styles";

import { DEFAULT_RETIREMENT_AGE } from "../../../../utils/constant";

import {
  addManagedUser,
  addManagedUserExpanded,
  archiveLead,
  importCSVManagedUser,
  markAsProspect,
  notifyManagedUser,
  openGoalsManagedUser,
  openManagedUser,
  openManagedUserAsThem,
  updateManagedUser,
} from "../../../../utils/request/manager";
import { getCurrencyValue } from "../../../../utils/helper/general";
import { isManagerAccessAffiliateOnly } from "../../../../utils/helper/specialized";

import IconPenny from "../../images/IconPenny";
import PlusOutlined from "../../../../icon/PlusOutlined";
import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as ImportIcon } from "../../images/import.svg";

const getColumns = ({
  handleActionArchive,
  handleActionConvert,
  handleClientNameClick,
  handleNotify,
  managerAccess,
  searchedText,
}) =>
  [
    {
      dataIndex: "firstName",
      filteredValue: [searchedText],
      key: "name",
      render: (value, restValues) => (
        <a
          className="pointer"
          onClick={() => handleClientNameClick(restValues)}
        >
          <b>
            {restValues.firstName} {restValues.lastName}
          </b>
        </a>
      ),
      onFilter: (value, record) =>
        String(record.firstName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.lastName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      title: "Client",
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={"mailto:" + value}>{value}</a>,
      title: "Email",
    },
    {
      align: "right",
      dataIndex: "valueMap",
      key: "assets",
      render: (valueMap, allValues) =>
        getCurrencyValue(
          allValues.lastLoginTime
            ? allValues.currentAssets
            : (valueMap?.leadInitial?.assets ??
                valueMap?.guideInitialData?.assets ??
                0)
        ),
      title: "Assets",
    },
    {
      dataIndex: "planningType",
      key: "planningType",
      render: (_, allValues) => (
        <span style={{ textTransform: "capitalize" }}>
          {allValues.valueMap?.leadInitial?.planningType ||
            allValues.planningType ||
            allValues.valueMap?.prospectObjective?.planningType ||
            "Proposal"}
        </span>
      ),
      title: "Planning Type",
    },
    {
      align: "right",
      dataIndex: "lastLoginTime",
      key: "updateDateTime",
      render: value =>
        value ? dayjs(value.$date).format("MM/DD/YYYY") : "Never",
      title: "Last Client Login",
    },
    {
      align: "right",
      dataIndex: "lastAdvisorLoginTime",
      key: "lastAccessedDate",
      render: value =>
        value ? dayjs(value.$date).format("MM/DD/YYYY") : "Never",
      title:
        managerAccess?.affiliate || managerAccess?.proportal
          ? "Last Advisor Login"
          : "Last Modified",
    },
    {
      align: "right",
      dataIndex: "status",
      hidden: isManagerAccessAffiliateOnly(managerAccess),
      key: "status",
      render: (value, restValues) => {
        if (
          (
            restValues.planningType ??
            restValues.valueMap?.leadInitial?.planningType
          )?.toLowerCase() === "goals"
        ) {
          return "N/A";
        } else {
          return value === "NotNotified" ? "Not Notified" : value;
        }
      },
      title: "Status",
    },
    {
      className: "actions-menu-table-cell",
      dataIndex: "actionsMenu",
      key: "actionsMenu",
      render: (value, restValues) => (
        <MenuActions
          items={
            (
              restValues.planningType ??
              restValues.valueMap?.leadInitial?.planningType
            )?.toLowerCase() === "goals"
              ? getGoalsActions({
                  email: restValues.email,
                  handleActionArchive,
                  handleActionConvert,
                  managerAccess,
                })
              : getCashflowActions({
                  email: restValues.email,
                  handleNotify,
                  handleActionArchive,
                  handleActionConvert,
                  managerAccess,
                })
          }
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

const getCashflowActions = ({
  email,
  handleNotify,
  handleActionArchive,
  handleActionConvert,
  managerAccess,
}) =>
  [
    {
      hidden:
        managerAccess?.affiliate &&
        !managerAccess?.next &&
        !managerAccess?.proportal,
      key: "open",
      label: "Open Client View",
      onClick: () => openManagedUserAsThem(email),
    },
    {
      hidden: !managerAccess?.affiliate && !managerAccess?.proportal,
      key: "move",
      label: "Move to Lead",
      onClick: () => handleActionConvert(email),
    },
    {
      hidden:
        managerAccess?.affiliate &&
        !managerAccess?.next &&
        !managerAccess?.proportal,
      key: "sendEmail",
      label: "Send Welcome Email",
      onClick: () => handleNotify(email),
    },
    {
      type: "divider",
    },
    {
      key: "delete",
      label: <span style={{ color: "red" }}>Archive</span>,
      onClick: () => handleActionArchive(email),
    },
  ].filter(it => !it.hidden);

const getGoalsActions = ({
  email,
  handleActionArchive,
  handleActionConvert,
  managerAccess,
}) =>
  [
    {
      hidden: !managerAccess?.affiliate && !managerAccess?.proportal,
      key: "move",
      label: "Move to Lead",
      onClick: () => handleActionConvert(email),
    },
    {
      key: "delete",
      label: <span style={{ color: "red" }}>Archive Client</span>,
      onClick: () => handleActionArchive(email),
    },
  ].filter(it => !it.hidden);

const PageNextClients = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const organization = state?.orgName ?? state?.organization?.name;

  const handleAddClientClick = () => {
    state.setKeyValue("activeEmail", null);
    state.setKeyValue("openModalClientDetails", true);
  };

  const handleArchiveClient = () => {
    state.closeModal("archiveManagedUserModal");
    state.setKeyValue("loading", true);

    archiveLead({
      email: state.activeEmail,
      userManagerEmail: state._id,
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.setKeyValue("activeEmail", null);
          state.showSuccess(`Client ${state.activeEmail} archived`);
        })
      )
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleActionArchive = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("archiveManagedUserModal");
  };

  const handleActionConvert = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("openModalConvertIntoLead");
  };

  const handleClientNameClick = clientData => {
    const clientEmail = clientData.email;

    if (clientData.metaData?.keyValue?.AffiliateUrl) {
      const clientPlanningType = clientData.planningType;

      clientPlanningType === "goals"
        ? openGoalsManagedUser(clientEmail)
        : openManagedUser(clientEmail);
    } else {
      state.setKeyValue("activeEmail", clientEmail);
      state.setKeyValue("openModalClientDetails", true);
    }
  };

  const handleImportClients = file => {
    importCSVManagedUser(file)
      .then(response => {
        if (typeof response?.data === "object") {
          Object.keys(response.data).map(key =>
            state.showWarning(`${key} ${response.data[key]}`)
          );
        }

        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.closeModal("importManagedUserModal");
          state.showSuccess("Clients list imported.");
        });
      })
      .catch(error => {
        state.setKeyValue("loading", false);
        state.showError(error.response?.data);
      });
  };

  const handleImportClientsClick = () => {
    state.openModal("importManagedUserModal");
  };

  const handleNotify = email => {
    state.setKeyValue("loading", true);

    notifyManagedUser(email)
      .then(() =>
        state.setAdminData().then(() => {
          state.showSuccess(`Welcome email to the ${email} sent`);
          state.setKeyValue("loading", false);
        })
      )
      .catch(error => {
        state.showError(error?.response?.data);
        state.setKeyValue("loading", false);
      });
  };

  const handleConvertIntoLead = () => {
    state.setKeyValue("loading", true);
    state.closeModal("openModalConvertIntoLead");

    markAsProspect(state.activeEmail)
      .then(() => {
        state.showSuccess("Client converted into Lead!");
        state.setAdminData();
      })
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const onManagedUserFormSubmit = formValues => {
    state.setKeyValue("loading", true);

    if (formValues.oldEmail) {
      // update data of existed user
      updateManagedUser(formValues)
        .then(() => {
          state.setAdminData().then(() => {
            state.setKeyValue("openModalClientDetails", false);
            state.showSuccess("Client data updated");
          });
        })
        .catch(console.log);
    } else {
      // create new user
      if (!formValues.planType || formValues.planType === "basic") {
        const requestBody = {
          doNotNotifyManagedUser: true,
          maritalStatus: "single",
          retirementAge: DEFAULT_RETIREMENT_AGE,
          scenarioName: "Scenario 1",
          startDate: formValues.startDate?.$d ?? new Date(),
          planningType: "Goals",
          ...formValues,
        };

        addManagedUser(requestBody)
          .then(response => {
            state.showSuccess(response.data);
            state.setKeyValue("openModalClientDetails", false);

            state.setAdminData(() => {
              state.setKeyValue("loading", false);
            });
          })
          .catch(error => {
            state.showWarning(error.response?.data || error.message);
            state.setKeyValue("loading", false);
          });
      } else {
        const requestBody = {
          doNotNotifyManagedUser: true,
          retirementAge: DEFAULT_RETIREMENT_AGE,
          scenarioName: "Scenario 1",
          ...formValues,
          startDate: formValues.startDate?.$d ?? new Date(),
          accounts: formValues.accounts,
          incomes: formValues.incomes,
          expenses: formValues.expenses,
        };

        addManagedUserExpanded(requestBody)
          .then(response => {
            state.showSuccess(response.data);
            state.setKeyValue("openModalClientDetails", false);

            state.setAdminData(() => {
              state.setKeyValue("loading", false);
            });
          })
          .catch(error => {
            state.showWarning(error.response?.data || error.message);
            state.setKeyValue("loading", false);
          });
      }
    }
  };

  return (
    <SecurityGate state={state}>
      <StylesContainer>
        <header>
          <h3>Clients</h3>

          <Button
            disabled={!state.access?.penny}
            icon={<IconPenny />}
            onClick={() => state.openModal("openModalAskAdvice")}
            shape="round"
            style={{ background: token.buttonAskPennyBackground }}
            type="primary"
          >
            <span>
              Ask<b>Penny</b>
            </span>
          </Button>
        </header>

        <main>
          <Flex justify="space-between" style={{ marginBottom: 20 }}>
            <Input
              style={{
                maxWidth: 228,
                height: 40,
                borderRadius: 25,
              }}
              placeholder="Search my clients..."
              onChange={event => setSearchedText(event.target.value)}
              suffix={<SearchOutlined />}
            />
            <Flex gap={20}>
              <Button
                icon={<ImportIcon />}
                onClick={handleImportClientsClick}
                shape="round"
                size="small"
                type="text"
              >
                Import Clients
              </Button>
              <Button
                icon={<PlusOutlined />}
                onClick={handleAddClientClick}
                shape="round"
                size="small"
                style={{ background: token.buttonAddUserBackground }}
                type="primary"
              >
                Add New Client
              </Button>
            </Flex>
          </Flex>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerColor: "#6C6C6C",
                  colorText: "#516474",
                },
              },
            }}
          >
            <Table
              columns={getColumns({
                handleActionArchive,
                handleNotify,
                handleClientNameClick,
                handleActionConvert,
                managerAccess: state.access,
                searchedText,
              })}
              dataSource={state.managedUsers
                ?.filter(
                  it =>
                    it.userType === "Registered" ||
                    it.valueMap?.leadInitial?.isConverted
                )
                ?.map((it, index) => ({
                  ...it,
                  key: index,
                }))}
              loading={state.loading}
              pagination={false}
              rowHoverable={false}
              scroll={{
                x: 1250,
              }}
            />
          </ConfigProvider>
        </main>

        <ModalArchiveManagedUser
          loading={state.loading}
          onCancel={() => state.closeModal("archiveManagedUserModal")}
          onConfirm={handleArchiveClient}
          open={state.archiveManagedUserModal}
        />
        <ModalConvertIntoLead
          email={state.activeEmail}
          onCancel={() => state.closeModal("openModalConvertIntoLead")}
          onSuccess={handleConvertIntoLead}
          open={state.openModalConvertIntoLead}
        />
        <ModalImportManagedUser
          instanceLabel="client"
          loading={state.loading}
          onCancel={() => state.closeModal("importManagedUserModal")}
          onFinish={handleImportClients}
          open={state.importManagedUserModal}
        />
        <ModalClientDetails
          activeUserData={state.managedUsers?.find(
            it => it.email === state.activeEmail
          )}
          loading={state.loading}
          managerAccess={state.access}
          onCancel={() => state.setKeyValue("openModalClientDetails", false)}
          onFinish={onManagedUserFormSubmit}
          open={state.openModalClientDetails}
        />
      </StylesContainer>
    </SecurityGate>
  );
};

export default PageNextClients;
