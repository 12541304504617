import styled from "styled-components";
import { Flex } from "antd";

const StylesProvider = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .statistic-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 16px #0000000d;
    display: flex;
    gap: 7px;
    flex-direction: column;
    justify-content: center;
    min-height: 126px;
    padding: 19px 22px 25px;
    width: 33%;

    .value {
      color: #1e1919;
      font-size: 26px;
      line-height: 24px;
    }

    .description {
      color: #4b4747;
      font-size: 14px;
      line-height: 24px;
      margin: 0;
    }

    .label {
      color: #1e1919;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .marker {
    background: ${props => props.color};
    border-radius: 50%;
    display: inline-block;
    height: 9px;
    width: 9px;
  }
`;

export default StylesProvider;
