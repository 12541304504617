import { useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Button, Flex, Slider } from "antd";
import CardChartPie from "../CardChartPie";

import StylesContainer from "./styles";

import { PRODUCT_LIST } from "../../constants";

import slider_button from "../../images/slider_button.svg";

const SectionSelectAllocation = ({
  handleContinue,
  handleGoBack,
  handleStartOver,
  proposalData,
  updateProposalData,
}) => {
  const [loading, setLoading] = useState(false);

  const selectedProductDataAllocation = useMemo(
    () =>
      PRODUCT_LIST.find(it => it.label === proposalData.product).allocation[
        proposalData.portfolio
      ],
    [proposalData.product]
  );

  useEffect(() => {
    if (!proposalData.allocation && selectedProductDataAllocation) {
      updateProposalData(
        "allocation",
        selectedProductDataAllocation[
          Math.floor(selectedProductDataAllocation.length / 2)
        ]?.value
      );
    }
  }, [proposalData, selectedProductDataAllocation]);

  const marks = useMemo(() => {
    const marks = {};

    selectedProductDataAllocation.map(
      it => (marks[it.value] = `${it.value}% Astor`)
    );

    return marks;
  }, [proposalData.product]);

  const handleSliderChange = useDebouncedCallback(value => {
    setLoading(true);
    updateProposalData("allocation", value);
    setTimeout(() => setLoading(false), 500);
  }, 500);

  return (
    <StylesContainer align={"center"} vertical>
      <h3 className={"title"}>Select Allocation</h3>
      <Flex align={"center"} gap={220}>
        {proposalData.allocation && (
          <Slider
            defaultValue={proposalData.allocation}
            dots
            marks={marks}
            min={selectedProductDataAllocation[0].value}
            max={
              selectedProductDataAllocation[
                selectedProductDataAllocation.length - 1
              ].value
            }
            onChange={handleSliderChange}
            onChangeComplete={handleSliderChange}
            step={10}
            style={{ height: 276 }}
            styles={{
              rail: {
                background: "#32a2de20",
                width: 11,
              },
              track: {
                background: "transparent",
              },
              tracks: {
                background: "transparent",
              },
              handle: {
                background: `url(${slider_button})`,
                height: 52,
                transform: "translate(-25px, 27px)",
                width: 60,
                zIndex: 1,
              },
            }}
            tooltip={{
              formatter: null,
            }}
            vertical
          />
        )}
        <CardChartPie
          containerHeight={350}
          containerWidth={300}
          loading={loading}
          pieSize={200}
          readonly
          series={
            selectedProductDataAllocation.find(
              it => it.value === proposalData.allocation
            )?.series
          }
          title={proposalData.product}
        />
      </Flex>
      <Flex
        align={"center"}
        gap={12}
        style={{ marginTop: 45, width: 292 }}
        vertical
      >
        <Button
          block
          disabled={!proposalData.allocation}
          onClick={handleContinue}
          shape={"round"}
          style={{ background: "#1971B9", color: "#fff", width: 228 }}
          type={"primary"}
        >
          Continue to plan
        </Button>
        <Flex align={"center"} justify={"center"}>
          <Button
            onClick={handleGoBack}
            shape={"round"}
            size={"small"}
            style={{ color: "#32A2DE" }}
            type={"text"}
          >
            Go back
          </Button>
          <div
            style={{
              background: "#32a2de",
              height: 20,
              width: 1,
            }}
          />
          <Button
            onClick={handleStartOver}
            shape={"round"}
            size={"small"}
            style={{ color: "#32A2DE" }}
            type={"text"}
          >
            Start over
          </Button>
        </Flex>
      </Flex>
    </StylesContainer>
  );
};

export default SectionSelectAllocation;
